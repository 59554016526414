var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:" pb-10 mb-5 mr-5 ml-5 pa-5",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"label":"Banco"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"label":"Banco"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-select',{attrs:{"label":"Periodo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-select',{attrs:{"label":"Tipo de movimiento"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-search")])],1)]}}])},[_c('span',[_vm._v("Consultar estado de cuenta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v("Subir estado de cuenta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Guardar conciliacion")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)]}}])},[_c('span',[_vm._v("Exportar a Excel")])])],1)],1)],1),_c('v-card',{staticClass:"justify-center  ma-5 ",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Movimientos Bancarios")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pb-10 ma-5 pa-5 mt-10",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","xl":"12","md":"12","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Filtro"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersMovimientos,"search":_vm.search,"items":_vm.itemsMovimientosBancos,"items-per-page":10,"show-select":"","single-select":"","item-key":"id"}})],1)],1)],1),_c('v-card',{staticClass:"justify-center  ma-5 ",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Facturas")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pb-10 ma-5 pa-5",attrs:{"elevation":"3"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"10","xl":"3","md":"3","sm":"10","xs":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsI,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaI = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaI),callback:function ($$v) {_vm.menuDateBuscaI=$$v},expression:"menuDateBuscaI"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaI = false}},model:{value:(_vm.dateBuscaI),callback:function ($$v) {_vm.dateBuscaI=$$v},expression:"dateBuscaI"}})],1)],1),_c('v-col',{attrs:{"cols":"10","xl":"3","md":"3","sm":"10","xs":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsF,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaF = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaF),callback:function ($$v) {_vm.menuDateBuscaF=$$v},expression:"menuDateBuscaF"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaF = false}},model:{value:(_vm.dateBuscaF),callback:function ($$v) {_vm.dateBuscaF=$$v},expression:"dateBuscaF"}})],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"10","xl":"6","md":"6","sm":"10","xs":"10"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary darken-1","dark":"","fab":""},on:{"click":function($event){return _vm.ConsultarNomina()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Consultar Comprobantes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary darken-1","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-progress-check")])],1)]}}])},[_c('span',[_vm._v("Conciliacion Automática")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary darken-1","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Conciliacion manual")])])],1)],1),_c('v-row',[_c('vuetify-money',{staticStyle:{"width":"100%"},attrs:{"prefix":"$","readonly":"","outlined":"","label":"Total de Ingresos Facturados","options":_vm.options}})],1)],1),_c('v-card',{staticClass:"mt-5 pb-10 ma-5 pa-5 mt-10",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-right mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)]}}])},[_c('span',[_vm._v("Descargar Excel")])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersFacturas,"items":_vm.itemsFacturas}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }