<template>
    <div class="app pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- TITULO -->
        <v-card elevation="4" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Solicitudes de Cancelación</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>

        <!-- BARRA DE BUSQUEDA -->
        <v-card elevation="4" class="pb-10 ma-5 pa-5">
            <v-row>
                <v-col class="text-right" cols="12" md="12" sm="12"> 
                    <v-row class="mt-3 mb-3">
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2 mr-5 ml-0" fab dark color="primary darken-1" v-bind="attrs" v-on="on"
                                    @click="buscarSolicitudes()">
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                        
                    </v-row>
                </v-col>
            </v-row>

            <DataTable exportFilename="SolicitudesCancelacion" ref="SolicitudesCancelacion" removableSort  filterDisplay="menu"   class="p-datatable-sm p-datatable-customers" :value="itemsSolicitud" showGridlines :scrollable="true" 
        scrollHeight="600px" :paginator="true" :rows="15" 
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :filters.sync="filtros" :globalFilterFields="['serie','folio','fecha','subTotal','total','rfc','nombre','foliofiscal','estatus']">
            <template #header>
                <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText  v-model="filtros['global'].value" placeholder="Buscar..." style="width: 500px;"/>
                </span>
                <div style="text-align: left">
                    <Button @click="limpiarFiltros()" style="width: 200px;" type="button" icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined"/>
                    <Button @click="exportCSV($event)" style="width: 200px;" icon="pi pi-external-link" label="Exportar" />
                </div>
                </div>
            </template>

            <Column sortable field="serie" header="Serie" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.serie}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="folio" header="Folio" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'80px'}">
                <template #body="{data}">
                    {{data.folio}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="foliofiscal" header="FolioFiscal" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.foliofiscal}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="subTotal" header="Subtotal" filterField="subTotal" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.subTotal)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="total" header="Total" filterField="total" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.total)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN" locale="en-ES" />
                </template>
            </Column>

            <Column sortable field="fecha" header="Fecha" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'100px'}">
                <template #body="{data}">
                    {{formatDate(data.fecha)}}
                </template>
                <template #filter="{filterModel}">
                    <InputText  type="text"  v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>
          
            <Column sortable field="rfc" header="RFC" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.rfc}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="nombre" header="Nombre" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.nombre}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="estatus" header="Estatus" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'80px'}">
                <template #body="{data}">
                    {{data.estatus}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>
        </DataTable>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    export default {

        components: {
        },
        data: () => ({
            itemsSolicitud: [],

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            filtros: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'serie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'foliofiscal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'subTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'rfc': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'nombre': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'estatus': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            },
        }
        ),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            async buscarSolicitudes() {
                this.dialog = true;
                this.dialogMessage = 'Consultando solicitudes...'

                try {
                    let response = await axios.get('PeticionesSat/GetPeticiones/'+this.datosEmpresa.empresaBase+'/'+ this.datosEmpresa.rfc);
                    this.itemsSolicitud = response.data
                    console.log(response)
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar sus solicitudes'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            exportCSV() {
            this.$refs.SolicitudesCancelacion.exportCSV();
        },
        limpiarFiltros(){
            this.filtros= {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'serie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'foliofiscal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'subTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'rfc': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'nombre': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'estatus': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            }
        },
        },
    };
</script>
<style>
.app {
  background: url('https://img.freepik.com/vector-gratis/fondo-blanco-minimo_1393-354.jpg?w=2000')
    no-repeat center center fixed !important;
  background-size: cover;
  height: 205%;
}

</style>