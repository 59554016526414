<template>
    <div>
        <v-card elevation="2" class="justify-center mb-5 mr-5 ml-5  mt-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Comprobantes</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE COMPONENTES -->
        <v-card class="justify-center pb-5" flat>
            <v-row dense>
                <v-hover v-slot="{ hover }" open-delay="10">
                    <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=1"
                        class="mx-auto justify-center mr-5" width="180" height="160" link>
                        <v-card-text class="text-center">
                            <v-icon size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                            <h3 class="mt-3 mb-1">Comprobantes</h3>
                        </v-card-text>
                    </v-card>
                </v-hover>
                <v-hover v-slot="{ hover }" open-delay="10">
                    <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="step=2"
                        class="mx-auto justify-center ml-5" width="180" height="160" link>
                        <v-card-text class="text-center">
                            <v-icon size="80" color="primary">mdi-file-document-multiple-outline</v-icon>
                            <h3 class="mt-2 mb-1">Comprobantes Verificados</h3>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-row>
        </v-card>
        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="3" class="mt-5 pb-10 ma-5 pa-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-select v-model="Select" :items="itemsSelect" label="Selecciona un Dato"></v-select>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI" label="Fecha" readonly v-bind="attrs"
                                v-on="on" @click:clear="dateBuscaI = null" locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA FINAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF" label="Fecha" readonly v-bind="attrs"
                                v-on="on" @click:clear="dateBuscaF = null" locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- BOTON DE AYUDA  -->
                <v-col class="text-right" cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span>Consultar Comprobantes</span>
                    </v-tooltip>
                    <!-- BOTON DE AYUDA  -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-checkbox-marked-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Validar</span>
                    </v-tooltip>
                    <!-- BOTON DE CANCELAR  -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab v-bind="attrs" v-on="on">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Cancelar</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>
        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- LISTA DE COMPROBANTES -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="3" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Lista de Comprobantes</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="3" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col class="text-center" cols="12" xl="11" md="11" sm="12" xs="12">
                            <v-text-field v-model="search" label="Filtro" class="mx-4"></v-text-field>
                        </v-col>
                        <v-col class="text-right" cols="12" xl="1" md="1" sm="12" xs="12">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2 mr-1 ml-0" color="primary accent-4" dark fab v-bind="attrs"
                                        v-on="on">
                                        <v-icon>mdi-view-list-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Descargar Excel</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-data-table v-model="selected" :headers="headers" :items="itemsComprobantes"
                        :single-select="singleSelect" :search="search" item-key="folioFiscal" show-select
                        class="elevation-0">
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>

            <!-- LISTA DE COMPROBANTES VERIFICADOS -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="3" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Lista de Comprobantes Verificados</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="3" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col class="text-center" cols="12" xl="11" md="11" sm="12" xs="12">
                            <v-text-field v-model="search2" label="Filtro" class="mx-4"></v-text-field>
                        </v-col>
                        <v-col class="text-right" cols="12" xl="1" md="1" sm="12" xs="12">

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2 mr-1 ml-0" color="primary accent-4" dark fab v-bind="attrs"
                                        v-on="on">
                                        <v-icon>mdi-view-list-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Descargar Excel</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headersVerificados" :items="itemsComprobantesValidados" :search="search2"
                        item-key="folioFiscal" class="elevation-0">
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
        </v-window>

    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    export default {
        data: () => ({
            step: 1,
            search: '',
            search2: '',

            itemsSelect: [
                { text: 'Emitidos', value: 'Emitidos' },
                { text: 'Recibidos', value: 'Recibidos' }
            ],
            Select: '',
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,
            search: '',
            loading: false,
            dialog: false,
            singleSelect: false,
            selected: [],
            headers: [
                { text: 'Serie', value: 'serie', sortable: false },
                { text: 'Folio', value: 'folio', sortable: false },
                { text: 'Fecha de Timbrado', value: 'fechaTimbrado', sortable: false },
                { text: 'RFC del Emisor', value: 'rfcEmisor', sortable: false },
                { text: 'Nombre del Emisor', value: 'nombreEmisor', sortable: false },
                { text: 'RFC del Receptor', value: 'rfcReceptor', sortable: false },
                { text: 'Nombre del Receptor', value: 'nombreReceptor', sortable: false },
                { text: 'Folio Fiscal', value: 'folioFiscal', sortable: false },
                { text: 'Total', value: 'total', sortable: false },
                { text: 'Estatus', value: 'estatus', sortable: false },
                { text: 'Estatus Consultado', value: 'estatusConsultado', sortable: false },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante', sortable: false },
            ],
            itemsComprobantes: [],
            itemsTipo: [
                'Emitidos',
                'Recibidos'
            ],
            selectTipo: '',
            //COMPROBANTES VALIDADOS
            headersVerificados: [
                { text: 'Serie', value: 'serie', sortable: false },
                { text: 'Folio', value: 'folio', sortable: false },
                { text: 'Fecha de Timbrado', value: 'fechaTimbrado', sortable: false },
                { text: 'RFC del Emisor', value: 'rfcEmisor', sortable: false },
                { text: 'Nombre del Emisor', value: 'nombreEmisor', sortable: false },
                { text: 'RFC del Receptor', value: 'rfcReceptor', sortable: false },
                { text: 'Nombre del Receptor', value: 'nombreReceptor', sortable: false },
                { text: 'Folio Fiscal', value: 'folioFiscal', sortable: false },
                { text: 'Total', value: 'total', sortable: false },
                { text: 'Estatus', value: 'estatus', sortable: false },
                { text: 'Estatus Consultado', value: 'estatusConsultado', sortable: false },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante', sortable: false },
            ],
            itemsComprobantesValidados: [],


        }),
        computed: {
            // METODOS COMPUTADOS PARA LAS FECHAS
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {


        }
    }
</script>