<template>
    <div>
        <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                {{ dialogMessage }}
                <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG PARA VER DETALLES DE TABLAS IEPS-->
        <v-dialog v-model="dialogDetalle" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ titulo }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <template v-if="itemsConsulta.length != 0">
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                            @click="GeneraExcel()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                    </template>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchConsulta"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerConsulta" :items="itemsConsulta" :search="searchConsulta" fixed-header height="65vh">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.baseIva`]="{ item }">
                            <span>{{ functionFormatPrice(item.baseIva) }}</span>
                        </template>
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <template v-slot:[`item.ivaPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.ivaPagado) }}</span>
                        </template>
                        <template v-slot:[`item.ivaPesosPagado`]="{ item }">
                            <span>{{ functionFormatPrice(item.ivaPesosPagado) }}</span>
                        </template>
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <template v-slot:[`item.subTotalPago`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotalPago) }}</span>
                        </template>
                        <template v-slot:[`item.tasaOCuota`]="{ item }">
                            <span>{{ functionFormatPrice(item.tasaOCuota) }}</span>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <template v-slot:[`item.totalPago`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalPago) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- DIALOG COMPARATIVA -->
        <v-dialog v-model="dialogComparativa" persistent max-width="500px">
            <v-card>
            <v-toolbar>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small @click="dialogComparativa = false" v-bind="attrs" v-on="on">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-toolbar-title>Importes Registrados {{ fechaHome.anio }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostComparativa()">
                    <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Guardar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <br>
            <v-card-text>
                    <v-data-table :headers="headersComparativa" :items="itemsComparativa" :items-per-page="-1" hide-default-footer >
                    <!-- PARA EDITAR -->
                    <template v-slot:item.importe="props">
                        <v-edit-dialog
                        :return-value.sync="props.item.importe"
                        >
                        {{ props.item.importe }}
                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.importe"
                            label="Editar"
                            single-line
                            counter
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template>
                    </v-data-table>
            </v-card-text>
            </v-card>
        </v-dialog>
        <!-- REPORTE -->
        <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5"  color="transparent">
            <v-card elevation="3" class="justify-center">
                <v-card-title class="dark justify-center">
                    <h3 class="justify-center">IEPS</h3>
                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
            </v-card>
            <v-card elevation="3" class="mt-5 pb-10">
                <!-- IVA RETENIDO EMITIDO -->
                <div class="text-center">
                    <h2>IEPS EMITIDO</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES-->
                <v-row justify="space-around">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelIEPSEmitidos()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                outlined @click="openDialog('Emitidos')">
                                <v-icon>mdi-menu</v-icon>
                                COMPARATIVA
                            </v-chip>
                        </template>
                        <span>COMPARATIVA DE IEPS EMITIDO</span>
                    </v-tooltip>
                    <!-- BOTÓN PARA CONSULTAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="GetEmitidos()">
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                        </template>
                        <span>Actualizar</span>
                    </v-tooltip>
                </v-row>
                <br>
                <v-data-table :item-class="row_classes" class="elevation -1" :headers="headerIEPSEmitidos"
                    :items="itemsEmitidos" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="detallesPorMes(item, 'IEPS Emitidos')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importeBaseIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeBaseIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>
            <v-card elevation="3" class="mt-5 pb-10  mt-10">
                <!-- IVA RETENIDO RECIBIDO -->
                <div class="text-center">
                    <h2>IEPS RECIBIDO</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES-->
                <v-row justify="space-around">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelIEPSRecibidos()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                outlined @click="openDialog('Recibidos')">
                                <v-icon>mdi-menu</v-icon>
                                COMPARATIVA
                            </v-chip>
                        </template>
                        <span>COMPARATIVA DE IEPS RECIBIDO</span>
                    </v-tooltip>
                    <!-- BOTÓN PARA CONSULTAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="GetRecibidos()">
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                        </template>
                        <span>Actualizar</span>
                    </v-tooltip>
                    <!-- </div> -->
                </v-row>
                <br>
                <v-data-table :item-class="row_classes" class="elevation -1" :headers="headerIEPSRecibidos"
                    :items="itemsRecibidos" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="detallesPorMes(item, 'IEPS Recibidos')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importeBaseIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeBaseIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeIva`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeIva) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"


    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            HelloWorld,
        },
        data: () => ({
            //DIALOG
            dialog: false,
            dialogMessage: '',

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            headerIEPSEmitidos: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importeIva', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsEmitidos: [],
            headerIEPSRecibidos: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importeIva', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsRecibidos: [],
            headerConsulta: [
                { text: 'version', value: 'version' },
                { text: 'serie', value: 'serie' },
                { text: 'seriePago', value: 'seriePago' },
                { text: 'folio', value: 'folio' },
                { text: 'folioPago', value: 'folioPago' },
                { text: 'fecha', value: 'fecha' },
                { text: 'fechaPago', value: 'fechaPago' },
                // { text: 'subTotal', value: 'subTotal', align: 'right' },
                // { text: 'subTotalPago', value: 'subTotalPago', align: 'right' },
                // { text: 'descuento', value: 'descuento', align: 'right' },
                // { text: 'total', value: 'total', align: 'right' },
                // { text: 'totalPago', value: 'totalPago', align: 'right' },
                // { text: 'subTotalPesosCobrados', value: 'subTotalPesosCobrados', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IVA Pagado', value: 'ivaPagado', align: 'right' },
                { text: 'IVA PesosPagado', value: 'ivaPesosPagado', align: 'right' },
                { text: 'tasaOCuota', value: 'tasaOCuota', align: 'right' },
                { text: 'tipoFactor', value: 'tipoFactor' },
                // { text: 'baseIsr', value: 'baseIsr', align: 'right' },
                { text: 'moneda', value: 'moneda' },
                { text: 'monedaPago', value: 'monedaPago' },
                { text: 'tipoCambio', value: 'tipoCambio' },
                { text: 'tipoCambioPago', value: 'tipoCambioPago' },
                { text: 'tipoComprobante', value: 'tipoComprobante' },
                { text: 'formaPago', value: 'formaPago' },
                { text: 'Método Pago', value: 'metodoPago' },
                { text: 'rfc', value: 'rfc' },
                { text: 'nombre', value: 'nombre' },
                { text: 'folioFiscal', value: 'folioFiscal' },
                { text: 'folioFiscalPago', value: 'folioFiscalPago' },
                { text: 'porcentajePago', value: 'porcentajePago' },
            ],
            itemsConsulta: [],
            dialogDetalle: false,
            titulo: '',
            searchConsulta: '',

            //COMPARATIVAS
            dialogComparativa: false,
            headersComparativa: [
                { text: 'Mes', value: 'mes' },
                { text: 'Importe', value: 'importe' },
            ],
            itemsComparativa:[],
            itemsComparativaEmitido: [
                { anio: '', mes: 'enero', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'IEPS_EMITIDO', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaRecibido: [
                { anio: '', mes: 'enero', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'IEPS_RECIBIDO', importe: 0, cargo: 0, abono: 0 },
            ],

        }),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            fechaHome(){
                return this.$store.state.fechaHome;
            },

        },
        methods: {
            async GetEmitidos(){
                try{
                    this.dialog = true;
                    this.dialogMessage = 'Consultando Emitidos...'

                    this.itemsEmitidos = [];
                    let listaRespuestas = [];

                    let contador = Number(this.fechaHome.mes.valor) + 1
                    console.log(this.fechaHome.mes.valor)
                    for (let i = 1; i < contador; i++) {
                        let fechaInicial = this.fechaHome.anio + '-' + i + '-01'
                        let response = await axios.get('Ingresos/GetIepsTrasladado/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                        listaRespuestas.push(response.data);
                    }

                    let final = await Promise.all(listaRespuestas);
                    console.log(final)

                    for (let resultado of final) {
                        this.itemsEmitidos.push(resultado)
                    }
                    
                    //CONSULTAMOS LOS IMPORTES REGISTRADOS
                    await this.GetComparativaEmitidos()

                    let emitido = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsEmitidos, 'comparativa'),
                        importeIva: this.sum(this.itemsEmitidos, 'importeIva'),
                        importeBaseIva: this.sum(this.itemsEmitidos, 'importeBaseIva'),
                        importeRegistrado: this.sum(this.itemsEmitidos, 'importeRegistrado'),
                    }

                    this.itemsEmitidos.push(emitido);
                    this.dialog = false;
                }catch(error){
                    this.dialog = false;
                    console.log(error)
                }
            },

            async GetRecibidos(){
                try{
                    this.dialog = true;
                    this.dialogMessage = 'Consultando Recibidos...'

                    this.itemsRecibidos = [];
                    let listaRespuestas = [];

                    let contador = Number(this.fechaHome.mes.valor) + 1
                    console.log(this.fechaHome.mes.valor)
                    for (let i = 1; i < contador; i++) {
                        let fechaInicial = this.fechaHome.anio + '-' + i + '-01'
                        let response = await axios.get('Gastos/GetIepsTrasladado/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                        listaRespuestas.push(response.data);
                    }

                    let final = await Promise.all(listaRespuestas);
                    console.log(final)

                    for (let resultado of final) {
                        this.itemsRecibidos.push(resultado)
                    }
                    
                    //CONSULTAMOS LOS IMPORTES REGISTRADOS
                    await this.GetComparativaRecibidos()

                    let emitido = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsRecibidos, 'comparativa'),
                        importeIva: this.sum(this.itemsRecibidos, 'importeIva'),
                        importeBaseIva: this.sum(this.itemsRecibidos, 'importeBaseIva'),
                        importeRegistrado: this.sum(this.itemsRecibidos, 'importeRegistrado'),
                    }

                    this.itemsRecibidos.push(emitido);
                    this.dialog = false;
                }catch(error){
                    this.dialog = false;
                    console.log(error)
                }
            },


            detallesPorMes(item, value) {
                console.log(item)
                this.itemsConsulta = item.detalles
                this.titulo = value + ' ' + item.mes + ' ' + this.fechaHome.anio
                this.dialogDetalle = true
            },

            // GENERAR EXCEL
            GeneraExcel() {
                this.nombreReporte = this.titulo + '.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsConsulta
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },

            row_classes(item) {
                if (item.mes == "TOTAL:") {
                    return "primary lighten-5";
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
        
            //COMPARATIVA
            async openDialog(item){
                if(item === 'Emitidos'){
                    await this.GetComparativaEmitidos();
                    this.itemsComparativa =  this.itemsComparativaEmitido;
                    this.dialogComparativa = true;
                }
                if(item === 'Recibidos'){
                    await this.GetComparativaRecibidos();
                    this.itemsComparativa =  this.itemsComparativaRecibido;
                    this.dialogComparativa = true;
                }
            },
            
            async GetComparativaEmitidos(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/IEPS_EMITIDO/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaEmitido){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsEmitidos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importeIva - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaRecibidos(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/IEPS_RECIBIDO/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaRecibido){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsRecibidos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importeIva - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async PostComparativa(){
                try{
                    let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsComparativa)
                    this.dialogComparativa = false
                }catch(error){
                    console.log(error)
                }
            },
        
        },
    };
</script>