import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import vuetify from '../plugins/vuetify'
import store from '../store/index'
import ValidarRFC from '../components/ValidarRFC.vue'
import ReportePorFechas from '../components/ReportePorFechas.vue'
import Conceptos from '../components/Conceptos.vue'
import Comprobantes from '../components/Comprobantes.vue'
import SolicitudesDeCancelacion from '../components/SolicitudesDeCancelacion.vue'
import Nomina from '../components/Nomina.vue'
import CuentasCobrarYPagar from '../components/CuentasCobrarYPagar.vue'
import Anticipos from '../components/Anticipos.vue'
import MovimientosBancarios from '../components/MovimientosBancarios.vue'
import Gasolinera from '../components/Gasolinera.vue'
import Login from '../views/Login.vue'
import Archivos from '../components/SubirArchivos/Archivos.vue'
import Compras from '../components/Compras.vue'
import Ventas from '../components/Ventas.vue'
import IngresosNomina from '../components/IngresosNomina.vue'
import Bancos from '../components/Bancos.vue'

Vue.use(VueRouter)
Vue.use(vuetify)

const routes = [
  { path: '/', name: 'Home', component: Home,meta:{ Administrador: true, Gasolinero: true, Nomina: true, CuentasPagar: true, CuentasCobrar: true, Ejecutivo: true, Capturista:true, EjecutivoGas:true } },
  { path: '/about', name: 'About', component: () => import('../views/About.vue')  },                          
  { path: '/ValidarRFC', name: 'ValidarRFC', component: ValidarRFC,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false}},
  { path: '/ReportePorFechas', name: 'ReportePorFechas', component: ReportePorFechas,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/Conceptos', name: 'Conceptos', component: Conceptos,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/Comprobantes', name: 'Comprobantes', component: Comprobantes,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/SolicitudesDeCancelacion', name: 'SolicitudesDeCancelacion', component: SolicitudesDeCancelacion,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/Nomina', name: 'Nomina', component: Nomina,meta:{ Administrador: true, Gasolinero: true, Nomina: true, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/CuentasCobrarYPagar', name: 'CuentasCobrarYPagar', component: CuentasCobrarYPagar,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: true, CuentasCobrar: true  }},
  { path: '/Anticipos', name: 'Anticipos', component: Anticipos,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/MovimientosBancarios', name: 'MovimientosBancarios', component: MovimientosBancarios,meta:{ Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false  }},
  { path: '/Gasolinera', name: 'Gasolinera', component: Gasolinera,meta: { Administrador: false,Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false }},
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true, }},
  { path: '/Archivos', name: 'Archivos', component: Archivos,meta: { Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false }},
  { path: '/Compras', name: 'Compras', component: Compras,meta: { Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false }},
  { path: '/Ventas', name: 'Ventas', component: Ventas,meta: { Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false }},
  { path: '/IngresosNomina', name: 'IngresosNomina', component: IngresosNomina,meta: { Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false }},
  { path: '/Bancos', name: 'Bancos', component: Bancos,meta: { Administrador: true, Gasolinero: true, Nomina: false, CuentasPagar: false, CuentasCobrar: false }},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'Gasolinero') {
    if (to.matched.some(record => record.meta.Gasolinero)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'Nomina') {
    if (to.matched.some(record => record.meta.Nomina)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'CuentasPagar') {
    if (to.matched.some(record => record.meta.CuentasPagar)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'CuentasCobrar') {
    if (to.matched.some(record => record.meta.CuentasPagar)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'Ejecutivo') {
    if (to.matched.some(record => record.meta.Ejecutivo)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'Capturista') {
    if (to.matched.some(record => record.meta.Capturista)) {
      next()
    }
  } else if (store.state.usuario && store.state.usuario.rol == 'EjecutivoGas') {
    if (to.matched.some(record => record.meta.Capturista)) {
      next()
    }
  }else {
    next({
      name: 'Login'
    })
  }
})
export default router
