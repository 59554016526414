<template>
    <div class="app pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- TITULO -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Ingresos Nómina</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="12" xs="12">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- BOTÓN PARA CONSULTAR -->
                <v-col class="text-right" cols="12" xl="6" md="6" sm="3" xs="3">
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="ConsultarIngresosNomina()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar compras</span>
                        </v-tooltip>
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="GeneraExcelIngresosNomina()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <!-- TABLA -->
        <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details>
            </v-text-field>
            <v-data-table :headers="headersIngresosNomina" :items="itemsIngresosNomina" class="elevation-0"
                :search="search">

            </v-data-table>
        </v-card>

    </div>
</template>


<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    // import XLSX from 'xlsx';

    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            HelloWorld,
        },
        data: () => ({
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //DIALOG
            dialog: false,
            dialogMessage: '',
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            itemsIngresosNomina: [],

            search: '',

            headersIngresosNomina: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha Comprobante', value: 'fechaComprobante' },
                { text: 'Fecha Inicial', value: 'fechaInicial' },
                { text: 'Fecha Final', value: 'fechaFinal' },
                { text: 'Fecha Pago', value: 'fechaPago' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Origen Recurso', value: 'origenRecurso' },
                { text: 'Puesto', value: 'puesto' },
                { text: 'Departamento', value: 'departamento' },
                { text: 'Num. Empleado', value: 'numEmpleado' },
                { text: 'Tipo de Régimen', value: 'tipoRegimen' },
                { text: 'Tipo de Contrato', value: 'tipoContrato' },
                { text: 'Periocidad de Pago', value: 'periodicidadPago' },
                { text: 'Tipo Nómina', value: 'tipoNomina' },
                { text: 'Total Percepciones', value: 'totalPercepciones' },
                { text: 'Total Deducciones', value: 'totalDeducciones' },
                { text: 'Total Otros Pagos', value: 'totalOtrosPagos' },
                { text: 'Total ISR', value: 'totalIsr' },
                { text: 'Num. Dáas Pagados', value: 'numDiasPagados' },
            ],
        }),
        computed: {
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        created() { },
        methods: {
            async ConsultarIngresosNomina() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                this.itemsIngresosNomina = []
                try {
                    let response = await axios.get('Nomina/IngresoNomina/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    console.log(response.data)
                    this.itemsIngresosNomina = response.data

                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;

            },
            GeneraExcelIngresosNomina() {
                this.nombreReporte = 'IngresosNomina.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsIngresosNomina
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
        },
    }
</script>