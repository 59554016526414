<template>
    <div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- CARGAR FIEL -->
        <v-dialog persistent v-model="dialogCargarFiel" width="1000">
            <CargarFiel @closeCargaFiel="dialogCargarFiel = false, cierraVentana()"></CargarFiel>
        </v-dialog>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <v-card>
            <v-toolbar>
                <v-btn @click="cierraVentana()" icon color="primary darken-1">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>REGISTRO DE NUEVO USUARIO</h3>
                <v-spacer></v-spacer>
                <v-tooltip bottom v-if="this.validarReferencia == 1 && this.usuario == true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="abrirValidarFiel()" color="primary darken-1" v-bind="attrs" v-on="on" icon>
                            <v-icon large>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Registrarse</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-form>
                <v-container class="pa-6">
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="nuevaEmpresa.nombreEmpresa" label="Nombre de la Empresa">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field hint="Valide que el nombre de usuario no exista." persistent-hint
                                :readonly="usuario" v-model="nuevaEmpresa.nombreUsuario" label="Nombre del usuario"
                                append-outer-icon="mdi-account-check" @click:append-outer="validarUsuario()">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field :readonly="validaUsuarioN" v-model="nuevaEmpresa.correo" label="Correo">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field :readonly="validaUsuarioN" v-model="nuevaEmpresa.telefono" label="Teléfono">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field :readonly="validaUsuarioN" v-model="nuevaEmpresa.nip" :maxlength="4"
                                hint="El NIP debe contener 4 digitos numericos." persistent-hint label="NIP">
                            </v-text-field>
                        </v-col>
                        <v-col cols="1" sm="1" md="1">
                            <v-switch class="pa-4" @click="switchCasoReferencia()" v-model="radioButton"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="5" md="5">
                            <v-text-field hint="Valide que el usuario de referencia exista." persistent-hint
                                :readonly="usuarioReferencia" v-model="nuevaEmpresa.usuarioReferencia"
                                label="Usuario de Referencia" append-outer-icon="mdi-account-check"
                                @click:append-outer="validarUsuarioReferencia()"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

        </v-card>
    </div>
</template>
<script>
    import moment from 'moment';
    import axios from 'axios';
    import CargarFiel from '../RegistroUsuario/CargarFiel.vue'

    export default {
        components: {
            CargarFiel
        },
        data: () => ({
            dialogCargarFiel: false,

            radioButton: true,
            show1: false,
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
            //DIALOG
            dialog: false,
            dialogMessage: '',
            validaUsuarioN: true,
            usuario: false,
            validarReferencia: 0,
            usuarioReferencia: false,
        }),
        computed: {
            nuevaEmpresa() {
                return this.$store.state.nuevaEmpresaStore
            },
        },
        methods: {
            switchCasoReferencia(){
                if(this.radioButton == false){
                    this.usuarioReferencia = true
                    this.validarReferencia = 1
                    this.nuevaEmpresa.usuarioReferencia=''

                }else{
                    this.usuarioReferencia = false
                    this.validarReferencia = 0
                }
                
            },

            // VALIDAMOS LOS ARCHIVOS CER Y KEY
            abrirValidarFiel(){
                this.dialogCargarFiel=true
                
            },
            // GUARDAMOS SOLAMENTE LOS DATOS DEL USUARIO Y LA EMPRESA AL IGUAL QUE SE LE ASIGNAN LOS ID 
            async crearEmpresa() {
                this.dialog = true,
                    this.dialogMessage = 'Creando Empresa...'

                console.log(this.nuevaEmpresa)
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/CreaEmpresa/', this.nuevaEmpresa);
                    console.log(response.data)

                    await this.creaBaseTablas();

                } catch (error) {
                    console.log(error)
                    if (error.response.status == 400) {
                        this.snackbarMessage = "Empresa ya se ha dado de alta.";
                        this.snackbar = true;
                    }
                }
                this.dialog = false
            },

            // VALIDAMOS QUE EL USUARIO A DAR DE ALTA NO ESTE REGISTRADO ANTERIORMENTE 
            async validarUsuario() {
                this.dialog = true
                this.dialogMessage = 'Validando usuario...'

                console.log(this.nuevaEmpresa)
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/ValidaNuevoUsuario/', this.nuevaEmpresa);
                    console.log(response.data)
                    this.validaUsuarioN = false
                    this.usuario = true
                } catch (error) {
                    console.log(error.response.data)
                    this.snackbarMessage = error.response.data.Message
                    this.snackbar = true;
                }
                this.dialog = false
            },

            // VALIDAMOS QUE EL USUARIO DE REFERENCIA EXISTA
            async validarUsuarioReferencia() {
                console.log(this.nuevaEmpresa)

                this.dialog = true
                this.dialogMessage = 'Buscando Usuario...'

                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/ValidaUsuarioReferencia/', this.nuevaEmpresa);
                    console.log(response.data)
                    this.usuarioReferencia = true
                    this.validarReferencia = 1
                    console.log(this.validarReferencia)
                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = error.response.data.Message
                    this.snackbar = true;
                }
                this.dialog = false
            },

            // CREAMOS LAS BASES Y TABLAS DE LA BASE DOCUMENTOS
            async creaBaseTablas() {
                this.dialog = true,
                    this.dialogMessage = 'Creando Base...'
                console.log(this.nuevaEmpresa)
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/CreaBaseTablas/', this.nuevaEmpresa);
                    console.log(response.data)


                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = error.response.data.Message
                    this.snackbar = true;
                }
                this.dialog = false

            },

            // CERRAMOS LAS VENTANAS E INICIALIZAMOS TODO EL DIALOGO
            cierraVentana() {
                this.$emit('closeNuevoUsuario')

                this.validaUsuarioN = true
                this.usuario = false
                this.validarReferencia = 0
                this.usuarioReferencia = false

                this.$store.state.nuevaEmpresaStore.rfc = ''
                this.$store.state.nuevaEmpresaStore.nombreEmpresa = ''
                this.$store.state.nuevaEmpresaStore.nombreUsuario = ''
                this.$store.state.nuevaEmpresaStore.correo = ''
                this.$store.state.nuevaEmpresaStore.telefono = ''
                this.$store.state.nuevaEmpresaStore.nip = ''
                this.$store.state.nuevaEmpresaStore.usuarioReferencia = ''
            },
        }
    }
</script>