var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"justify-center mb-5 mr-5 ml-5  mt-5",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Comprobantes")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"justify-center pb-5",attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center mr-5",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"180","height":"160","link":""},on:{"click":function($event){_vm.step=1}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"80","color":"primary"}},[_vm._v("mdi-file-document-multiple-outline")]),_c('h3',{staticClass:"mt-3 mb-1"},[_vm._v("Comprobantes")])],1)],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto justify-center ml-5",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"width":"180","height":"160","link":""},on:{"click":function($event){_vm.step=2}}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"80","color":"primary"}},[_vm._v("mdi-file-document-multiple-outline")]),_c('h3',{staticClass:"mt-2 mb-1"},[_vm._v("Comprobantes Verificados")])],1)],1)]}}])})],1)],1),_c('v-card',{staticClass:"mt-5 pb-10 ma-5 pa-5",attrs:{"elevation":"3"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","xl":"3","md":"3","sm":"12","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.itemsSelect,"label":"Selecciona un Dato"},model:{value:(_vm.Select),callback:function ($$v) {_vm.Select=$$v},expression:"Select"}})],1),_c('v-col',{attrs:{"cols":"10","xl":"3","md":"3","sm":"10","xs":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsI,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaI = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaI),callback:function ($$v) {_vm.menuDateBuscaI=$$v},expression:"menuDateBuscaI"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaI = false}},model:{value:(_vm.dateBuscaI),callback:function ($$v) {_vm.dateBuscaI=$$v},expression:"dateBuscaI"}})],1)],1),_c('v-col',{attrs:{"cols":"10","xl":"3","md":"3","sm":"10","xs":"10"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsF,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaF = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaF),callback:function ($$v) {_vm.menuDateBuscaF=$$v},expression:"menuDateBuscaF"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaF = false}},model:{value:(_vm.dateBuscaF),callback:function ($$v) {_vm.dateBuscaF=$$v},expression:"dateBuscaF"}})],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"10","xl":"3","md":"3","sm":"10","xs":"10"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Consultar Comprobantes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")])],1)]}}])},[_c('span',[_vm._v("Validar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Cancelar")])])],1)],1)],1),_c('v-window',{staticClass:"pt-0 mt-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{staticClass:"pt-0 mt-0",attrs:{"value":1}},[_c('v-card',{staticClass:"justify-center  ma-5 ",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Lista de Comprobantes")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pt-10 pb-10 pa-5 ma-5",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","xl":"11","md":"11","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtro"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"1","md":"1","sm":"12","xs":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 mr-1 ml-0",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)]}}])},[_c('span',[_vm._v("Descargar Excel")])])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.itemsComprobantes,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"folioFiscal","show-select":""},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-window-item',{staticClass:"pt-0 mt-0",attrs:{"value":2}},[_c('v-card',{staticClass:"justify-center  ma-5 ",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Lista de Comprobantes Verificados")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pt-10 pb-10 pa-5 ma-5",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","xl":"11","md":"11","sm":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtro"},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"1","md":"1","sm":"12","xs":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 mr-1 ml-0",attrs:{"color":"primary accent-4","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)]}}])},[_c('span',[_vm._v("Descargar Excel")])])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersVerificados,"items":_vm.itemsComprobantesValidados,"search":_vm.search2,"item-key":"folioFiscal"},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }