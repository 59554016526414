<template>
  <v-card dense>
    <v-card-text dense>
      <v-container dense>
        <p></p>
        <v-row dense>
          <v-col cols="6" dense>
            <v-menu v-model="menuDateBuscaA" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense :value="computedDateFormattedMomentjsA" label="Fecha de Inicial: " readonly
                  v-bind="attrs" hide-details v-on="on" locale="es"></v-text-field>
              </template>
              <v-date-picker @change="funfecha1" locale="es" v-model="fecha1"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" dense>
            <v-menu ref="menu2" v-model="menuHI" :close-on-content-click="false" :nudge-right="40"
              :return-value.sync="timeI" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="timeI" label="Hora inicial" prepend-icon="mdi-clock-time-four-outline"
                  readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-time-picker v-if="menuHI" v-model="timeI" full-width use-seconds format="24hr"
                @click:second="$refs.menu2.save(timeI)"></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" dense>
            <v-menu v-model="menuDateBuscaB" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense :value="computedDateFormattedMomentjsB" label="Fecha de Final: " readonly
                  v-bind="attrs" hide-details v-on="on" locale="es"></v-text-field>
              </template>
              <v-date-picker @change="funfecha2" locale="es" v-model="fecha2"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" dense>
            <v-menu ref="menu" v-model="menuHF" :close-on-content-click="false" :nudge-right="40"
              :return-value.sync="timeF" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="timeF" label="Hora final" prepend-icon="mdi-clock-time-four-outline"
                  readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-time-picker v-if="menuHF" v-model="timeF" full-width use-seconds format="24hr"
                @click:second="$refs.menu.save(timeF)"></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" dense>
            <v-form ref="form1" onSubmit="return false;">
              <v-select dense :rules="[rules.required]" label="Tipo" :items="tipos" v-model="tipo"></v-select>
            </v-form>
          </v-col>
          <v-col cols="4" dense>
            <v-form ref="form2" onSubmit="return false;">
              <v-select dense :rules="[rules.required]" label="Archivos" :items="archivos" v-model="archivo">
              </v-select>
            </v-form>
          </v-col>
          <v-col cols="4" dense>
            <v-btn color="primary" @click="bt1" block denses text>
              Solicitar al SAT
            </v-btn>
          </v-col>
          <v-col cols="12" dense>
            <v-layout column style="max-height: 34vh">
              <v-flex style="overflow: auto">
                <v-data-table dense :headers="headers" :items="items" style="width: 100%" disable-sort
                  class="elevation-0" :footer-props="{ itemsPerPageText: 'Filas por página:' }">
                  <template v-slot:[`item.tipo`]="{ item }">
                    {{ item.tipo + " (" + item.archivo + ")"}}
                  </template>
                  <template v-slot:[`item.cfdi`]="{ item }">
                    <template v-if="item.cfdi != ''">
                      {{
                      item.cfdi + " CFDI"
                      }}
                    </template>
                    <template v-else-if="item.metadata != ''">
                      {{
                      item.metadata + " Metadata"
                      }}
                    </template>
                  </template>
                  <template v-slot:[`item.fecha`]="{ item }">
                    <template v-if="item.fecha.length == 19">
                      {{
                      item.fecha.substr(8, 2) +
                      "/" +
                      item.fecha.substr(5, 2) +
                      "/" +
                      item.fecha.substr(0, 4) +
                      " " +
                      item.fecha.substr(11, 8)
                      }}
                    </template>
                  </template>
                  <template v-slot:[`item.fecha2`]="{ item }">
                    <template v-if="item.fecha2.length == 19">
                      {{
                      item.fecha2.substr(8, 2) +
                      "/" +
                      item.fecha2.substr(5, 2) +
                      "/" +
                      item.fecha2.substr(0, 4) +
                      " " +
                      item.fecha2.substr(11, 8)
                      }}
                    </template>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <template v-if="item.solicitud_paq != '' && item.estatus == 'Listo para Descargar'">
                      <v-icon title="Descargar" @click="bt2a(item)">mdi-download</v-icon>
                    </template>
                    <template
                      v-else-if="item.estatus == 'Solicitud Erronea' || item.estatus == 'Solicitud Rechazada' || item.estatus == 'Solicitud Vencida'">
                      <v-icon title="Terminada" @click="bt2b(item)">mdi-close</v-icon>
                    </template>
                    <template v-else-if="item.estatus == 'Descargado'">
                      <v-icon title="Terminada" @click="bt2b(item)">mdi-check</v-icon>
                    </template>
                    <template v-else>
                      <v-icon title="Actualizar" @click="bt2c(item)">mdi-update</v-icon>
                    </template>
                  </template>
                  <template v-slot:no-data>
                    <br />
                    <p>Sin datos disponibles</p>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-btn color="primary" @click="cargartodo" text>
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>          
          <v-btn color="primary" @click="funappdes" text>
            APP de escritorio
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="descargafuncion" text>
            Cerrar
          </v-btn>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="dialogIni" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ dialogIniText }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog500" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>Problemas de conexión</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog500 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog404" max-width="350">
      <v-card>
        <v-card-title class="headline"> Error </v-card-title>
        <v-card-text class="text-h6">
          <h5>{{ Message }}</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog404 = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogaviso" max-width="350">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>{{ Text }}</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogaviso = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogeliminar" max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-h6">
          <h5>{{ Text }}</h5>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="salirocultar()">
            Salir
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="ocultar()">
            Eliminar Solicitud
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import axios from "axios";
  import moment from "moment";
  import { format, parseISO } from "date-fns";
  import Vue from "vue";
  import lightFormat from "date-fns/esm/lightFormat/index.js";
  export default {
    data: () => ({
      defaultdatabase: "",
      rules: {
        required: (value) => !!value || "Requerido",
      },
      dialogIniText: 'Cargando',
      Message: "",
      Text: "",
      aux: true,
      dialogIni: false,
      dialog500: false,
      dialog404: false,
      dialogaviso: false,
      dialogeliminar: false,
      menuDateBuscaA: false,
      menuDateBuscaB: false,
      fecha1: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      fecha2: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      timeI: "00:00:00",
      menuHI: false,
      timeF: "23:59:59",
      menuHF: false,
      tipo: "",
      archivo: "",
      tipos: ["Emitidos", "Recibidos"],
      archivos: ["Metadata", "CFDI"],
      id1: "",
      id2: "",
      solicitudeliminar: "",
      headers: [
        {
          text: "UUID",
          align: "center",
          value: "solicitud",
          width: "20%"
        },
        { text: "Tipo", value: "tipo", align: "center" },
        { text: "Fecha Inicial", value: "fecha", align: "center" },
        { text: "Fecha Final", value: "fecha2", align: "center" },
        { text: "Comprobantes", value: "cfdi", align: "center" },
        { text: "Estado", value: "estatus", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      items: [],
      pass: "",
      rfc: "",
      filecer: "",
      filekey: "",
      modelcer: null,
      modelkey: null,
    }),

    computed: {
      computedDateFormattedMomentjsA() {
        var localLocale = moment(this.fecha1).format("DD MMM YYYY");
        moment.locale("es");
        return this.fecha1 ? localLocale : "";
      },
      computedDateFormattedMomentjsB() {
        var localLocale = moment(this.fecha2).format("DD MMM YYYY");
        moment.locale("es");
        return this.fecha2 ? localLocale : "";
      },

      datosEmpresa() {
        if (this.$store.state.usuario.rol === "Ejecutivo") {
          this.banderaEditar = true;
        }
        return this.$store.state.usuario;
      },
    },

    methods: {
      cargartodo() {
        var vue = this;
        vue.dialogIniText = 'Cargando';
        vue.dialogIni = true;
        var Params = {
          db: vue.defaultdatabase,
          rfc: vue.$store.state.usuario.rfc,
        };
        axios
          .post("https://api-descargamasiva.contago.com.mx/api/DescargasXML/Consulta", Params)
          .then(function (response) {
            vue.items = response.data;
            vue.dialogIniText = 'Cargando';
            vue.dialogIni = false;
          })
          .catch(function (error) {
            vue.dialogIniText = 'Cargando';
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      },

      bt1() {
        var a = this.$refs.form1.validate();
        var b = this.$refs.form2.validate();
        if (a && b) {
          var vue = this;
          vue.dialogIniText = 'Creando solicitud...';
          vue.dialogIni = true;
          var Params = {
            db: vue.defaultdatabase,
            rfc: vue.$store.state.usuario.rfc,
            empresa: vue.$store.state.usuario.empresa,
            fecha_inicial: vue.fecha1 + " " + vue.timeI,
            fecha_final: vue.fecha2 + " " + vue.timeF,
            tipo: vue.tipo,
            archivo: vue.archivo,
          };
          axios
            .post("https://api-descargamasiva.contago.com.mx/api/DescargasXML/Solicitud", Params)
            .then(function (response) {
              vue.dialogIniText = 'Cargando';
              vue.dialogIni = false;
              vue.cargartodo();
            })
            .catch(function (error) {
              vue.dialogIniText = 'Cargando';
              vue.dialogIni = false;
              if (error.response) {
                if (error.response.status == 400) {
                  vue.Message = error.response.data.Message;
                  vue.dialog404 = true;
                } else {
                  vue.dialog500 = true;
                }
              } else {
                vue.dialog500 = true;
              }
              console.log(error);
            });
        }
      },

      async bt2a(item) {
        var vue = this;
        vue.dialogIniText = 'Descargando archivos...';
        vue.dialogIni = true;
        var Params = {
          db: vue.defaultdatabase,
          rfc: vue.$store.state.usuario.rfc,
          id: item.solicitud,
        };
        try {
          let response = await axios.post("https://api-descargamasiva.contago.com.mx/api/DescargasXML/Descarga", Params)
          console.log(response)
          vue.dialogIniText = 'Cargando';
          vue.dialogIni = false;
          if (item.archivo === 'CFDI') {
            await this.GuardaXml(item);
            vue.Text = 'Se han descargado los comprobantes con éxito';
          }
          if (item.archivo === 'Metadata') {
            await this.ActualizaCancelados(item);
            vue.Text = 'Se han actualizado los comprobantes con éxito';
          }
          vue.dialogaviso = true;
          vue.cargartodo();
        } catch (error) {
          vue.dialogIniText = 'Cargando';
          vue.dialogIni = false;
          console.log(error);
        }
      },
      async bt2b(item) {
        var vue = this;
        vue.solicitudeliminar = item.solicitud;
        if (item.estatus == "Solicitud Erronea") {
          vue.Text = "Se ha producido un error. ¿Desea eliminar la solicitud y hacer una nueva?";
        }
        else if (item.estatus == "Solicitud Rechazada") {
          vue.Text = "Se ha rechazado porque hay al menos una solicitud con horas y fechas completamente iguales o no hay comprobantes en las fechas solicitadas. ¿Desea eliminar la solicitud y hacer una nueva?";
        }
        else if (item.estatus == "Solicitud Vencida") {
          vue.Text = "Ha vencido el tiempo de descarga. ¿Desea eliminar la solicitud y hacer una nueva?";
        }
        else if (item.estatus == "Descargado") {
          if (item.archivo === 'CFDI') {
            await this.GuardaXml(item);
          }
          if (item.archivo === 'Metadata') {
            await this.ActualizaCancelados(item);
          }
          vue.Text = "Se han vuelto a guardar los XML. ¿Desea eliminar la solicitud?";
        }
        vue.dialogeliminar = true;
      },
      bt2c(item) {
        var vue = this;
        this.dialogIniText = 'Verificando estatus de descarga...'
        vue.dialogIni = true;
        var Params = {
          db: vue.defaultdatabase,
          rfc: vue.$store.state.usuario.rfc,
          id: item.solicitud,
        };
        axios
          .post(
            "https://api-descargamasiva.contago.com.mx/api/DescargasXML/Verificacion",
            Params
          )
          .then(function (response) {
            vue.dialogIniText = 'Cargando';
            vue.dialogIni = false;
            vue.cargartodo();
          })
          .catch(function (error) {
            vue.dialogIniText = 'Cargando';
            vue.dialogIni = false;
            if (error.response) {
              if (error.response.status == 400) {
                vue.Message = error.response.data.Message;
                vue.dialog404 = true;
              } else {
                vue.dialog500 = true;
              }
            } else {
              vue.dialog500 = true;
            }
            console.log(error);
          });
      },

      ocultar() {
        if (this.solicitudeliminar != '') {
          var vue = this;
          this.dialogIniText = 'Eliminando...'
          vue.dialogIni = true;
          var Params = {
            db: vue.defaultdatabase,
            rfc: vue.$store.state.usuario.rfc,
            id: vue.solicitudeliminar,
          };
          axios
            .post(
              "https://api-descargamasiva.contago.com.mx/api/DescargasXML/Ocultar",
              Params
            )
            .then(function (response) {
              vue.salirocultar();
              vue.dialogIniText = 'Cargando';
              vue.dialogIni = false;
              vue.cargartodo();
            })
            .catch(function (error) {
              vue.salirocultar();
              vue.dialogIniText = 'Cargando';
              vue.dialogIni = false;
              if (error.response) {
                if (error.response.status == 400) {
                  vue.Message = error.response.data.Message;
                  vue.dialog404 = true;
                } else {
                  vue.dialog500 = true;
                }
              } else {
                vue.dialog500 = true;
              }
              console.log(error);
            });
        }
      },

      salirocultar() {
        this.solicitudeliminar = '';
        this.dialogeliminar = false
      },

      funfecha1() {
        this.menuDateBuscaA = false;
        var a = this.fecha1;
        var b = this.fecha2;
        let dati = new Date(
          a.substr(0, 4),
          (parseInt(a.substr(5, 2)) - 1).toString(),
          a.substr(8, 2)
        );
        let datf = new Date(
          b.substr(0, 4),
          (parseInt(b.substr(5, 2)) - 1).toString(),
          b.substr(8, 2)
        );
        var dat2 = dati;
        dat2 = new Date(dat2.setMonth(dat2.getMonth() + 1));
        this.fecha2 = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
      },

      funfecha2() {
        this.menuDateBuscaB = false;
        var a = this.fecha1;
        var b = this.fecha2;
        let dati = new Date(
          a.substr(0, 4),
          (parseInt(a.substr(5, 2)) - 1).toString(),
          a.substr(8, 2)
        );
        let datf = new Date(
          b.substr(0, 4),
          (parseInt(b.substr(5, 2)) - 1).toString(),
          b.substr(8, 2)
        );
        if (dati > datf) {
          var dat2 = dati;
          dat2 = new Date(dat2.setMonth(dat2.getMonth() + 1));
          this.fecha2 = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
        }
        else if (dati = datf) {
          var time1 = parseInt(this.timeI.substr(0, 2) + this.timeI.substr(3, 2) + this.timeI.substr(6, 2));
          var time2 = parseInt(this.timeF.substr(0, 2) + this.timeF.substr(3, 2) + this.timeF.substr(6, 2));
          if (time1 >= time2) {
            var dat2 = dati;
            dat2 = new Date(dat2.setMonth(dat2.getMonth() + 1));
            this.fecha2 = format(parseISO(dat2.toISOString()), "yyyy-MM-dd");
          }
        }
      },
      funappdes() {
        window.open("https://contago.com.mx/contago-descargas");
      },

      descargafuncion() {
        this.items = [];
        this.fecha1 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        this.fecha2 = format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
        this.$refs.form1.reset();
        this.$refs.form2.reset();
        this.timeI = "00:00:00";
        this.timeF = "00:00:00";
        this.fecha1 = this.fecha1.substr(0, 8) + "01";
        this.fecha2 = this.fecha2.substr(0, 8) + "01";

        var a = this.fecha1;
        let dat = new Date(
          this.fecha1.substr(0, 4),
          (parseInt(this.fecha1.substr(5, 2)) - 1).toString(),
          this.fecha1.substr(8, 2)
        );
        if (this.fecha1.substr(5, 2) == 1) {
          dat = new Date(dat.setYear(parseInt(this.fecha2.substr(0, 4)) - 1));
          this.fecha1 = format(parseISO(dat.toISOString()), "yyyy-12-01");
        }
        else {
          dat = new Date(dat.setMonth(dat.getMonth() - 1));
          this.fecha1 = format(parseISO(dat.toISOString()), "yyyy-MM-dd");
        }
        this.$emit('descargafun');
      },


      uploadcer() {
        setTimeout(() => {
          const file = document.getElementById("filecer").files[0];
          if (file != null) {
            const reader = new FileReader();
            var vue = this;
            reader.onloadend = () => {
              this.filecer = reader.result;
              console.log(this.filecer);
            };
            reader.readAsDataURL(file);
          } else {
            this.filecer = "";
          }
        }, 100);
      },

      uploadkey() {
        setTimeout(() => {
          const file = document.getElementById("filekey").files[0];
          if (file != null) {
            const reader = new FileReader();
            var vue = this;
            reader.onloadend = () => {
              this.filekey = reader.result;
              console.log(this.filekey);
            };
            reader.readAsDataURL(file);
          } else {
            this.filekey = "";
          }
        }, 100);
      },

      async GuardaXml(item) {
        console.log(item)
        this.dialogIniText = 'Guardando XML...'
        this.dialogIni = true;
        try {
          let response = await axios.post('GuardaXml/PostXml/' + this.datosEmpresa.empresaBase + '/' + item.solicitud)
          console.log(response)
          this.dialogIniText = 'Cargando'
          this.dialogIni = false
        } catch (error) {
          console.log(error)
          this.dialogIniText = 'Cargando'
          this.dialogIni = false
        }
      },
      async ActualizaCancelados(item) {
        console.log(item)
        this.dialogIniText = 'Actualizando cancelados...'
        this.dialogIni = true;
        try {
          let response = await axios.get('GuardaXml/GetCancelados/' + this.datosEmpresa.empresaBase + '/' + item.solicitud)
          console.log(response)
          this.dialogIniText = 'Cargando'
          this.dialogIni = false
        } catch (error) {
          console.log(error)
          this.dialogIniText = 'Cargando'
          this.dialogIni = false
        }
      },
    },

    created() {
      this.cargartodo();
      if (true) {
        var localLocale = moment(this.fecha1).format("DD MMM YYYY");
        moment.locale("es");
      }
      if (true) {
        var localLocale = moment(this.fecha2).format("DD MMM YYYY");
        moment.locale("es");
      }
      this.fecha1 = this.fecha1.substr(0, 8) + "01";
      this.fecha2 = this.fecha2.substr(0, 8) + "01";
      var a = this.fecha1;
      let dat = new Date(
        this.fecha1.substr(0, 4),
        (parseInt(this.fecha1.substr(5, 2)) - 1).toString(),
        this.fecha1.substr(8, 2)
      );
      if (this.fecha1.substr(5, 2) == 1) {
        dat = new Date(dat.setYear(parseInt(this.fecha2.substr(0, 4)) - 1));
        this.fecha1 = format(parseISO(dat.toISOString()), "yyyy-12-01");
      }
      else {
        dat = new Date(dat.setMonth(dat.getMonth() - 1));
        this.fecha1 = format(parseISO(dat.toISOString()), "yyyy-MM-dd");
      }
    },
  };
</script>