var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogMessage)+" "),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('Dialog',{style:({width: '85vw'}),attrs:{"header":_vm.titulo,"visible":_vm.dialogDetalle,"maximizable":true,"modal":true,"contentStyle":{height: '550px'}},on:{"update:visible":function($event){_vm.dialogDetalle=$event}}},[_c('DetallesPagosProvisionales')],1),_c('v-dialog',{attrs:{"max-width":"1600"}},[_c('v-card',[_c('v-toolbar',[_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(this.titulo))]),_c('v-spacer'),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GeneraExcel()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('br'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"filtrar"},model:{value:(_vm.searchConsulta),callback:function ($$v) {_vm.searchConsulta=$$v},expression:"searchConsulta"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headerConsulta,"items":_vm.itesmDestalles,"search":_vm.searchConsulta,"fixed-header":"","height":"65vh"},scopedSlots:_vm._u([{key:"item.descuento",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.descuento)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.total)))])]}},{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotal)))])]}},{key:"item.subTotalPesos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotalPesos)))])]}},{key:"item.subTotalPesosCobrados",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.subTotalPesosCobrados)))])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogPFEA),callback:function ($$v) {_vm.dialogPFEA=$$v},expression:"dialogPFEA"}},[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){_vm.dialogPFEA = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Pérdidas Fiscales de Ejercicios Anteriores")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.PostPFEA()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Guardar")])])],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('br'),_c('v-card-text',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Importe ENE-FEB","outlined":""},model:{value:(_vm.itemsPerdida[0].importe),callback:function ($$v) {_vm.$set(_vm.itemsPerdida[0], "importe", $$v)},expression:"itemsPerdida[0].importe"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Importe MAR-DIC","outlined":""},model:{value:(_vm.itemsPerdida[2].importe),callback:function ($$v) {_vm.$set(_vm.itemsPerdida[2], "importe", $$v)},expression:"itemsPerdida[2].importe"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogCU),callback:function ($$v) {_vm.dialogCU=$$v},expression:"dialogCU"}},[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){_vm.dialogCU = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Coeficiente de Utilidad")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.PostCU()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Guardar")])])],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('br'),_c('v-card-text',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Importe ENE-FEB","outlined":""},model:{value:(_vm.itemsCoeficiente[0].importe),callback:function ($$v) {_vm.$set(_vm.itemsCoeficiente[0], "importe", $$v)},expression:"itemsCoeficiente[0].importe"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Importe MAR-DIC","outlined":""},model:{value:(_vm.itemsCoeficiente[2].importe),callback:function ($$v) {_vm.$set(_vm.itemsCoeficiente[2], "importe", $$v)},expression:"itemsCoeficiente[2].importe"}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogComparativa),callback:function ($$v) {_vm.dialogComparativa=$$v},expression:"dialogComparativa"}},[_c('v-card',[_c('v-toolbar',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){_vm.dialogComparativa = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar")])]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Importes Registrados "+_vm._s(_vm.fechaHome.anio))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.PostComparativa()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Guardar")])])],1),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}}),_c('br'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersComparativa,"items":_vm.itemsComparativa,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.importe",fn:function(props){return [_c('v-edit-dialog',{attrs:{"return-value":props.item.importe},on:{"update:returnValue":function($event){return _vm.$set(props.item, "importe", $event)},"update:return-value":function($event){return _vm.$set(props.item, "importe", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Editar","single-line":"","counter":""},model:{value:(props.item.importe),callback:function ($$v) {_vm.$set(props.item, "importe", $$v)},expression:"props.item.importe"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.importe)+" ")])]}}])})],1)],1)],1),_c('v-card',{staticClass:"mx-auto pb-10 ma-5",attrs:{"width":"1400","elevation":"0","color":"transparent"}},[_c('v-card',{staticClass:"justify-center",attrs:{"elevation":"3"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5 mr-5",attrs:{"color":"primary darken-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GeneraExcelPagosProvisionales()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2),_c('h3',{staticClass:"justify-center"},[_vm._v("PAGOS PROVISIONALES DE ISR")]),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-5 mr-5",attrs:{"color":"primary darken-1 ","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.GetReporte()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-autorenew")])],1)]}}])},[_c('span',[_vm._v("Actualizar")])])],1),_c('small',[_vm._v(_vm._s(_vm.regimenEmpresa))]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mx-auto pt-10 pb-10 ma-5",attrs:{"width":"1400","elevation":"3"}},[_c('v-row',{staticClass:"justify-center mb-4"},[_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h2',{staticClass:"text-center",attrs:{"font-family":"Arial"}},[_vm._v("CU")]),_c('v-chip',_vm._g(_vm._b({staticClass:"white--text mr-10 ml-10",staticStyle:{"width":"250px"},attrs:{"color":"blue darken-4","outlined":""},on:{"click":function($event){return _vm.openDialogCu()}}},'v-chip',attrs,false),on),[_c('span',[_vm._v(" ENE-FEB: "+_vm._s(_vm.itemsCoeficiente[0].importe)+" | MAR-DIC: "+_vm._s(_vm.itemsCoeficiente[2].importe))])])]}}])},[_c('span',[_vm._v("COEFICIENTE DE UTILIDAD")])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('h2',{staticClass:"text-center",attrs:{"font-family":"Arial"}},[_vm._v("PFEA")]),_c('v-chip',_vm._g(_vm._b({staticClass:"white--text mr-10 ml-10",staticStyle:{"width":"300px"},attrs:{"type":"number","color":"blue darken-4","outlined":""},on:{"click":function($event){return _vm.openDialogPFEA()}}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v("mdi-currency-usd")]),_c('span',[_vm._v("ENE-FEB: "+_vm._s(_vm.itemsPerdida[0].importe)+" | MAR-DIC: "+_vm._s(_vm.itemsPerdida[2].importe))])],1)]}}])},[_c('span',[_vm._v("PÉRDIDAS FISCALES DE EJERCICIOS ANTERIORES")])])],1),_c('div',{staticClass:"pt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text mt-6 mr-10 ml-10",attrs:{"color":"blue darken-4","outlined":""},on:{"click":function($event){return _vm.openDialogComparativa()}}},'v-chip',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table-large")]),_vm._v(" COMPARATIVA ")],1)]}}])},[_c('span',[_vm._v("COMPARATIVA")])])],1),_c('div',{staticClass:"text-right"})]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"fixed-header":"","height":"350px","item-class":_vm.row_classes,"headers":_vm.headerIG,"items":_vm.itemsPPIsr,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.mes != 'TOTAL:' && item.mes != '')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary darken-4"},on:{"click":function($event){return _vm.detalles(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document ")])]}}],null,true)},[_c('span',[_vm._v("Comprobaciones")])])]:_vm._e()]}},{key:"item.ingresoMes",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ingresoMes)))])]}},{key:"item.ingresoAcumulado",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ingresoAcumulado)))])]}},{key:"item.baseImpuestoPpIsr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.baseImpuestoPpIsr)))])]}},{key:"item.basePpIsr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.basePpIsr)))])]}},{key:"item.ppIsr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ppIsr)))])]}},{key:"item.impuestoCargo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.impuestoCargo)))])]}},{key:"item.impuestoRegistrado",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.impuestoRegistrado)))])]}},{key:"item.comparativa",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.comparativa)))])]}},{key:"item.ingresos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ingresos)))])]}},{key:"item.gastos",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.gastos)))])]}},{key:"item.nomina",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.nomina)))])]}},{key:"item.baseGravable",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.baseGravable)))])]}},{key:"item.gastosAcumulados",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.gastosAcumulados)))])]}},{key:"item.ingresosAcumulados",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.ingresosAcumulados)))])]}},{key:"item.excedente",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.excedente)))])]}},{key:"item.limiteInferior",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.limiteInferior)))])]}},{key:"item.tasa",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.tasa)))])]}},{key:"item.impuestoMarginal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.impuestoMarginal)))])]}},{key:"item.cuotaFija",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.cuotaFija)))])]}},{key:"item.isrCausado",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.isrCausado)))])]}},{key:"item.pagosAnterioresIsr",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.pagosAnterioresIsr)))])]}},{key:"item.isrCargo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.isrCargo)))])]}},{key:"item.pfea",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.pfea)))])]}},{key:"item.anticipoCuentas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.anticipoCuentas)))])]}},{key:"item.pagoAnterior",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.functionFormatPrice(item.pagoAnterior)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }