<template>
  <v-app>
    <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
    <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-icon dark @click="snackbar = false">
          mdi-close-circle
        </v-icon>
      </template>
    </v-snackbar>

    <!-- DIALOGO PARA SUBIR ARCHIVOS FIEL -->
    <v-dialog persistent v-model="dialogSubirArchivos" max-width="600">
      <v-card>
        <v-toolbar>
          <v-btn @click="dialogSubirArchivos = false" color="primary darken-1" dark fab small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Registrar Información</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="guardarSellos()" color="primary darken-1" dark fab small>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <v-card-text>
          <v-card elevation="0" class="ma-0 pa-0">
            <v-card-text class="text-center ma-0 pa-0">
              <v-icon class="ma-0 pa-0" size="150" color="rgb(255, 0, 0, 0.1)">mdi-account-key</v-icon>
            </v-card-text>
          </v-card>
          <v-file-input label="Seleccione el archivo .cer" accept=".cer" v-model="cer_e" @change="convertirBase64CER()"
            truncate-length="50"></v-file-input>
          <v-file-input label="Seleccione el archivo .key" accept=".key" v-model="key_e" @change="convertirBase64KEY()"
            truncate-length="50"></v-file-input>
          <v-text-field :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1" prepend-icon="mdi-lock" v-model="sello.contra" label="Contraseña">
          </v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDescargaXml" persistent max-width="1000">
      <Descarga @descargafun="closeDescarga"></Descarga>
    </v-dialog>

    <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ dialogMessage }}
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card tile class="mx-auto overflow-hidden" height="100%" width="100%">
      <v-system-bar app color="primary darken-1"></v-system-bar>
      <v-app-bar class="mt-5" color="primary" dark v-if="logueado">
        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h4></h4>
          <h5>{{ $store.state.usuario.empresa}} | {{ $store.state.usuario.rfc}}</h5>

        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click.stop="drawerCambioEmpresa = !drawerCambioEmpresa">
          <v-icon>mdi-domain</v-icon>
        </v-btn>

        <v-badge :color="colorMensaje" :icon="iconoMensaje" overlap bottom>
          <v-btn icon @click="abrirSubirArchivos">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-badge>

        <v-btn title="Descargar XML" icon @click="openDescarga">
          <v-icon>mdi-file-xml-box</v-icon>
        </v-btn>

        <v-btn icon @click="salir">
          <v-icon>mdi-account-arrow-left-outline</v-icon>
        </v-btn>
      </v-app-bar>

      <!-- MENU DE EMPRESAS -->
      <v-navigation-drawer width="300" v-model="drawerCambioEmpresa" absolute right temporary>
        <v-list-item>
          <v-list-item-avatar>
            <img class="ma-0 pa-0" src="../src/assets/logoContaGoDos.jpeg">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Mis Empresas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list shaped>
          <template>
            <v-list-item @click="CambioEmpresa(item)" color="primary"
              v-for="item in this.$store.state.listaEmpresasStore" :key="item.nombre_e" link>
              <v-list-item-icon>
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.nombre_e }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <!-- MENU DE VENTANAS -->
      <v-navigation-drawer v-model="drawer" absolute temporary v-if="logueado">
        <v-list-item>
          <v-list-item-avatar>
            <img class="ma-0 pa-0" src="../src/assets/logoContaGoDos.jpeg">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title><span>{{ $store.state.usuario.nombre}}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list shaped>
          <template>
            <v-list-item color="primary" :to="{path: '/'}">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Inicio</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template>
          <v-list-item color="primary darken-3" :to="{path: 'ReportePorFechas'}">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon> 
            <v-list-item-content>
              <v-list-item-title>Reportes por fechas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'SolicitudesDeCancelacion'}">
              <v-list-item-icon>
                <v-icon>mdi-thumb-up</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Solicitudes de Cancelación</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'ValidarRFC'}">
              <v-list-item-icon>
                <v-icon>mdi-format-list-checks</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Validar RFC y CURP</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Conceptos'}">
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Conceptos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template>
          <v-list-item color="red darken-3" :to="{path: 'Comprobantes'}">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Comprobantes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->

          <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero' ||  Usuario == 'Nomina'">
            <v-list-item color="primary" :to="{path: 'Nomina'}">
              <v-list-item-icon>
                <v-icon>mdi-account-cash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Nómina</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template
            v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero' || Usuario == 'CuentasCobrar' || Usuario == 'CuentasPagar'">
            <v-list-item color="primary" :to="{path: 'CuentasCobrarYPagar'}">
              <v-list-item-icon>
                <v-icon>mdi-cash-fast</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cuentas por cobrar y por pagar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Anticipos'}">
              <v-list-item-icon>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Anticipos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template>
          <v-list-item color="primary darken-3" :to="{path: 'MovimientosBancarios'}">
            <v-list-item-icon>
              <v-icon>mdi-cash-refund</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Movimientos Bancarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->
          <template v-if="Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Gasolinera'}">
              <v-list-item-icon>
                <v-icon>mdi-gas-station</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Gasolinera</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Compras'}">
              <v-list-item-icon>
                <v-icon>mdi-file-document-minus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Compras/Gastos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Ventas'}">
              <v-list-item-icon>
                <v-icon>mdi-account-cash-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ventas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="Usuario == 'Administrador'|| Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'Bancos'}">
              <v-list-item-icon>
                <v-icon>mdi-bank-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Bancos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template v-if="Usuario == 'Administrador' || Usuario == 'Gasolinero'">
            <v-list-item color="primary" :to="{path: 'IngresosNomina'}">
              <v-list-item-icon>
                <v-icon>mdi-account-cash-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ingresos Nomina</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
        </v-list>
      </v-navigation-drawer>
      <v-main class="pa-0 ma-0">
        <router-view />
      </v-main>

    </v-card>
    <v-footer dark padless>
      <v-card class="flex text-center" flat tile>
        <v-card-text class="primary darken-1 py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>contago</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import Descarga from '../src/components/DescargaXml/DescargaXml'

  export default {
    name: 'App',

    components: {
      Descarga
    },

    data: () => ({
      //
      drawerCambioEmpresa: false,
      drawer: false,
      nombreUsuario: '',

      //
      dialogMessage: '',
      dialog: false,

      dialogSubirArchivos: false,

      key_e: [],
      cer_e: [],
      contra_e: '',
      show1: false,
      // sello: {},

      // SNACKBAR
      snackbar: false,
      snackbarMessage: '',

      dialogDescargaXml: false,

      iconoMensaje: '',
      colorMensaje: '',

      itemEmpresaVincular: {}
    }),
    computed: {
      logueado() {
        return this.$store.state.usuario;
      },
      obtieneEmpresa() {
        return this.$store.state.usuario.empresa;
      },
      obtieneUsuario() {
        return this.$store.state.usuario.nombre;
      },
      sello() {
        return this.$store.state.sellosStore;
      },

      esAdministrador() {
        return (
          this.$store.state.usuario &&
          this.$store.state.usuario.rol == "Administrador"
        );
      },
      Usuario() {
        return this.$store.state.usuario.rol
      },
    },
    created() {
      this.$store.dispatch("autoLogin");
      this.cambiarColor();
      this.validarSellos();
      this.listaEmpresas()
    },
    methods: {
      CambioEmpresa(item) {
        console.log(item)
        this.itemEmpresaVincular = item
        this.ingresar();
      },
      abrirSubirArchivos() {
        this.dialogSubirArchivos = true
      },
      cambiarColor() {
        if (this.$store.state.usuario.rol == 'Gasolinero') {
          this.$vuetify.theme.themes.light.primary = '#009341'
          return this.$vuetify.theme.themes.light.primary
        }
        else {
          this.$vuetify.theme.themes.light.primary = '#C62828'
          return this.$vuetify.theme.themes.light.primary
        }
      },

      async salir() {
        this.dialogMessage = 'Cerrando sesión ...'
        this.dialog = true;
        let respuesta;
        try {
          respuesta = await axios.put(
            "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
            this.$store.state.usuario.nombre +
            "/INACTIVA"
          );
          this.$store.dispatch("salir");
          this.$vuetify.theme.themes.light.primary = '#C62828'
          this.dialog = false;
        } catch (err) {
          console.log(err);
          this.dialog = false;
        }
      },
      closeDescarga() {
        this.dialogDescargaXml = false;
      },
      openDescarga() {
        this.dialogDescargaXml = true;
      },
      convertirBase64KEY() {
        this.archivoKEY = {};

        let file = this.key_e;
        let reader = new FileReader();
        reader.onloadend = (file) => {
          this.archivoKEY.base64 = reader.result;
          this.sello.selloKey = this.archivoKEY
          console.log(this.archivoKEY)
        }
        reader.readAsDataURL(file);
      },
      convertirBase64CER() {
        this.archivoCER = {};
        let file = this.cer_e;
        let reader = new FileReader();
        reader.onloadend = (file) => {
          this.archivoCER.base64 = reader.result;
          this.sello.selloCer = this.archivoCER;
          console.log(this.archivoCER)
        }
        reader.readAsDataURL(file);
      },

      async guardarSellos() {
        this.sello.tipo = "Fiel"
        this.sello.rfc = this.$store.state.usuario.rfc
        let base = this.$store.state.usuario.empresaBase
        console.log(this.sello)
        console.log(base)
        this.dialogMessage = 'Cargando archivos...'
        this.dialog = true;
        try {
          let response = await axios.post('configuracion/postSellos/' + base, this.sello);
          console.log(response.data)
          this.snackbarMessage = 'Archivos cargados.'
          this.snackbar = true
          this.dialog = false;
          this.validarSellos()
        } catch (error) {
          console.log(error)
          this.snackbarMessage = 'Error al cargar archivos, verifique los archivos.'
          this.snackbar = true
        }
        this.dialog = false;

      },

      async validarSellos() {
        let base = this.$store.state.usuario.empresaBase

        try {
          let response = await axios.get('configuracion/GetVigenciaFiel/' + base);
          console.log(response.data)
          console.log('Fiel Vigente')
          this.colorMensaje = 'green'
          this.iconoMensaje = 'mdi-check-bold'
        } catch (error) {
          console.log(error)
          this.snackbarMessage = error.response.data
          this.snackbar = true
          this.dialogSubirArchivos = true
          this.colorMensaje = 'orange'
          this.iconoMensaje = 'mdi-alert'
        }
      },

      listaEmpresas(item) {
        axios.get('https://api-framework.contago.com.mx/api/Usuarios/Empresas/' + this.$store.state.usuario.idusuariosApp + '/' + 'DESERIALIZADOR')
          .then(response => {
            this.$store.state.listaEmpresasStore = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      ingresar() {
        this.dialog= true
        this.dialogMessage = 'Cargando empresa..'
         axios.post('https://api-framework.contago.com.mx/api/Usuarios/Login', {
          idUsuario: this.$store.state.usuario.idusuariosApp,
          nombre: this.$store.state.usuario.nombre, rol: this.$store.state.usuario.rol, empresa: this.itemEmpresaVincular.nombre_e, empresaBase:
            this.itemEmpresaVincular.nombreBase, firma: this.$store.state.usuario.firma, rfc: this.itemEmpresaVincular.rfc
        })
          .then(response => {
            console.log(response.data);
            return response.data
          })
          .then(data => {
            this.$store.dispatch("guardarToken", data.token)
            this.$router.push({ name: 'Home' })
            location.reload();

          }).catch(err => {
            if (err.response.status == 500) {
              this.error = err.response;
              this.snackbar = true;
            }
          });

      },

    },
  };
</script>