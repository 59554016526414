<template>
    <div >
        <!-- DIALOG PARA VER DETALLES -->
        <Dialog  :header="tituloArchivo" :visible.sync="dialogConceptos" :style="{width: '85vw'}" :maximizable="true"
            :modal="true" :contentStyle="{height: '550px'}">
            <DataTable :exportFilename="tituloArchivo" ref="Conceptos" removableSort  filterDisplay="menu"   class="p-datatable-sm p-datatable-customers" :value="itemsConceptos" showGridlines :scrollable="true" 
        scrollHeight="flex" :paginator="true" :rows="15" 
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[15,30,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :filters.sync="filtrosConceptos" :globalFilterFields="['claveProdServicio','noIdentificacion','descripcion','claveUnidad','unidad','cantidad','valorUnitario','descuento','importe']">
            <template #header>
                <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText  v-model="filtrosConceptos['global'].value" placeholder="Buscar..." style="width: 500px;"/>
                </span>
                <div style="text-align: left">
                    <Button @click="limpiarFiltrosConceptos()" style="width: 200px;" type="button" icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined"/>
                    <Button @click="exportCSVConceptos($event)" style="width: 200px;" icon="pi pi-external-link" label="Exportar" />
                </div>
                </div>
            </template>

            <Column sortable field="claveProdServicio" header="Clave del Producto o Servicio" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'300px'}">
                <template #body="{data}">
                    {{data.claveProdServicio}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="noIdentificacion" header="No. Identificación" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.noIdentificacion}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="descripcion" header="Descripción" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.descripcion}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="claveUnidad" header="Clave Unidad" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.claveUnidad}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="unidad" header="Unidad" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.unidad}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="cantidad" header="Cantidad" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.cantidad}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="valorUnitario" header="Valor Unitario" filterField="valorUnitario" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.valorUnitario)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="descuento" header="Descuento" filterField="descuento" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.descuento)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="importe" header="Importe" filterField="importe" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.importe)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN" locale="en-ES" />
                </template>
            </Column>
        </DataTable>
        </Dialog>
        
        <DataTable  :exportFilename="nombreArchivo" ref="Detalles" removableSort  filterDisplay="menu" class="p-datatable-sm p-datatable-customers" :value="items" showGridlines :scrollable="true" 
        scrollHeight="400px" :paginator="true" :rows="15" 
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[15,30,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :filters.sync="filtros" :globalFilterFields="['serie','folio','fecha','subTotal','total','descuento','tipoComprobante','formaPago','metodoPago','folioFiscal']">
            <template #header>
                <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText  v-model="filtros['global'].value" placeholder="Buscar..." style="width: 500px;"/>
                </span>
                <div style="text-align: left">
                    <Button @click="limpiarFiltros()" style="width: 200px;" type="button" icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined"/>
                    <Button @click="exportCSV($event)" style="width: 200px;" icon="pi pi-external-link" label="Exportar" />
                </div>
                </div>
            </template>

            <Column sortable field="serie" header="Serie" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.serie}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="folio" header="Folio" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.folio}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="fecha" header="Fecha" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'100px'}">
                <template #body="{data}">
                    {{formatDate(data.fecha)}}
                </template>
                <template #filter="{filterModel}">
                    <InputText  type="text"  v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="subTotal" header="Subtotal" filterField="subTotal" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.subTotal)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="descuento" header="Descuento" filterField="descuento" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.descuento)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="total" header="Total" filterField="total" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.total)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN" locale="en-ES" />
                </template>
            </Column>

            <Column sortable field="tipoComprobante" header="Tipo de Comprobante" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.tipoComprobante}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="formaPago" header="Forma de Pago" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.formaPago}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="metodoPago" header="Método Pago" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.metodoPago}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="folioFiscal" header="FolioFiscal" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.folioFiscal}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>
            <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                :bodyStyle="{'justify-content': 'center', overflow: 'visible'}"
                :styles="{'min-width':'100px'}">
                <template #body="{data}">
                    <Button @click="verConceptos(data)" type="button"
                        class="p-button-sm" icon="pi pi-bars"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>    
<script>
import moment from "moment";
import {FilterMatchMode,FilterOperator} from 'primevue/api';

export default {
    components: {
    },
    data: () => ({
        filtros: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'serie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folioFiscal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'subTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'formaPago': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'metodoPago': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'tipoComprobante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'descuento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
            },

            filtrosConceptos: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'claveProdServicio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'noIdentificacion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'descripcion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'valorUnitario': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'descuento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'importe': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'claveUnidad': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'unidad': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'cantidad': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            },

            itemsConceptos:[],
            dialogConceptos:false,
            tituloArchivo:'',
a:3 ,
b:1
    }),
    computed: {
        nombreArchivo(){
            return this.$store.state.tituloDetalleStore
        },

        items() {
            return this.$store.state.listaDetalleStore;
        },
    },
    methods:{
        verConceptos(item) {
                this.dialogConceptos = true
                this.itemsConceptos = []
                this.itemsConceptos = item.conceptos
                this.tituloArchivo = 'Conceptos_' + item.folioFiscal
                this.b = 2
            },

        exportCSVConceptos() {
            this.$refs.Conceptos.exportCSV();
        },
        exportCSV() {
            this.$refs.Detalles.exportCSV();
        },
        limpiarFiltros(){
            this.filtros= {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'serie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folioFiscal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'subTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'moneda': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'formaPago': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'metodoPago': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'descuento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'tipoComprobante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            }
        },
        limpiarFiltrosConceptos(){
            this.filtrosConceptos={
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'claveProdServicio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'noIdentificacion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'descripcion': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'valorUnitario': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'descuento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'importe': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'claveUnidad': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'unidad': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'cantidad': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            }
        },
        formatCurrency(value) {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");

        },
    },
}
</script>