<template>
    <div class="app pt-5">

        <!-- DIALOGO PARA VER DETALLES FACTURADOS -->
        <Dialog :header="titulo" :visible.sync="dialogDocumentos" :style="{width: '85vw'}" :maximizable="true"
            :modal="true" :contentStyle="{height: '550px'}">
            <DetallesAnticipos></DetallesAnticipos>
    </Dialog>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- titulo -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Bancos</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>

        <!-- MENU PARA ATICIPOS -->
        <v-card class="justify-center pb-5" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>
                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=1"
                                    class="mx-auto justify-center mr-3 rounded-lg" width="170" height="150" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-1 mb-1">Cuentas de Depósito</h3>
                                        <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Cuentas de Depósito
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=2"
                                    class="mx-auto justify-center ml-3 rounded-lg" width="170" height="150" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-1 mb-1">Cuentas de Retiro</h3>
                                        <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Cuentas de Retiro
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>
                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>

        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectAño" :items="itemsAño" label="Año"></v-select>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectMes" :items="itemsMes" label="Mes"></v-select>
                </v-col>
            </v-row>
        </v-card>

        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- LISTA DE CUENTAS DE DEPOSITO -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <v-spacer></v-spacer>
                        <h2 class="justify-center">Cuentas de Depósito</h2>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-2 mt-2" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="ConsultarAnticiposEmitidos()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar Cuentas de Depósito</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>

                <v-card elevation="6" class="mt-5 pb-10 ma-5 pa-5 mt-10">
                    <DataTable exportFilename="AnticiposEmitidos" ref="AnticiposEmitidos" :value="itemsEmitidos"
                        :paginator="true" class="p-datatable-sm p-datatable-datosGen" showGridlines :rows="20"
                        :filters.sync="filtrosDepositos" filterDisplay="menu" responsiveLayout="scroll"
                        removableSort :scrollable="true" scrollHeight="500px"
                        :globalFilterFields="['fecha', 'nombre', 'total']"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosDepositos['global'].value"
                                        placeholder="Buscar..." style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="clearFilterDepositos()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarAnticiposEmitidos($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            No se encontraron datos.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>

                        <Column sortable field="fecha" header="Fecha" :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fecha)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="nombre" header="Nombre" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.serie}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="total" header="Total"
                            filterField="total" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalFacturado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                    </DataTable>
                </v-card>
            </v-window-item>

            <!-- LISTA DE CUENTAS DE RETIRO -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <v-spacer></v-spacer>
                        <h2 class="justify-center">Cuentas de Retiro</h2>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="ConsultarAnticiposRecibidos()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>Consultar</span>
                            </v-tooltip>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="" class="mt-5 pb-10 ma-5 pa-5 mt-10">
                    <DataTable exportFilename="AnticiposRecibidos" ref="AnticiposRecibidos" :value="itemsRecibidos"
                        :paginator="true" class="p-datatable-sm p-datatable-datosGen" showGridlines :rows="20"
                        :filters.sync="filtrosAnticiposRecibidos" filterDisplay="menu" responsiveLayout="scroll"
                        removableSort :scrollable="true" scrollHeight="600px"
                        :globalFilterFields="['serie', 'folio','fecha', 'totalAnticipo', 'moneda', 'totalFacturado', 'totalNotaCredito', 'formaPago', 'rfc', 'nombre', 'folioFiscal']"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosAnticiposRecibidos['global'].value"
                                        placeholder="Buscar..." style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="clearFilterAnticiposRecibidos()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarAnticiposRecibidos($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            No se encontraron datos.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>
                        <Column :bodyStyle="{'justify-content':'center'}" field="serie" header="Serie" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.serie}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por serie" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="folio" header="Folio" :sortable="true"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.folio}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por folio" />
                            </template>
                        </Column>

                        <Column sortable field="fecha" header="Fecha" :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fecha)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalFacturado" header="Total Facturado"
                            filterField="totalFacturado" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalFacturado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalAnticipo" header="Total Anticipo"
                            filterField="totalAnticipo" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalAnticipo)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalNotaCredito"
                            header="Total Nota de Crédito" filterField="totalNotaCredito" :sortable="true"
                            dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalNotaCredito)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="moneda" header="Moneda"
                            :sortable="true" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.moneda}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por moneda" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="formaPago" header="Forma Pago"
                            :sortable="true" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.formaPago}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar..." />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="rfc" header="RFC" :sortable="true"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por RFC" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'left'}" field="nombre" header="Nombre" :sortable="true"
                            :styles="{'min-width':'500px'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>


                        <Column :bodyStyle="{'justify-content':'center'}" field="folioFiscal" header="Folio Fiscal"
                            :sortable="true" :styles="{'min-width':'400px'}">
                            <template #body="{data}">
                                {{data.folioFiscal}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por Folio Fiscal" />
                            </template>
                        </Column>

                        <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                            :bodyStyle="{'justify-content': 'center', overflow: 'visible'}"
                            :styles="{'min-width':'100px'}">
                            <template #body="{data}">
                                <Button @click="verFacturas(data, 'Facturas Recibidos')" type="button"
                                    class="p-button-sm" icon="pi pi-bars"></Button>
                                <Button @click="verNotasCredito(data, 'Notas de Crédito Recibidos')" type="button"
                                    class="p-button-sm" icon="pi pi-bars"></Button>
                            </template>
                        </Column>

                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="3" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalFacturadoRecibidos"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalRecibidos"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalNCRecibidos"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'500px','width':'500px','max-width':'500px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'400px','width':'400px','max-width':'400px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'100px','width':'100px','max-width':'100px'}" />
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </v-card>
            </v-window-item>
        </v-window>
    </div>
</template>
<script>
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import axios from 'axios';
    import { FilterMatchMode, FilterOperator } from 'primevue/api/';
    import DetallesAnticipos from './DialogosDetalles/DetallesAnticipos.vue'

    export default {
        components: {
            DetallesAnticipos
        },
        data: () => ({
            step: 1,
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            SelectAño: '',
            SelectMes: '',
            itemsAño: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018'
            ],
            itemsMes: [
                { text: 'Enero', value: '01' },
                { text: 'Febrero', value: '02' },
                { text: 'Marzo', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Mayo', value: '05' },
                { text: 'Junio', value: '06' },
                { text: 'Julio', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Septiembre', value: '09' },
                { text: 'Octubre', value: '10' },
                { text: 'Noviembre', value: '11' },
                { text: 'Diciembre', value: '12' },
            ],
            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            // DIALOGOS
            dialogDocumentos: false,

            titulo: '',
            itemsEmitidos:[],
            itemsRecibidos:[],
            filtrosDepositos: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
            },
            filtrosAnticiposRecibidos: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'totalAnticipo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalNotaCredito': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
            },

        }
        ),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            totalEmitidos() {
                let total = 0;
                for (let suma of this.itemsEmitidos) {
                    total += suma.totalAnticipo;
                }
                return this.formatCurrency(total);
            },
            totalFacturadoEmitidos() {
                let total = 0;
                for (let suma of this.itemsEmitidos) {
                    total += suma.totalFacturado;
                }
                return this.formatCurrency(total);
            },
            totalNCEmitidos() {
                let total = 0;
                for (let suma of this.itemsEmitidos) {
                    total += suma.totalFacturado;
                }
                return this.formatCurrency(total);
            },
            totalRecibidos() {
                let total = 0;
                for (let suma of this.itemsRecibidos) {
                    total += suma.totalAnticipo;
                }
                return this.formatCurrency(total);
            },
            totalFacturadoRecibidos() {
                let total = 0;
                for (let suma of this.itemsRecibidos) {
                    total += suma.totalFacturado;
                }
                return this.formatCurrency(total);
            },
            totalNCRecibidos() {
                let total = 0;
                for (let suma of this.itemsRecibidos) {
                    total += suma.totalFacturado;
                }
                return this.formatCurrency(total);
            },
        },
        methods: {
            async ConsultarAnticiposEmitidos() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('Anticipos/GetAnticiposEmitidos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    console.log(response.data)
                    this.itemsEmitidos = response.data
                    this.totalAnticipos = this.sum(this.itemsEmitidos, 'totalAnticipo')
                    this.totalFacturado = this.sum(this.itemsEmitidos, 'totalFacturado')
                    this.totalNC = this.sum(this.itemsEmitidos, 'totalNotaCredito')

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultarAnticiposRecibidos() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('Anticipos/GetAnticiposRecibidos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    console.log(response.data)
                    this.itemsRecibidos = response.data
                    this.totalAnticiposR = this.sum(this.itemsRecibidos, 'totalAnticipo')
                    this.totalFacturadoR = this.sum(this.itemsRecibidos, 'totalFacturado')
                    this.totalNCR = this.sum(this.itemsRecibidos, 'totalNotaCredito')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            verNotasCredito(item, value) {
                this.titulo = value + ' (Folio: ' + item.folio + ')'
                this.dialogDocumentos = true
                this.$store.state.listaDetalleStore = []
                this.$store.state.listaDetalleStore = item.notasCredito
                this.$store.state.tituloDetalleStore = value + '_' + item.folioFiscal
            },
            verFacturas(item, value) {
                this.titulo = value + ' (Folio: ' + item.folio + ')'
                this.dialogDocumentos = true
                this.$store.state.listaDetalleStore = []
                this.$store.state.listaDetalleStore = item.facturas
                this.$store.state.tituloDetalleStore = value + '_' + item.folioFiscal
            },
            
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },

            exportarAnticiposEmitidos() {
                this.$refs.AnticiposEmitidos.exportCSV();
            },
            clearFilterDepositos() {
                this.filtrosDepositos = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                }
            },
            exportarAnticiposRecibidos() {
                this.$refs.AnticiposRecibidos.exportCSV();
            },
            clearFilterAnticiposRecibidos() {
                this.filtrosAnticiposRecibidos = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'totalAnticipo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalNotaCredito': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                }
            },
            formatDate(date) {
                return moment(date).format("yyyy-MM-DD");
            },

            formatCurrency(value) {
                return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
            }
        },
    };
</script>