<template>
    <div class="app pt-5">

        <!-- DIALOGO PARA VER DETALLES FACTURADOS -->
        <Dialog :header="titulo" :visible.sync="dialogDocumentos" :style="{width: '85vw'}" :maximizable="true"
            :modal="true" :contentStyle="{height: '550px'}">
            <DetallesCuentasCobrarPagar></DetallesCuentasCobrarPagar>
        </Dialog>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
  
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO --> 
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- titulo -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Cuentas por Cobrar y por Pagar</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>

        <!-- MENU DE CUENTAS POR PAGAR -->
        <template v-if="datosEmpresa.rol != 'CuentasPagar' && datosEmpresa.rol != 'CuentasCobrar'">
            <v-card class="justify-center pb-5" flat color="transparent">
                <v-item-group mandatory>
                    <v-container>
                        <v-row dense>
                            <v-item v-slot="{ active, toggle }">
                                <v-hover v-slot="{ hover }" open-delay="10">
                                    <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }" @click="step=1"
                                        class="mx-auto justify-center mr-5 rounded-lg" width="170" height="150" link>
                                        <v-card-text class="text-center" @click="toggle">
                                            <v-icon v-if="!active" size="80" color="primary">
                                                mdi-file-document-multiple-outline</v-icon>
                                            <v-icon v-if="active" size="80" color="white">
                                                mdi-file-document-multiple-outline
                                            </v-icon>
                                            <h3 v-if="!active" class="mt-1 mb-1">Cuentas por Cobrar</h3>
                                            <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Cuentas por Cobrar
                                            </h3>
                                        </v-card-text>
                                    </v-card>
                                </v-hover>
                            </v-item>

                            <v-item v-slot="{ active, toggle }">
                                <v-hover v-slot="{ hover }" open-delay="10">
                                    <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }" @click="step=2"
                                        class="mx-auto justify-center ml-5 rounded-lg" width="170" height="150" link>
                                        <v-card-text class="text-center" @click="toggle">
                                            <v-icon v-if="!active" size="80" color="primary">
                                                mdi-file-document-multiple-outline</v-icon>
                                            <v-icon v-if="active" size="80" color="white">
                                                mdi-file-document-multiple-outline
                                            </v-icon>
                                            <h3 v-if="!active" class="mt-1 mb-1">Cuentas por Pagar</h3>
                                            <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Cuentas por Pagar
                                            </h3>
                                        </v-card-text>
                                    </v-card>
                                </v-hover>
                            </v-item>
                        </v-row>
                    </v-container>
                </v-item-group>
            </v-card>
        </template>

        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectAño" :items="itemsAño" label="Año"></v-select>
                </v-col>
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-select prepend-icon="mdi-calendar" v-model="SelectMes" :items="itemsMes" label="Mes"></v-select>
                </v-col>
            </v-row>
        </v-card>

        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- LISTA DE COMPROBANTES -->
            <v-window-item :value="0"></v-window-item>
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <v-spacer></v-spacer>
                        <h2 class="justify-center">Cuentas por Cobrar</h2>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 " color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="ConsultarCuentasCobrar()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar Cuentas Por Cobrar</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <DataTable exportFilename="CuentasCobrar" ref="CuentasCobrar" :value="datosGen" :paginator="true"
                        class="p-datatable-sm p-datatable-datosGen" showGridlines :rows="20" 
                        :filters.sync="filtrosCobrar" filterDisplay="menu" :loading="loading" responsiveLayout="scroll"
                        removableSort :scrollable="true" scrollHeight="500px" columnKey="folioFiscal"
                        :globalFilterFields="['version', 'serie', 'folio', 'rfc', 'nombre', 'porCobrar', 'cobrado', 'totalFacturado', 'totalNC', 'moneda', 'totalCobradoPesos', 'totalNCPesos', 'folioFiscal']"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosCobrar['global'].value" placeholder="Buscar..."
                                        style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="clearFilterCobrar()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarCuentasCobrar($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>

                        <template #empty>
                            No se encontraron datos.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>

                        <Column :bodyStyle="{'justify-content':'center'}" field="version" header="Versión"
                            :sortable="true" :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.version}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por Versión" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="serie" header="Serie" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.serie}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por serie" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="folio" header="Folio" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.folio}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por folio" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="rfc" header="RFC" :sortable="true"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por RFC" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'left'}" field="nombre" header="Nombre" :sortable="true"
                            :styles="{'min-width':'500px'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="porCobrar" header="Por Cobrar"
                            filterField="porCobrar" :sortable="true" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.porCobrar)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="cobrado" header="Cobrado"
                            filterField="cobrado" :sortable="true" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.cobrado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalFacturado" header="Total Facturado"
                            filterField="totalFacturado" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalFacturado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalNC" header="Total NC"
                            filterField="totalNC" :sortable="true" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalNC)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="moneda" header="Moneda"
                            :sortable="true" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.moneda}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por moneda" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalFacturadoPesos"
                            header="Total Facturado (pesos)" filterField="totalFacturadoPesos" :sortable="true"
                            dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalFacturadoPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalCobradoPesos"
                            header="Total Pesos cobrados" filterField="totalCobradoPesos" :sortable="true"
                            dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalCobradoPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalNCPesos" header="Total NC (pesos)"
                            filterField="totalNCPesos" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalNCPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="folioFiscal" header="Folio Fiscal"
                            :sortable="true" :styles="{'min-width':'400px'}">
                            <template #body="{data}">
                                {{data.folioFiscal}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por Folio Fiscal" />
                            </template>
                        </Column>

                        <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                            :bodyStyle="{'justify-content': 'center', overflow: 'visible'}"
                            :styles="{'min-width':'80px'}">
                            <template #body="{data}">
                                <Button @click="verDocumentosCobrar(data, 'Documentos por cobrar')" type="button"
                                    class="p-button-sm" icon="pi pi-bars"></Button>
                            </template>
                        </Column>

                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="5" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalPorC"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalC"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalFac"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalNCo"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalFactP"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalPesosCo"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalNCoP"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'400px','width':'400px','max-width':'400px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'80px','width':'80px','max-width':'80px'}" />
                            </Row>
                        </ColumnGroup>

                    </DataTable>
                </v-card>
            </v-window-item>

            <!-- LISTA DE COMPROBANTES VERIFICADOS -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <v-spacer></v-spacer>
                        <h2 class="justify-center">Cuentas por Pagar</h2>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="ConsultarCuentasPagar()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar Cuentas Por Pagar</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <!-- PRIMEVUE PAGOS-->
                    <DataTable exportFilename="CuentasPagar" ref="CuentasPagar" :value="datosGenPagos" :paginator="true"
                        class="p-datatable-sm p-datatable-datosGenPagos" showGridlines :rows="20" dataKey="folioFiscal" 
                        :filters.sync="filtersPagos" filterDisplay="menu" :loading="loading" responsiveLayout="scroll"
                        removableSort :scrollable="true" scrollHeight="500px" columnKey="folioFiscal"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :globalFilterFields="['version', 'serie', 'folio', 'rfc', 'nombre', 'porPagar', 'pagado', 'totalFacturado', 'totalNC', 'moneda', 'totalPagadoPesos', 'totalNCPesos', 'folioFiscal']">

                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtersPagos['global'].value" placeholder="Buscar..."
                                        style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="clearFilterPago()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarCuentasPagar($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>

                        <template #empty>
                            No se encontraron datos.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>

                        <Column :bodyStyle="{'justify-content':'center'}" field="version" header="Versión"
                            :sortable="true" :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.version}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por Versión" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="serie" header="Serie" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.serie}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por serie" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="folio" header="Folio" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.folio}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por folio" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="rfc" header="RFC" :sortable="true"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por RFC" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'left'}" field="nombre" header="Nombre" :sortable="true"
                            :styles="{'min-width':'500px'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="porPagar" header="Por Pagar"
                            filterField="porPagar" :sortable="true" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.porPagar)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="pagado" header="Pagado"
                            filterField="pagado" :sortable="true" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.pagado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalFacturado" header="Total Facturado"
                            filterField="totalFacturado" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalFacturado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalNC" header="Total NC"
                            filterField="totalNC" :sortable="true" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalNC)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="moneda" header="Moneda"
                            :sortable="true" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.moneda}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por moneda" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalFacturadoPesos"
                            header="Total Facturado (pesos)" filterField="totalFacturadoPesos" :sortable="true"
                            dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalFacturadoPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalPagadoPesos"
                            header="Total Pesos Pagados" filterField="totalPagadoPesos" :sortable="true"
                            dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalPagadoPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="totalNCPesos" header="Total NC (pesos)"
                            filterField="totalNCPesos" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalNCPesos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'center'}" field="folioFiscal" header="Folio Fiscal"
                            :sortable="true" :styles="{'min-width':'400px'}">
                            <template #body="{data}">
                                {{data.folioFiscal}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por Folio Fiscal" />
                            </template>
                        </Column>

                        <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                            :bodyStyle="{'justify-content': 'center', overflow: 'visible'}"
                            :styles="{'min-width':'80px'}">
                            <template #body="{data}">
                                <Button @click="verDocumentosPagar(data, 'Documentos por pagar')" type="button"
                                    class="p-button-sm" icon="pi pi-bars"></Button>
                            </template>
                        </Column>

                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="5" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalXPagar"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalPago"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalFacXPagar"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalNCoPagado"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalFactPagadaPesos"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalPesosPag"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalNCoPesosPag"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'400px','width':'400px','max-width':'400px'}" />
                                <Column
                                    :footerStyle="{'text-align':'right','min-width':'80px','width':'80px','max-width':'80px'}" />
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </v-card>
            </v-window-item>
        </v-window>

        <span hidden>{{datosEmpresa}}</span>
    </div>
</template>
<script>
    import axios from 'axios';
    import { FilterMatchMode, FilterOperator } from 'primevue/api/';
    import DetallesCuentasCobrarPagar from './DialogosDetalles/DetallesCuentasCobrarPagar.vue'
    export default {
        components: {
            DetallesCuentasCobrarPagar
        },
        data: () => ({
            step: 1,
            SelectAño: '',
            SelectMes: '',
            itemsAño: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018'
            ],
            itemsMes: [
                { text: 'Enero', value: '01' },
                { text: 'Febrero', value: '02' },
                { text: 'Marzo', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Mayo', value: '05' },
                { text: 'Junio', value: '06' },
                { text: 'Julio', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Septiembre', value: '09' },
                { text: 'Octubre', value: '10' },
                { text: 'Noviembre', value: '11' },
                { text: 'Diciembre', value: '12' },
            ],

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
            // DIALOGOS
            dialogDocumentos: false,

            titulo: '',

            // PRIMEVUE
            loading: true,
            datosGen: [],
            filtrosCobrar: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'porCobrar': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'cobrado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalNC': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalCobradoPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalNCPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
            },
            datosGenPagos: [],
            filtersPagos: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'porPagar': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'pagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalNC': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'totalFacturadoPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalPagadoPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'totalNCPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
            }
        }),
        mounted() {
            this.loading = false;
        },
        computed: {

            datosEmpresa() {
                return this.$store.state.usuario;
            },

            // PRIMEVUE
            totalPorC() {
                let totalPC = 0;
                for (let sumaPer of this.datosGen) {
                    totalPC += sumaPer.porCobrar;
                }

                return this.formatCurrency(totalPC);
            },

            totalC() {
                let totalC = 0;
                for (let sumaPer of this.datosGen) {
                    totalC += sumaPer.cobrado;
                }

                return this.formatCurrency(totalC);
            },

            totalFac() {
                let totalFac = 0;
                for (let sumaPer of this.datosGen) {
                    totalFac += sumaPer.totalFacturado;
                }

                return this.formatCurrency(totalFac);
            },

            totalNCo() {
                let totalNCo = 0;
                for (let sumaPer of this.datosGen) {
                    totalNCo += sumaPer.totalNC;
                }

                return this.formatCurrency(totalNCo);
            },

            totalFactP() {
                let totalFactP = 0;
                for (let sumaPer of this.datosGen) {
                    totalFactP += sumaPer.totalFacturadoPesos;
                }

                return this.formatCurrency(totalFactP);
            },

            totalPesosCo() {
                let totalPesosCo = 0;
                for (let sumaPer of this.datosGen) {
                    totalPesosCo += sumaPer.totalCobradoPesos;
                }

                return this.formatCurrency(totalPesosCo);
            },

            totalNCoP() {
                let totalNCoP = 0;
                for (let sumaPer of this.datosGen) {
                    totalNCoP += sumaPer.totalNCPesos;
                }

                return this.formatCurrency(totalNCoP);
            },

            //PAGOS
            totalXPagar() {

                let totalPP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalPP += sumaPer.porPagar;
                }

                return this.formatCurrency(totalPP);
            },

            totalPago() {
                let totalP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalP += sumaPer.pagado;
                }

                return this.formatCurrency(totalP);
            },

            totalFacXPagar() {
                let totalFacXP = 0;
                for (let sumaFXP of this.datosGenPagos) {
                    totalFacXP += sumaFXP.totalFacturado;
                }

                return this.formatCurrency(totalFacXP);
            },

            totalNCoPagado() {
                let totalNCoP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalNCoP += sumaPer.totalNC;
                }

                return this.formatCurrency(totalNCoP);
            },

            totalFactPagadaPesos() {
                let totalFactPP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalFactPP += sumaPer.totalFacturadoPesos;
                }

                return this.formatCurrency(totalFactPP);
            },

            totalPesosPag() {
                let totalPePag = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalPePag += sumaPer.totalPagadoPesos;
                }

                return this.formatCurrency(totalPePag);
            },

            totalNCoPesosPag() {
                let totalNCoPP = 0;
                for (let sumaPer of this.datosGenPagos) {
                    totalNCoPP += sumaPer.totalNCPesos;
                }

                return this.formatCurrency(totalNCoPP);
            },

        },
        created() {
            this.validarUsuario()
        },
        methods: {
            validarUsuario() {
                if (this.datosEmpresa.rol == 'CuentasCobrar') {
                    this.step = 1
                    return this.step
                }
                if (this.datosEmpresa.rol == 'CuentasPagar') {
                    this.step = 2
                    return this.step
                }
            },
            async ConsultarCuentasCobrar() {
                this.dialog = true;
                this.dialogMessage = 'Consultando cuentas por cobrar...'
                this.datosGen = []

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('CuentasPorCobrarPagar/GetCxC/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    console.log(response.data)
                    this.datosGen = response.data
                    
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            async ConsultarCuentasPagar() {
                this.datosGenPagos = []
                this.dialog = true;
                this.dialogMessage = 'Consultando cuentas por pagar...'

                let fechaInicial = this.SelectAño + '-' + this.SelectMes + '-01'
                try {
                    let response = await axios.get('CuentasPorCobrarPagar/GetCxP/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/');
                    console.log(response.data)
                    this.datosGenPagos = response.data
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },


            verDocumentosCobrar(item, value) {
                this.titulo = value
                this.dialogDocumentos = true

                this.$store.state.tituloDetalleDocumentosStore = ''
                this.$store.state.listaDetalleDocumentosStore = []

                this.$store.state.tituloDetalleNotasCreditoStore = ''
                this.$store.state.listaDetalleNotasCreditoStore = []

                this.$store.state.tituloDetalleDocumentosStore = 'ComplementosPago' + this.SelectMes + '_' + this.SelectAño
                this.$store.state.listaDetalleDocumentosStore = item.pagos

                this.$store.state.tituloDetalleNotasCreditoStore = 'NotasCredito' + this.SelectMes + '_' + this.SelectAño
                this.$store.state.listaDetalleNotasCreditoStore = item.notaCredito
            },

            verDocumentosPagar(item, value) {
                this.titulo = value
                this.dialogDocumentos = true

                this.$store.state.tituloDetalleDocumentosStore = ''
                this.$store.state.listaDetalleDocumentosStore = []

                this.$store.state.tituloDetalleNotasCreditoStore = ''
                this.$store.state.listaDetalleNotasCreditoStore = []

                this.$store.state.tituloDetalleDocumentosStore = 'ComplementosPago' + this.SelectMes + '_' + this.SelectAño
                this.$store.state.listaDetalleDocumentosStore = item.pagos

                this.$store.state.tituloDetalleNotasCreditoStore = 'NotasCredito' + this.SelectMes + '_' + this.SelectAño
                this.$store.state.listaDetalleNotasCreditoStore = item.notaCredito
            },

            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
           
            formatCurrency(value) {
                return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
            },

            clearFilterCobrar() {
                this.filtrosCobrar = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'porCobrar': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'cobrado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalNC': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalCobradoPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalNCPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                }
            },

            clearFilterPago() {
                this.filtersPagos = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'porPagar': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'pagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalNC': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                    'totalFacturadoPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalPagadoPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'totalNCPesos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                    'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                }

            },
            exportarCuentasCobrar() {
                this.$refs.CuentasCobrar.exportCSV();
            },
            exportarCuentasPagar() {
                this.$refs.CuentasPagar.exportCSV();
            },
        }
    }
</script>