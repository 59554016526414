<template>
<div>

        <DataTable :exportFilename="nombreArchivo" ref="documentos" removableSort filterDisplay="menu"
            class="p-datatable-sm p-datatable-customers" :value="itemsDocumentos" showGridlines :scrollable="true"
            scrollHeight="flex" :paginator="true" :rows="15"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[15,30,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :filters.sync="filtrosDocumentos"
            :globalFilterFields="['serie','folio','fechaPago','saldoInsoluto','saldoAnterior','importePagado','monedaP','tipoCambioP','formaDePago','version','folioFiscal']">
            <template #header>
                <div class="table-header">
                <h2>Complementos de Pago</h2>
                </div>
                <div class="flex justify-content-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filtrosDocumentos['global'].value" placeholder="Buscar..."
                            style="width: 500px;" />
                    </span>
                    <div style="text-align: left">
                        <Button @click="limpiarFiltrosDocumentos()" style="width: 200px;" type="button"
                            icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined" />
                        <Button @click="exportarDocumentos($event)" style="width: 200px;" icon="pi pi-external-link"
                            label="Exportar" />
                    </div>
                </div>
            </template>

            <Column sortable field="version" header="Versión" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.version}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="serie" header="Serie" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.serie}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>


            <Column sortable field="folio" header="Folio" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.folio}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>


            <Column sortable field="fechaPago" header="Fecha Pago" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'100px'}">
                <template #body="{data}">
                    {{formatDate(data.fechaPago)}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="saldoInsoluto" header="Saldo Insoluto" filterField="saldoInsoluto"
                dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.saldoInsoluto)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="saldoAnterior" header="Saldo Anterior" filterField="saldoAnterior"
                dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.saldoAnterior)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="importePagado" header="Importe Pagado" filterField="importePagado"
                dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.importePagado)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="monedaP" header="Moneda" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'120px'}">
                <template #body="{data}">
                    {{data.monedaP}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="tipoCambioP" header="Tipo de Cambio" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.tipoCambioP}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="formaPago" header="Forma de Pago" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.formaPago}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="folioFiscal" header="FolioFiscal" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.folioFiscal}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>
        </DataTable>

        <DataTable :exportFilename="nombreArchivoNC" ref="notasCredito" removableSort filterDisplay="menu"
            class="mt-5 p-datatable-sm p-datatable-customers" :value="itemsNotasCredito" showGridlines :scrollable="true"
            scrollHeight="flex" :paginator="true" :rows="15"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[15,30,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :filters.sync="filtrosNotasCredito"
            :globalFilterFields="['serie','folio','fecha','total','moneda','tipoCambio','formaDePago','tipoRelacion','folioFiscal']">
            <template #header>
                <div class="table-header">
                    <h2>Notas de Crédito</h2>
                </div>
                <div class="flex justify-content-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filtrosNotasCredito['global'].value" placeholder="Buscar..."
                            style="width: 500px;" />
                    </span>
                    <div style="text-align: left">
                        <Button @click="limpiarFiltrosNotasCredito()" style="width: 200px;" type="button"
                            icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined" />
                        <Button @click="exportarNotasCredito($event)" style="width: 200px;" icon="pi pi-external-link"
                            label="Exportar" />
                    </div>
                </div>
            </template>

            <Column sortable field="version" header="Versión" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.version}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="serie" header="Serie" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.serie}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>


            <Column sortable field="folio" header="Folio" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.folio}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>


            <Column sortable field="fecha" header="Fecha" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'100px'}">
                <template #body="{data}">
                    {{formatDate(data.fecha)}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="total" header="Total" filterField="total" dataType="numeric"
                :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.total)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="moneda" header="Moneda" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'120px'}">
                <template #body="{data}">
                    {{data.moneda}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="tipoCambio" header="Tipo de Cambio" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.tipoCambio}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="formaPago" header="Forma de Pago" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.formaPago}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="tipoRelacion" header="Tipo de Relación" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.tipoRelacion}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>

            <Column sortable field="folioFiscal" header="FolioFiscal" :bodyStyle="{'justify-content':'center'}"
                :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.folioFiscal}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                        placeholder="Search by name" />
                </template>
            </Column>
        </DataTable>
</div>

</template> 
<script>
    import moment from "moment";
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    export default {
        components: {
        },
        data: () => ({
            filtrosDocumentos: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'saldoInsoluto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'saldoAnterior': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'importePagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'monedaP': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoCambioP': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
            filtrosNotasCredito: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoCambio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoRelacion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            }
        }),
        computed: {
            nombreArchivo() {
                return this.$store.state.tituloDetalleDocumentosStore
            },

            nombreArchivoNC() {
                return this.$store.state.tituloDetalleNotasCreditoStore
            },

            itemsDocumentos() {
                return this.$store.state.listaDetalleDocumentosStore;
            },

            itemsNotasCredito() {
                return this.$store.state.listaDetalleNotasCreditoStore;
            },
        },
        methods: {
            exportarDocumentos() {
                this.$refs.documentos.exportCSV();
            },
            limpiarFiltrosDocumentos() {
                this.filtrosDocumentos = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'fechaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'saldoInsoluto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'saldoAnterior': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'importePagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'monedaP': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoCambioP': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                }
            },
            exportarNotasCredito() {
                this.$refs.notasCredito.exportCSV();
            },
            limpiarFiltrosNotasCredito() {
                this.filtrosNotasCredito = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoCambio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoRelacion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                }
            },
            formatCurrency(value) {
                return value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                });
            },
            formatDate(date) {
                return moment(date).format("yyyy-MM-DD");

            },
        },
    }
</script>