<template>
    <div class="app ma-0 pt-5">

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- DIALOGO DE INFORMACION -->
        <v-dialog v-model="dialogInformacion" width="650">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>INFORMACIÓN</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-alert icon="mdi-information-outline" prominent text type="info">
                        - En la columna dónde se encuentre el icono, correspondrá la situación en la que se encuentra el
                        RFC.
                        <br>
                        - Para consultar los RFC únicamente debes de dar click en el botón de
                        Consultar RFC.
                    </v-alert>
                    <v-row class=" mt-5">
                        <v-chip class="mr-2 mb-5" color="primary darken-4" dark>
                            <v-icon>mdi-close-circle-outline</v-icon>
                        </v-chip>
                        <h3>Listado de contribuyentes (Artículo 69-B del Código Fiscal de la Federación) </h3>
                    </v-row>
                    <br>
                    <v-row>
                        <v-chip class="mr-2 mb-5" color="amber darken-4" dark>
                            <v-icon>mdi-minus-circle-outline</v-icon>
                        </v-chip>
                        <h3>Listado de contribuyentes (No Localizados)</h3>
                    </v-row>
                    <br>
                    <v-row>
                        <v-chip class="mr-2 mb-5" color="blue-grey darken-1" dark>
                            <v-icon>mdi-cancel</v-icon>
                        </v-chip>
                        <h3>Listado de contribuyentes (Incumplidos)</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOGO PARA VER DETALLES FACTURADOS -->
        <Dialog :header="titulo" :visible.sync="dialogDetalle" :style="{width: '85vw'}" :maximizable="true"
            :modal="true" :contentStyle="{height: '550px'}">
            <DetallesValidarRFC></DetallesValidarRFC>
        </Dialog>

        <!-- CARD PARA VALIDAD -->
        <v-card elevation="4" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Validar RFC</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="4" class="mt-5 pb-10 pa-5 ma-5">
            <v-row class="justify-center">
                <v-col cols="12" md="6" sm="6">
                    <v-combobox v-model="select" label="Datos a consultar" :items="items" hide-selected small-chips>
                    </v-combobox>
                </v-col>

                <v-col class="text-right mt-1" cols="12" md="6" sm="6">
                    <v-row class="text-right">
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-3" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="consultarListaSat()">
                                    <v-icon>mdi-account-search</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar RFC</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-3" color="primary darken-1" dark fab @click="dialogInformacion = true"
                                    v-bind="attrs" v-on="on">
                                    <v-icon>mdi-help-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Ayuda</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>

            <DataTable exportFilename="ListaRFC" ref="validarRFC" removableSort filterDisplay="menu"
                class="p-datatable-sm p-datatable-customers" :value="itemsRFC" showGridlines :scrollable="true"
                scrollHeight="600px" :paginator="true" :rows="15"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                :filters.sync="filtros" :globalFilterFields="['rfc','nombre','lista69B','noLocalizado','tipo']">
                <template #header>
                    <div class="flex justify-content-between">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filtros['global'].value" placeholder="Buscar..."
                                style="width: 500px;" />
                        </span>
                        <div style="text-align: left">
                            <Button @click="limpiarFiltros()" style="width: 200px;" type="button"
                                icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined" />
                            <Button @click="exportCSV($event)" style="width: 200px;" icon="pi pi-external-link"
                                label="Exportar" />
                        </div>
                    </div>
                </template>

                <Column sortable field="rfc" header="RFC" :bodyStyle="{'justify-content':'center'}">
                    <template #body="{data}">
                        {{data.rfc}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search by name" />
                    </template>
                </Column>

                <Column sortable field="nombre" header="Nombre" :bodyStyle="{'justify-content':'left'}">
                    <template #body="{data}">
                        {{data.nombre}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search by name" />
                    </template>
                </Column>

                <Column sortable field="lista69B" header="Lista 69B" :bodyStyle="{'justify-content':'left'}">
                    <template #body="{data}">
                        <template v-if="data.lista69B != ''">
                            <Button icon="pi pi-times-circle" class="p-button-rounded p-button-danger" />
                        </template>
                    </template>
                </Column>

                <Column sortable field="noLocalizado" header="No Localizado" :bodyStyle="{'justify-content':'center'}">
                    <template #body="{data}">
                        <template v-if="data.noLocalizado == 'supuesto'">
                            <Button icon="pi pi-minus-circle" class="p-button-rounded p-button-warning"
                                @click="consultarComprobantesXrfc(data)" />
                        </template>
                    </template>
                </Column>

                <Column sortable field="tipo" header="Tipo" :bodyStyle="{'justify-content':'center'}">
                    <template #body="{data}">
                        {{data.tipo}}
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                            placeholder="Search by name" />
                    </template>
                </Column>

            </DataTable>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios'
    import { FilterMatchMode, FilterOperator } from 'primevue/api';
    import DetallesValidarRFC from './DialogosDetalles/DetallesValidarRFC.vue'
    export default {

        components: {
            DetallesValidarRFC,
        },
        data: () => ({
            filtros: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'lista69B': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'noLocalizado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },

            items: [
                'Emisor',
                'Receptor',
                'Todos',
            ],
            select: '',
            search: '',

            itemsRFC: [],
            dialogInformacion: false,

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            titulo: '',
            dialogDetalle: false,
        }
        ),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            exportCSV() {
                this.$refs.validarRFC.exportCSV();
            },
            async consultarListaSat() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                console.log(this.select)
                try {
                    let response = await axios.get('PeticionesSat/GetListasSat/' + this.datosEmpresa.empresaBase + '/' + this.select);
                    console.log(response.data)
                    this.itemsRFC = response.data
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async consultarComprobantesXrfc(item) {
                this.titulo = item.rfc + ' - ' + item.nombre
                this.$store.state.listaDetalleStore = []

                console.log(item)
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                try {
                    let response = await axios.get('PeticionesSat/GetComprobantesXrfc/' + this.datosEmpresa.empresaBase + '/' + item.rfc + '/' + item.tipo);
                    console.log(response.data)

                    this.$store.state.listaDetalleStore = response.data
                    this.$store.state.tituloDetalleStore = 'ListaComprobantes_' + item.rfc

                    this.dialogDetalle = true
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            getColorListaNegra(listaNegra) {
                if (!listaNegra || listaNegra != 'No encontrado') return 'red darken-4'
                else return 'white'
            },
            getColornoListado(noLocalizados) {
                if (!noLocalizados || noLocalizados != 'No encontrado') return 'amber darken-4'
                else return 'white'
            },
            getColorincumplido(incumplidos) {
                if (!incumplidos || incumplidos != '') return 'blue-grey darken-1'
                else return 'white'
            },

            limpiarFiltros() {
                this.filtros = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'lista69B': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'noLocalizado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                }
            },
        },
    };
</script>