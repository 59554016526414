<template>
    <div>
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <v-card>
            <v-toolbar>
                <v-btn icon color="primary darken-1" @click="cierraVentana()">
                    <v-icon large>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <h3>Subir Archivos</h3>
                <v-spacer></v-spacer>
                <!-- Boton para guardar factura -->
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary darken-1" v-bind="attrs" v-on="on" icon @click="guardarArchivos()">
                            <v-icon large>mdi-file-upload-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Subir</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <v-card elevation="0" class="ma-0 pa-0">
                    <v-card-text class="text-center ma-0 pa-0">
                        <v-icon class="ma-0 pa-0" size="150" color="rgb(255, 0, 0, 0.1)">mdi-folder-open</v-icon>
                    </v-card-text>
                </v-card>
                <v-file-input outlined dense truncate-length="50" label="Subir archivo xml" v-model="archivoXML"
                    accept=".xml, .json" @change="convirteaBase64()"> </v-file-input>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import moment from 'moment';
    import axios from 'axios';

    export default {
        components: {

        },
        data: () => ({
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
            //DIALOG
            dialog: false,
            dialogMessage: '',

            archivoXML: [],
            archivoBase64: '',
            listaArchivos: [],
            listArchivo: {
                archivoBase64: '',
                nombreArchivo: '',
            },
            ruta: ''
        }),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        methods: {
            cierraVentana() {
                this.$emit('close')
            },
            convirteaBase64() {
                this.listaArchivos = [];

                let file = this.archivoXML;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.listArchivo.archivoBase64 = reader.result;
                    this.listaArchivos.push(this.listArchivo);
                }
                reader.readAsDataURL(file);
                this.listArchivo.nombreArchivo = file.name
                console.log(this.listArchivo)
            },
            async guardarArchivos() {
                let archivo = this.listArchivo
                archivo.empresa = this.datosEmpresa.empresaBase
                this.$store.state.listaVentasXML = []
                this.dialog = true;
                this.dialogMessage = 'Subiendo archivo...'
                try {
                    let response = await axios.post('Gasolinas/SubirArchivos/' + this.datosEmpresa.empresaBase, archivo)
                    console.log(response.data);
                    this.$store.state.listaVentasXML = response.data.result
                    this.snackbarMessage = 'Archivo guardado'
                    this.snackbar = true
                    this.archivoXML = []

                    // let magna = this.$store.state.listaVentasXML.filter(x => x.producto == "Magna")
                    // let premium = this.$store.state.listaVentasXML.filter(x => x.producto == "Premium")
                    // let diesel = this.$store.state.listaVentasXML.filter(x => x.producto == "Diesel")

                    // let objeto = {
                    //     concepto: 'TOTAL:'
                    //     totalVentas: this.sum(this.$store.state.listaVentasXML, 'totalVenta'),
                    //     totalDiesel: this.sum(diesel, 'totalVenta'),
                    //     totalMagna: this.sum(magna, 'totalVenta'),
                    //     totalPremium: this.sum(premium, 'totalVenta'),
                    // }
                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = 'Error al subir'
                    this.snackbar = true
                }
                this.dialog = false;
                this.calcularTotales();
                this.cierraVentana();
            },
            calcularTotales() {
                this.$store.state.totalesReporteXML = []
                let magna = this.$store.state.listaVentasXML.filter(x => x.producto == "Magna")
                let premium = this.$store.state.listaVentasXML.filter(x => x.producto == "Premium")
                let diesel = this.$store.state.listaVentasXML.filter(x => x.producto == "Diesel")

                let objetoLitros = {
                    concepto: 'LITROS:',
                    totalVentasXML: this.sum(this.$store.state.listaVentasXML, 'valorNumerico'),
                    totalDiesel: this.sum(diesel, 'valorNumerico'),
                    totalMagna: this.sum(magna, 'valorNumerico'),
                    totalPremium: this.sum(premium, 'valorNumerico'),
                }
                this.$store.state.totalesReporteXML.push(objetoLitros)
                let objeto = {
                    concepto: 'TOTAL:',
                    totalVentasXML: this.sum(this.$store.state.listaVentasXML, 'totalVenta'),
                    totalDiesel: this.sum(diesel, 'totalVenta'),
                    totalMagna: this.sum(magna, 'totalVenta'),
                    totalPremium: this.sum(premium, 'totalVenta'),
                }
                this.$store.state.totalesReporteXML.push(objeto)
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
        }
    }
</script>