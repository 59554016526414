<template>
    <div class="app pt-5">
        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <!-- DIALOG PARA VER DETALLES -->
        <Dialog :header="nombreProveedor" :visible.sync="dialogDetalle" :style="{width: '85vw'}" :maximizable="true"
            :modal="true" :contentStyle="{height: '550px'}">
            <DetallesCompras></DetallesCompras>
        </Dialog>

        <!-- TITULO -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Compras/Gastos</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>

        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
            <v-row class="justify-center">

                <!-- SELECCION DE DATOS -->
                <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>

                <!-- BOTÓN PARA CONSULTAR -->
                <v-col cols="12" xl="6" md="6" sm="6" xs="6">
                    <v-spacer></v-spacer>
                    <v-row class="text-right">
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="buscarCompras()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar compras</span>
                        </v-tooltip>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <!-- TABLA -->
        <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
            <DataTable exportFilename="ComprasGastos" ref="ComprasGastos" :value="itemsGeneral"
                        :paginator="true" class="p-datatable-sm p-datatable-datosGen" showGridlines :rows="20"
                        :filters.sync="filtrosComprasGastos" filterDisplay="menu" responsiveLayout="scroll"
                        removableSort :scrollable="true" scrollHeight="500px"
                        :globalFilterFields="['rfc','nombre','fecha','subTotal','descuento','total','numCompras']"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                        <template #header>
                            <v-row>
                                <v-col cols="12" xl="6" md="6" sm="6" xs="6">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosComprasGastos['global'].value"
                                        placeholder="Buscar..." style="width: 500px" />
                                </span>
                            </v-col>
                           <v-col class="text-right" cols="12" xl="6" md="6" sm="6" xs="6">
                                    <Button @click="clearFilterComprasGastos()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarComprasGastos($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                    </v-col>
                                </v-row>
                        </template>
                        <template #empty>
                            No se encontraron datos.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>

                        <Column :bodyStyle="{'justify-content':'center'}" field="rfc" header="RFC" :sortable="true"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por RFC" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'left'}" field="nombre" header="Nombre" :sortable="true"
                            :styles="{'min-width':'700px'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="subTotal" header="Subtotal"
                            filterField="subTotal" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.subTotal)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="descuento" header="Descuento"
                            filterField="descuento" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.descuento)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="total" header="Total"
                            filterField="total" :sortable="true" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.total)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>

                        <Column :bodyStyle="{'justify-content':'right'}" field="numCompras" header="No. Compras" :sortable="true"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.numCompras}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por nombre" />
                            </template>
                        </Column>
                        <Column header="Acción" :headerStyle="{'text-align': 'center'}"
                            :bodyStyle="{'justify-content': 'center', overflow: 'visible'}"
                            :styles="{'min-width':'100px'}">
                            <template #body="{data}">
                                <Button @click="detalles(data,'Compras/Gastos')" type="button"
                                    class="p-button-sm" icon="pi pi-bars"></Button>
                            </template>
                        </Column>
                    </DataTable>
        </v-card>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import Vue from 'vue'
    import { FilterMatchMode, FilterOperator } from 'primevue/api/';
    import DetallesCompras from './DialogosDetalles/DetallesCompras.vue'



    export default {
        name: 'Home',
        components: {
            HelloWorld,
            DetallesCompras
        },
        data: () => ({
            
            nombreReporte: '',

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            //DIALOG
            dialog: false,
            dialogMessage: '',
            nombreProveedor: '',
            
            itemsGeneral: [],

            
            itemsComprobantes: [],
            dialogDetalle: false,

            
            itemsConceptos: [],
            dialogConceptos: false,

            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            filtrosComprasGastos: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'descuento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'numCompras': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
            },
        }),
        computed: {
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
        },
        created() { },
        methods: {
            async buscarCompras() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...';
                try {
                    let response = await axios.get('Gastos/GetGastosCompras/' + this.datosEmpresa.empresaBase + '/' + this.dateBuscaI + '/' + this.dateBuscaF + '/' + this.datosEmpresa.rfc);
                    this.itemsGeneral = response.data
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            detalles(item, value) {
                this.nombreProveedor = item.rfc + ' | ' + item.nombre
                this.dialogDetalle = true
                this.$store.state.listaDetalleStore = []
                this.$store.state.listaDetalleStore = item.comprobantes
                this.$store.state.tituloDetalleStore ='Compras/Gastos_' +  item.rfc
            },

            clearFilterComprasGastos(){
                this.filtrosComprasGastos={
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'descuento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS },] },
                'numCompras': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH },] },
            }
            },

            exportarComprasGastos() {
                this.$refs.ComprasGastos.exportCSV();
            },

            formatDate(date) {
                return moment(date).format("yyyy-MM-DD");
            },

            formatCurrency(value) {
                return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
            }
        },
    }
</script>