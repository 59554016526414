var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app pt-5"},[_c('v-snackbar',{attrs:{"timeout":10000,"color":"primary darken-2","elevation":"30"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" mdi-close-circle ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage)+" ")]),_c('div',[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1),_c('v-card',{staticClass:"justify-center mb-5 mr-5 ml-5",attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Ingresos Nómina")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pt-10 pb-10 pa-5 ma-5",attrs:{"elevation":"6"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","xl":"3","md":"3","sm":"12","xs":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsI,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaI = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaI),callback:function ($$v) {_vm.menuDateBuscaI=$$v},expression:"menuDateBuscaI"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaI = false}},model:{value:(_vm.dateBuscaI),callback:function ($$v) {_vm.dateBuscaI=$$v},expression:"dateBuscaI"}})],1)],1),_c('v-col',{attrs:{"cols":"10","xl":"3","md":"3","sm":"12","xs":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsF,"label":"Fecha","readonly":"","locale":"es"},on:{"click:clear":function($event){_vm.dateBuscaF = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateBuscaF),callback:function ($$v) {_vm.menuDateBuscaF=$$v},expression:"menuDateBuscaF"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaF = false}},model:{value:(_vm.dateBuscaF),callback:function ($$v) {_vm.dateBuscaF=$$v},expression:"dateBuscaF"}})],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"6","md":"6","sm":"3","xs":"3"}},[_c('v-row',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 mt-2 mb-4",attrs:{"color":"primary darken-1","dark":"","fab":""},on:{"click":function($event){return _vm.ConsultarIngresosNomina()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Consultar compras")])]),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":_vm.collection,"sheet-name":sheet.name}})}),_c('xlsx-download'),_c('xlsx-download',{attrs:{"filename":_vm.nombreReporte}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 mt-2",attrs:{"color":"primary darken-1","dark":"","fab":""},on:{"click":function($event){return _vm.GeneraExcelIngresosNomina()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-table-outline")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)],2)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-5 pb-10 pa-5 ma-5",attrs:{"elevation":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersIngresosNomina,"items":_vm.itemsIngresosNomina,"search":_vm.search}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }