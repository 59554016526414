var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"justify-center mb-5 mr-5 ml-5 mt-5",attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"dark justify-center"},[_c('h2',{staticClass:"justify-center"},[_vm._v("Reportes por Fechas")])]),_c('v-system-bar',{attrs:{"color":"primary","height":"6"}})],1),_c('v-card',{staticClass:"mt-5 pb-10 ma-5 pa-5 mt-10",attrs:{"elevation":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"label":"RFC del emisor","return-object":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"label":"RFC del receptor","return-object":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.radioFechas),callback:function ($$v) {_vm.radioFechas=$$v},expression:"radioFechas"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-radio',{attrs:{"label":"Rango de fechas","value":"fechas"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-radio',{attrs:{"label":"Año","value":"año"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[(_vm.radioFechas =='fechas')?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsI,"label":"Fecha inicial","readonly":"","locale":"es"}},'v-text-field',attrs,false),on))]}}],null,false,3929836636),model:{value:(_vm.menuDateBuscaI),callback:function ($$v) {_vm.menuDateBuscaI=$$v},expression:"menuDateBuscaI"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaI = false}},model:{value:(_vm.dateBuscaI),callback:function ($$v) {_vm.dateBuscaI=$$v},expression:"dateBuscaI"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjsF,"label":"Fecha final","readonly":"","locale":"es"}},'v-text-field',attrs,false),on))]}}],null,false,4031348534),model:{value:(_vm.menuDateBuscaF),callback:function ($$v) {_vm.menuDateBuscaF=$$v},expression:"menuDateBuscaF"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"change":function($event){_vm.menuDateBuscaF = false}},model:{value:(_vm.dateBuscaF),callback:function ($$v) {_vm.dateBuscaF=$$v},expression:"dateBuscaF"}})],1)],1)],1)]:_vm._e(),(_vm.radioFechas =='año')?[_c('v-col',{attrs:{"cols":"12","xl":"12","md":"12","sm":"12","xs":"12"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-calendar","label":"AÑO","items":_vm.itemsAnios},model:{value:(_vm.selectAnios),callback:function ($$v) {_vm.selectAnios=$$v},expression:"selectAnios"}})],1)]:_vm._e()],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"6","md":"6","sm":"12","xs":"12"}},[_c('v-select',{attrs:{"label":"Tipo de Reporte","items":_vm.itemsTipo},model:{value:(_vm.selectTipo),callback:function ($$v) {_vm.selectTipo=$$v},expression:"selectTipo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-select',{attrs:{"label":"Reporte","return-object":""}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"2"}},[_c('div',{staticClass:"mt-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 mr-1 ml-0",attrs:{"fab":"","dark":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-autorenew ")])],1)]}}])},[_c('span',[_vm._v("Consultar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2  mr-1 ml-6",attrs:{"fab":"","dark":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-down-circle ")])],1)]}}])},[_c('span',[_vm._v("Generar Excel")])])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"5","md":"5","sm":"12","xs":"12"}},[_c('v-select',{attrs:{"label":"Columnas a mostrar","multiple":"","return-object":""}})],1),_c('v-col',{attrs:{"cols":"12","xl":"1","md":"1","sm":"12","xs":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"justify-center mx-2",attrs:{"fab":"","dark":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-autorenew ")])],1)]}}])},[_c('span',[_vm._v("Aplicar")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"filtrar"},model:{value:(_vm.searchReportes),callback:function ($$v) {_vm.searchReportes=$$v},expression:"searchReportes"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headersTableReporte,"items":_vm.itemsTableReporte,"loading-text":"Generando reporte","loading":_vm.loadingReporte,"search":_vm.searchReportes}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }