<template>
    <div>
        <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                {{ dialogMessage }}
                <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOGO PARA VER DETALLES RETENIDO ISR -->
    <Dialog :header="titulo" :visible.sync="dialogDetalleISR" :style="{width: '85vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '550px'}">
      <DetallesRetenidoISR></DetallesRetenidoISR>
    </Dialog>

    <!-- DIALOGO PARA VER DETALLES RETENIDO NOMINA -->
    <Dialog :header="titulo" :visible.sync="dialogDetalleNomina" :style="{width: '85vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '550px'}">
      <DetallesRetenidoNomina></DetallesRetenidoNomina>
    </Dialog>

        <!-- DIALOG COMPARATIVA -->
        <v-dialog v-model="dialogComparativa" persistent max-width="500px">
            <v-card>
            <v-toolbar>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small @click="dialogComparativa = false" v-bind="attrs" v-on="on">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-toolbar-title>Importes Registrados {{ fechaHome.anio }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostComparativa()">
                    <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Guardar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <br>
            <v-card-text>
                    <v-data-table :headers="headersComparativa" :items="itemsComparativa" :items-per-page="-1" hide-default-footer>
                    <!-- PARA EDITAR -->
                    <template v-slot:item.importe="props">
                        <v-edit-dialog
                        :return-value.sync="props.item.importe"
                        >
                        {{ props.item.importe }}
                        <template v-slot:input>
                            <v-text-field
                            v-model="props.item.importe"
                            label="Editar"
                            single-line
                            counter
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template>
                    </v-data-table>
            </v-card-text>
            </v-card>
        </v-dialog>

        <!-- REPORTE -->
        <v-card width="1400" class=" mx-auto pb-10 ma-5" color="transparent">
            <v-card elevation="2" class="justify-center">
                <v-card-title class="dark justify-center">
                    <h3 class="justify-center">ISR RETENIDO</h3>
                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
            </v-card>

            <!-- SUELDOS -->
            <v-card elevation="3" class="mt-5 pb-10 ">
                <div class="text-center">
                    <h2>SUELDOS</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES DEL EXPANSION PANEL -->
                <v-row justify="space-around">
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                            @click="GeneraExcelSueldos()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                    @click="openDialog('Sueldos')" outlined>
                                    <v-icon>mdi-menu</v-icon>
                                    COMPARATIVA
                                </v-chip>
                            </template>
                            <span>COMPARATIVA DE SUELDOS</span>
                        </v-tooltip>
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="Consulta()">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                            </template>
                            <span>Actualizar</span>
                        </v-tooltip>
                </v-row>
                <br>
                <v-data-table :item-class="row_classes"  :headers="headerSueldos" :items="itemsSueldos" class="elevation -1"
                    hide-default-footer :items-per-page="-1">
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary"
                                            @click="detallesNomina(item, 'ISR Sueldos')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                        <span>{{ functionFormatPrice(item.importe) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- ASIMILADOS -->
            <v-card elevation="3" class="mt-5 pb-10 mt-10">
                <div class="text-center">
                    <h2>ASIMILADOS</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES-->
                <v-row justify="space-around">
                    <!-- <div> -->
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name"/>
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                            @click="GeneraExcelAsimilados()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                    @click="openDialog('Asimilados')" outlined>
                                    <v-icon>mdi-menu</v-icon>
                                    COMPARATIVA
                                </v-chip>
                            </template>
                            <span>COMPARATIVA DE ASIMILADOS</span>
                        </v-tooltip>
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                            </template>
                            <span>Actualizar</span>
                        </v-tooltip>
                    <!-- </div> -->
                </v-row>
                <br>
                <v-data-table :item-class="row_classes"  :headers="headerAsimilados" :items="itemsAsimilados"
                    class="elevation -1" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary"
                                            @click="detallesNomina(item, 'ISR Asimilados')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                        <span>{{ functionFormatPrice(item.importe) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- SUELDOS OTROS -->
            <v-card elevation="3" class="mt-5 pb-10 mt-10">
                <div class="text-center">
                    <h2>SUELDOS (OTROS)</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES -->
                <v-row justify="space-around">
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelOtrosSueldos()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                @click="openDialog('Sueldos Otros')" outlined>
                                <v-icon>mdi-menu</v-icon>
                                COMPARATIVA
                            </v-chip>
                        </template>
                        <span>COMPARATIVA DE SUELDOS (OTROS)</span>
                    </v-tooltip>
                    <!-- BOTÓN PARA CONSULTAR -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on">
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                        </template>
                        <span>Actualizar</span>
                    </v-tooltip>
                </v-row>
                <br>
                <v-data-table :item-class="row_classes"  :headers="headerSueldosOtros" :items="itemsSueldosOtros"
                    class="elevation -1" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary"
                                            @click="detallesNomina(item, 'ISR Sueldos Otros')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                        <span>{{ functionFormatPrice(item.importe) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- HONORARIOS -->
            <v-card elevation="3" class="mt-5 pb-10 mt-10">
                <div class="text-center">
                    <h2>HONORARIOS</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES DEL EXPANSION PANEL -->
                <v-row justify="space-around">
                    <!-- <div> -->
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                            @click="GeneraExcelHonorarios()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                    @click="openDialog('Honorarios')" outlined>
                                    <v-icon>mdi-menu</v-icon>
                                    COMPARATIVA
                                </v-chip>
                            </template>
                            <span>COMPARATIVA DE HONORARIOS</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="Consulta()">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                            </template>
                            <span>Actualizar</span>
                        </v-tooltip>
                    <!-- </div> -->
                </v-row>
                <br>
                <v-data-table :item-class="row_classes"  :headers="headerHonorarios" :items="this.itemsHonorarios"
                    class="elevation -1" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary"
                                            @click="detallesISR(item, 'ISR Honorarios')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                        <span>{{ functionFormatPrice(item.importe) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- ARRENDAMIENTOS -->
            <v-card elevation="3" class="mt-5 pb-10 mt-10">
                <div class="text-center">
                    <h2>ARRENDAMIENTOS</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES DEL EXPANSION PANEL -->
                <v-row justify="space-around">
                    <!-- <div> -->
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                            @click="GeneraExcelArrendamientos()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                    @click="openDialog('Arrendamientos')" outlined>
                                    <v-icon>mdi-menu</v-icon>
                                    COMPARATIVA
                                </v-chip>
                            </template>
                            <span>COMPARATIVA DE ARRENDAMIENTOS</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="Consulta()">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                            </template>
                            <span>Actualizar</span>
                        </v-tooltip>
                    <!-- </div> -->
                </v-row>
                <br>
                <v-data-table  :item-class="row_classes"  :headers="headerArrendamientos" :items="this.itemsArrendamientos"
                    class="elevation -1" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary"
                                            @click="detallesISR(item, 'ISR Arrendamientos')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                        <span>{{ functionFormatPrice(item.importe) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- FLETES -->
            <v-card elevation="3" class="mt-5 pb-10 mt-10">
                <div class="text-center">
                    <h2>FLETES</h2>
                </div>
                <br>
                <!-- BOTONES CENTRALES DEL EXPANSION PANEL -->
                <v-row justify="space-around">
                    <!-- <div> -->
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                            @click="GeneraExcelFletes()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                    @click="openDialog('Fletes')" outlined>
                                    <v-icon>mdi-menu</v-icon>
                                    COMPARATIVA
                                </v-chip>
                            </template>
                            <span>COMPARATIVA DE FLETES</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="Consulta()">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                            </template>
                            <span>Actualizar</span>
                        </v-tooltip>
                    <!-- </div> -->
                </v-row>
                <br>
                <v-data-table  :item-class="row_classes"  :headers="headerFletes" :items="this.itemsFletes"
                    class="elevation -1" :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary"
                                            @click="detallesISR(item, 'ISR Arrendamientos')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                        <span>{{ functionFormatPrice(item.importe) }}</span>
                    </template>
                    <template v-slot:[`item.importeRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeRegistrado) }}</span>
                    </template>
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                </v-data-table>
            </v-card>

        </v-card>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import DetallesRetenidoISR from './DetallesRetenidoISR.vue'
    import DetallesRetenidoNomina from './DetallesRetenidoNomina.vue'


    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            DetallesRetenidoISR,
            DetallesRetenidoNomina,

            HelloWorld,
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        data: () => ({
            dialogDetalleNomina: false,
            dialogDetalleISR: false,

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            titulo: '',

            //PANEL ISR RETENIDO 
            headerSueldos: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importe', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsSueldos: [],
            headerAsimilados: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importe', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsAsimilados: [],
            headerSueldosOtros: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importe', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsSueldosOtros: [],
            
            //HONORARIOS Y ARRENDAMIENTOS
            headerHonorarios: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importe', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsHonorarios: [],
            headerArrendamientos: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importe', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsArrendamientos: [],
            headerFletes: [
                { text: 'Mes', value: 'mes', sortable: false },
                { text: 'Importe', value: 'importe', sortable: false, align: 'right' },
                { text: 'Importe Registrado', value: 'importeRegistrado', sortable: false, align: 'right' },
                { text: 'Comparativa', value: 'comparativa', sortable: false, align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            itemsFletes:[],
            //DIALOG
            dialog: false,
            dialogMessage: '',

            //COMPARATIVAS
            dialogComparativa: false,
            headersComparativa: [
                { text: 'Mes', value: 'mes' },
                { text: 'Importe', value: 'importe' },
            ],
            itemsComparativa:[],
            itemsComparativaSueldos: [
                { anio: '', mes: 'enero', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'SUELDOS', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaAsimilados: [
                { anio: '', mes: 'enero', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'ASIMILADOS', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaOtros: [
                { anio: '', mes: 'enero', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'SUELDOS_O', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaHonorarios: [
                { anio: '', mes: 'enero', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'HONORARIOS', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaArrendamientos: [
                { anio: '', mes: 'enero', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'ARRENDAMIENTOS', importe: 0, cargo: 0, abono: 0 },
            ],
            itemsComparativaFletes: [
                { anio: '', mes: 'enero', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'FLETES', importe: 0, cargo: 0, abono: 0 },
            ],
        }),
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            fechaHome(){
                return this.$store.state.fechaHome;
            },
        },
        methods: {
            //REPORTES
            async Consulta(){
                await this.ConsultaNomina();
                await this.ConsultaHonorariosArrendamientos();
            },

            async ConsultaNomina() {
                //CONSULTAMOS LOS IMPORTES REGISTRADOS
                try{
                    this.dialog = true;
                    this.dialogMessage = 'Consultando nóminas...'

                    this.itemsSueldos = []
                    this.itemsSueldosOtros = []
                    this.itemsAsimilados = []

                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Nomina/GetRetencionIsr/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    console.log(response.data)
                    this.itemsSueldos = response.data.sueldos
                    this.itemsSueldosOtros = response.data.otros
                    this.itemsAsimilados = response.data.asimilados

                    //OBTENEMOS LA COMPARATIVA
                    await this.GetComparativaSueldos()
                    await this.GetComparativaAsimilados()
                    await this.GetComparativaOtros()

                    let sueldos = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsSueldos, 'comparativa'),
                        importeRegistrado: this.sum(this.itemsSueldos, 'importeRegistrado'),
                        importe: this.sum(this.itemsSueldos, 'importe'),
                    }
                    let sueldosOtros = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsSueldosOtros, 'comparativa'),
                        importeRegistrado: this.sum(this.itemsSueldosOtros, 'importeRegistrado'),
                        importe: this.sum(this.itemsSueldosOtros, 'importe'),
                    }
                    let asimilados = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsAsimilados, 'comparativa'),
                        importeRegistrado: this.sum(this.itemsAsimilados, 'importeRegistrado'),
                        importe: this.sum(this.itemsAsimilados, 'importe'),
                    }
                    this.itemsSueldos.push(sueldos);
                    this.itemsSueldosOtros.push(sueldosOtros);
                    this.itemsAsimilados.push(asimilados);
                }catch(error){
                    console.log(error)
                }
            },

            async ConsultaHonorariosArrendamientos(){
                try{
                    this.dialog = true;
                    this.dialogMessage = 'Consultando Honorarios, Arrendamientos y Fletes...'
                    this.itemsHonorarios = [];
                    this.itemsArrendamientos = [];
                    this.itemsFletes = [];

                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Gastos/GetHonorariosArrendamientos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    console.log(response.data)
                    this.itemsHonorarios = response.data.honorarios
                    this.itemsArrendamientos = response.data.arrendamiento
                    this.itemsFletes = response.data.fletes

                    //OBTENEMOS LAS COMPARATIVAS
                    await this.GetComparativaHonorarios()
                    await this.GetComparativaArrendamientos()
                    await this.GetComparativaFletes()

                    //CALCULAR SUMATORIA ISR RETENIDO (HONORARIOS Y ARRENDAMIENTOS)
                    let honora = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsHonorarios, 'comparativa'),
                        importeRegistrado: this.sum(this.itemsHonorarios, 'importeRegistrado'),
                        importe: this.sum(this.itemsHonorarios, 'importe'),
                    }

                    let arrend = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsArrendamientos, 'comparativa'),
                        importeRegistrado: this.sum(this.itemsArrendamientos, 'importeRegistrado'),
                        importe: this.sum(this.itemsArrendamientos, 'importe'),
                    }

                    let flet = {
                        mes: 'TOTAL:',
                        comparativa: this.sum(this.itemsFletes, 'comparativa'),
                        importeRegistrado: this.sum(this.itemsFletes, 'importeRegistrado'),
                        importe: this.sum(this.itemsFletes, 'importe'),
                    }

                    this.itemsHonorarios.push(honora);
                    this.itemsArrendamientos.push(arrend);
                    this.itemsFletes.push(flet);
                    this.dialog = false;
                }catch(error){
                    this.dialog = false;
                    console.log(error)
                }
            },

            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            
            detallesISR(item, value){
                this.titulo = value + ' ' + item.mes
                this.dialogDetalleISR = true

                this.$store.state.tituloDetalleStore = ''
                this.$store.state.listaDetalleStore = []

                this.$store.state.tituloDetalleStore = value + '_' + item.mes + '_' + this.fechaHome.anio
                this.$store.state.listaDetalleStore = item.detalles
            },
            detallesNomina(item, value){
                this.titulo = value + ' ' + item.mes
                this.dialogDetalleNomina = true

                this.$store.state.tituloDetalleStore = ''
                this.$store.state.listaDetalleStore = []

                this.$store.state.tituloDetalleStore = value + '_' + item.mes + '_' + this.fechaHome.anio
                this.$store.state.listaDetalleStore = item.detalles
            },
            GeneraExcelSueldos() {
                this.nombreReporte = 'Sueldos_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = this.itemsSueldos;
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelAsimilados() {
                this.nombreReporte = 'Asimilados_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = this.itemsAsimilados;
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelOtrosSueldos() {
                this.nombreReporte = 'OtrosSueldos_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = this.itemsSueldosOtros;
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelHonorarios() {
                this.nombreReporte = 'Honorarios_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = this.itemsHonorarios;
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelArrendamientos() {
                this.nombreReporte = 'Arrendamientos_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = this.itemsArrendamientos;
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelFletes() {
                this.nombreReporte = 'Fletes_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = this.itemsFletes;
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            row_classes(item) {
                if (item.mes == "TOTAL:") {
                    return "primary lighten-5"; 
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
        
            //COMPARATIVA
            async openDialog(item){
                if(item === 'Sueldos'){
                    await this.GetComparativaSueldos();
                    this.itemsComparativa =  this.itemsComparativaSueldos;
                    this.dialogComparativa = true;
                }
                if(item === 'Asimilados'){
                    await this.GetComparativaAsimilados();
                    this.itemsComparativa =  this.itemsComparativaAsimilados;
                    this.dialogComparativa = true;
                }
                if(item === 'Sueldos Otros'){
                    await this.GetComparativaOtros();
                    this.itemsComparativa =  this.itemsComparativaOtros;
                    this.dialogComparativa = true;
                }
                if(item === 'Honorarios'){
                    await this.GetComparativaHonorarios();
                    this.itemsComparativa =  this.itemsComparativaHonorarios;
                    this.dialogComparativa = true;
                }
                if(item === 'Arrendamientos'){
                    await this.GetComparativaArrendamientos();
                    this.itemsComparativa =  this.itemsComparativaArrendamientos;
                    this.dialogComparativa = true;
                }
                if(item === 'Fletes'){
                    await this.GetComparativaFletes();
                    this.itemsComparativa =  this.itemsComparativaFletes;
                    this.dialogComparativa = true;
                }
            },
            async GetComparativaSueldos(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/SUELDOS/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaSueldos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                            registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsSueldos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importe - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaAsimilados(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/ASIMILADOS/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaAsimilados){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }
                    
                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsAsimilados){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importe - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaOtros(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/SUELDOS_O/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaOtros){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsSueldosOtros){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importe - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaHonorarios(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/HONORARIOS/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaHonorarios){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsHonorarios){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importe - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaArrendamientos(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/ARRENDAMIENTOS/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaArrendamientos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsArrendamientos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importe - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async GetComparativaFletes(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/FLETES/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativaFletes){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let registrado = 0;
                        if(indice != -1){
                        registrado = itemsComparativaBd[indice].importe
                        }
                        item.anio = this.fechaHome.anio
                        item.importe = registrado;
                    }

                    //RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsArrendamientos){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        if(indice != -1){
                            item.importeRegistrado = itemsComparativaBd[indice].importe
                            item.comparativa = item.importe - item.importeRegistrado;
                        }
                    }
                }catch(error){
                    console.log(error)
                }
            },
            async PostComparativa(){
                try{
                    let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsComparativa)
                    this.dialogComparativa = false
                }catch(error){
                    console.log(error)
                }
            },
        
        },


    };
</script>