<template>
    <div>
        <v-card elevation="2" class="justify-center mb-5 mr-5 ml-5 mt-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Reportes por Fechas</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <v-card elevation="3" class="mt-5 pb-10 ma-5 pa-5 mt-10">
            <v-row>
                <!-- SELECT CON LOS RFC -->
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete label="RFC del emisor" return-object></v-autocomplete>
                </v-col>
                <!-- SELECT CON LOS RFC -->
                <v-col cols="12" sm="6" md="6">
                    <v-autocomplete label="RFC del receptor" return-object></v-autocomplete>
                </v-col>
            </v-row>
            <!-- RANGO DE FECHAS -->
            <v-row>
                <!-- SELECT CON LOS RFC -->
                <v-col cols="12" sm="6" md="6">
                    <v-radio-group v-model="radioFechas" row>
                        <v-col cols="12" sm="6" md="6">
                            <v-radio label="Rango de fechas" value="fechas"></v-radio>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-radio label="Año" value="año"></v-radio>
                        </v-col>
                    </v-radio-group>
                </v-col>
                <!-- SELECT CON LOS RFC -->
                <v-col cols="12" sm="6" md="6">
                    <template v-if="radioFechas =='fechas'">
                        <!-- FECHA INICIAL -->
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI" label="Fecha inicial"
                                            readonly v-bind="attrs" v-on="on" locale="es"></v-text-field>
                                    </template>
                                    <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!-- FECHA FINAL -->
                            <v-col cols="12" sm="6" md="6">
                                <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF" label="Fecha final"
                                            readonly v-bind="attrs" v-on="on" locale="es"></v-text-field>
                                    </template>
                                    <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="radioFechas =='año'">
                        <v-col cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-select prepend-icon="mdi-calendar" label="AÑO" :items="itemsAnios" v-model="selectAnios"></v-select>
                        </v-col>
                    </template>
                </v-col>
            </v-row>
            <!-- TIPOS DE REPORTES -->
            <v-row>
                <!-- SELECT CON LOS TIPOS DE REPORTES -->
                <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                    <v-select label="Tipo de Reporte" :items="itemsTipo" v-model="selectTipo"></v-select>
                </v-col>
                <!-- SELECCIONA EL REPORTE A GENERAR -->
                <v-col cols="12" md="4" sm="4">
                    <v-select label="Reporte" return-object></v-select>
                </v-col>
                <v-col class="text-center" cols="12" sm="2">
                    <div class="mt-1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2 mr-1 ml-0" fab dark color="primary" v-bind="attrs" v-on="on">
                                    <v-icon dark>
                                        mdi-autorenew
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2  mr-1 ml-6" fab dark color="primary" v-bind="attrs" v-on="on">
                                    <v-icon dark>
                                        mdi-arrow-down-circle
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Generar Excel</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
            <!-- MOSTRAR COLUMNAS -->
            <v-row>
                <v-col cols="12" xl="5" md="5" sm="12" xs="12">
                    <v-select label="Columnas a mostrar" multiple return-object> </v-select>
                </v-col>
                <v-col cols="12" xl="1" md="1" sm="12" xs="12">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="justify-center mx-2" fab dark color="primary" v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-autorenew
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Aplicar</span>
                    </v-tooltip>
                </v-col>

            </v-row>
            <!-- TABLA -->
            <v-row>
                <v-col cols="12">
                    <v-text-field label="filtrar" v-model="searchReportes"></v-text-field>
                </v-col>
            </v-row>
            <v-data-table :headers="headersTableReporte" :items="itemsTableReporte" loading-text="Generando reporte"
                :loading="loadingReporte" :search="searchReportes">
            </v-data-table>
        </v-card>

    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';

    export default {
        data: () => ({
            radioFechas: null,

            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,
            itemsAnios: ['2016', '2017', '2018', '2019', '2020', '2021',],
            selectAnios: '',

            itemsTipo: ['General', 'Nomina', 'Impuestos', 'Complementos de Pago', 'Ingresos', 'Egresos'],
            selectTipo: '',
            //TABLA
            searchReportes: '',
            itemsTableReporte: [],
            headersTableReporte: [],
            loadingReporte: false,

        }),
        computed: {
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
datosEmpresa() {
                return this.$store.state.usuario;
            },
        },

        created() {

        },

        methods: {

        },
    }
</script>