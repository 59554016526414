<template>
  <div>
    <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
    <v-dialog v-model="dialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ dialogMessage }}
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- DIALOGO PARA VER DETALLES FACTURADOS -->
    <Dialog :header="titulo" :visible.sync="dialogDetallesFacturados" :style="{width: '85vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '550px'}">
      <DetallesFacturado></DetallesFacturado>
    </Dialog>

    <!-- DIALOGO PRA VER DETALLES COBRADOS -->
    <Dialog :header="titulo" :visible.sync="dialogDetallesCobrados" :style="{width: '85vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '550px'}">
      <DetallesCobrados></DetallesCobrados>
    </Dialog>

    <!-- DIALOGO PARA VER DETALLES DE IVA -->
    <Dialog :header="titulo" :visible.sync="dialogDetallesIva" :style="{width: '85vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '550px'}">
      <DetallesIva></DetallesIva>
    </Dialog>

    <!-- REPORTE -->
    <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5" color="transparent">
      <v-card elevation="3" class="justify-center">
        <v-card-title class="dark justify-center">
          <h3 class="justify-center">GENERAL</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs" v-on="on"
                @click="GetReporte()">
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
        </v-card-title>
        <v-system-bar color="primary" height="6"></v-system-bar>
        <v-card-text>
          <!-- FACTURADOS -->
          <v-row pt-3 dense class="justify-center pt-4">
            <!-- INGRESOS FACTURADOS -->
            <v-col cols="12" md="3" sm="12" dense>
              <v-text-field prefix="$" label="Total de Ingresos Facturados" v-model="ingresosFacturadosImporte"
                readonly outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesFacturados('Total de Ingresos Facturados',ingresosFacturados.detalles)">
              </v-text-field>
            </v-col>
            <!-- GASTOS FACTURADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de Gastos Facturados" v-model="gastosFacturadosImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesFacturados('Total de Gastos Facturados', gastosFacturados.detalles)">
              </v-text-field>

            </v-col>
            <!-- RESULTADO INGRESOS MESNO GASTOS FACTURADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalFacturados" readonly outlined></v-text-field>

            </v-col>
          </v-row>
          <!-- ANTICIPOS -->
          <v-row pt-3 dense class="justify-center">
            <!-- ANTICIPOS EMITIDOS -->
            <v-col cols="12" md="3" sm="12" dense>
              <v-text-field prefix="$" label="Notas de Crédito de Anticipo" v-model="ingresosNcAnticiposImporte"
                readonly outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesFacturados('Notas de Crédito de Anticipo',ingresosNcAnticipos.detalles)">
              </v-text-field>
            </v-col>
            <!-- ANTICIPOS RECIBIDOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Notas de Crédito de Anticipo" v-model="gastosNcAnticiposImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesFacturados('Notas de Crédito de Anticipo',gastosNcAnticipos.detalles)">
              </v-text-field>
            </v-col>
            <!-- NO HAY RESULTADO -->
            <v-col cols="12" sm="3"></v-col>
          </v-row>
          <!-- COBRADOS -->
          <v-row dense class="justify-center">
            <!-- INGRESOS COBRADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de Ingresos Cobrados" v-model="ingresosCobradosImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesCobrados('Total de Ingresos Cobrados',ingresosCobrados.detalles)">
              </v-text-field>
            </v-col>
            <!-- GASTOS PAGADOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de Gastos Pagados" v-model="gastosPagadosImporte" readonly outlined
                append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesCobrados('Total de Gastos Pagados',gastosPagados.detalles)">
              </v-text-field>
            </v-col>
            <!-- RESULTADO DE INGRESOS MENOS GASTOS -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalCobrado" readonly outlined></v-text-field>
            </v-col>
          </v-row>
          <!-- IVA -->
          <v-row dense class="justify-center">
            <!-- IVA TRASLADADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de IVA Trasladado Cobrado" v-model="ivaTrasladadoImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesIva('Total de IVA Trasladado Cobrado',ivaTrasladado.detalles)">
              </v-text-field>
            </v-col>
            <!-- IVA ACREDITADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Total de IVA Acreditado Cobrado" v-model="ivaAcreditadoImporte" readonly
                outlined append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesIva('Total de IVA Acreditado Cobrado',ivaAcreditado.detalles)">
              </v-text-field>
            </v-col>
            <!-- TOTAL IVA -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalIva" readonly outlined></v-text-field>
            </v-col>
          </v-row>
          <!-- BASE IVA -->
          <v-row dense class="justify-center">
            <!-- BASE IVA TRASLADADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Base IVA" v-model="ivaTrasladadoBase" readonly outlined
                append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesIva('Base IVA',ivaTrasladado.detalles)"></v-text-field>
            </v-col>
            <!-- BASE IVA ACREDITADO -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Base IVA" v-model="ivaAcreditadoBase" readonly outlined
                append-outer-icon="mdi-file-document"
                @click:append-outer="verDetallesIva('Base IVA',ivaAcreditado.detalles)"></v-text-field>
            </v-col>
            <!-- RESULTADO BASE IVA -->
            <v-col cols="12" md="3" sm="12">
              <v-text-field prefix="$" label="Resultado" v-model="totalBaseIva" readonly outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  import VuetifyMoney from "vuetify-money";
  import Vue from 'vue'
  import DetallesFacturado from './DetallesFacturados.vue'
  import DetallesCobrados from './DetallesCobrados.vue'
  import DetallesIva from './DetallesIva.vue'

  Vue.use(VuetifyMoney);

  export default {
    name: 'Home',
    components: {
      DetallesFacturado,
      DetallesCobrados,
      DetallesIva
    },
    data: () => ({
      dialogDetallesFacturados: false,
      dialogDetallesCobrados: false,
      dialogDetallesIva: false,

      ingresosFacturados: { importe: 0, detalles: [] },
      gastosFacturados: { importe: 0, detalles: [] },

      ingresosNcAnticipos: { importe: 0, detalles: [] },
      gastosNcAnticipos: { importe: 0, detalles: [] },

      ingresosCobrados: { importe: 0, detalles: [] },
      gastosPagados: { importe: 0, detalles: [] },

      ivaTrasladado: { importe: 0, base: 0, detalles: [] },
      ivaAcreditado: { importe: 0, base: 0, detalles: [] },

      //DIALOG
      dialogMessage: '',
      dialog: false,

      titulo: '',
      
      ingresosFacturadosImporte : 0,
      ingresosNcAnticiposImporte : 0,
      ingresosCobradosImporte : 0,
      ivaTrasladadoImporte : 0,
      ivaTrasladadoBase : 0,

      gastosFacturadosImporte : 0,
      gastosNcAnticiposImporte : 0,
      gastosPagadosImporte : 0,
      ivaAcreditadoImporte : 0,
      ivaAcreditadoBase : 0,

    }),
    computed: {
      fechaHome() {
        return this.$store.state.fechaHome;
      },
      datosEmpresa() {
        return this.$store.state.usuario;
      },
      totalFacturados() {
        let b = this.ingresosFacturados.importe - this.gastosFacturados.importe
        let resta = this.functionFormatPrice(b)
        return resta;
      },
      totalCobrado() {
        let b = this.ingresosCobrados.importe - this.gastosPagados.importe
        let resta = this.functionFormatPrice(b)

        return resta;
      },
      totalIva() {
        let b = this.ivaTrasladado.importe - this.ivaAcreditado.importe
        let resta = this.functionFormatPrice(b)

        return resta;
      },
      totalBaseIva() {
        let b = this.ivaTrasladado.base - this.ivaAcreditado.base
        let resta = this.functionFormatPrice(b)

        return resta;
      },
    },

    methods: {
      //FUNCIÓN PARA DAR FORMATO DECIMAL
      functionFormatPrice(value) {
        if (value != '-') {
          let val = (value / 1).toFixed(2)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
          return '';
        }
      },
      

      async GetReporte() {

        this.dialogMessage = 'Consultando información...'
        this.dialog = true;

        // var promises = [
        await this.GetGastos()
        await this.GetIngresos()
        // ];

        // Promise.all(promises).then(results => {
        //     this.dialog = false;
        // })
      },

      async GetIngresos() {
        try {
          this.dialogMessage = 'Consultando Ingresos...'

          let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
          let response = await axios.get('Ingresos/GetIngresos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          // console.log("Ingresos", response.data)
          let r = response.data
          this.ingresosFacturados.importe = r.ingresosFacturados.importe;
          this.ingresosFacturados.detalles = r.ingresosFacturados.detalles;

          this.ingresosNcAnticipos.importe = r.anticipos.importe;
          this.ingresosNcAnticipos.detalles = r.anticipos.detalles;

          this.ingresosCobrados.importe = r.cobrados.importe;
          this.ingresosCobrados.detalles = r.cobrados.detalles;

          this.ivaTrasladado.importe = r.iva.importeIva;
          this.ivaTrasladado.base = r.iva.importeBaseIva;
          this.ivaTrasladado.detalles = r.iva.detalles;

          this.ingresosFacturadosImporte = this.functionFormatPrice(this.ingresosFacturados.importe);
          this.ingresosNcAnticiposImporte = this.functionFormatPrice(this.ingresosNcAnticipos.importe);
          this.ingresosCobradosImporte = this.functionFormatPrice(this.ingresosCobrados.importe);
          this.ivaTrasladadoImporte = this.functionFormatPrice(this.ivaTrasladado.importe);
          this.ivaTrasladadoBase = this.functionFormatPrice(this.ivaTrasladado.base);

          this.dialog = false;

        } catch (error) {
          console.log(error)
          this.dialog = false;
        }
      },

      async GetGastos() {
        try {
          this.dialogMessage = 'Consultando Gastos...'
          let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
          let response = await axios.get('Gastos/GetGastos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
          console.log(response.data)
          let r = response.data
          this.gastosFacturados.importe = r.gastosFacturados.importe;
          this.gastosFacturados.detalles = r.gastosFacturados.detalles;

          this.gastosNcAnticipos.importe = r.anticipos.importe;
          this.gastosNcAnticipos.detalles = r.anticipos.detalles;

          this.gastosPagados.importe = r.pagados.importe;
          this.gastosPagados.detalles = r.pagados.detalles;

          this.ivaAcreditado.importe = r.iva.importeIva;
          this.ivaAcreditado.base = r.iva.importeBaseIva;
          this.ivaAcreditado.detalles = r.iva.detalles;

          this.gastosFacturadosImporte = this.functionFormatPrice(this.gastosFacturados.importe);
          this.gastosNcAnticiposImporte = this.functionFormatPrice(this.gastosNcAnticipos.importe);
          this.gastosPagadosImporte = this.functionFormatPrice(this.gastosPagados.importe);
          this.ivaAcreditadoImporte = this.functionFormatPrice(this.ivaAcreditado.importe);
          this.ivaAcreditadoBase = this.functionFormatPrice(this.ivaAcreditado.base);

        } catch (error) {
          console.log(error)

        }
      },

      verDetallesFacturados(value, item) {
        this.titulo = value
        this.dialogDetallesFacturados = true

        this.$store.state.tituloDetalleStore = ''
        this.$store.state.listaDetalleStore = []

        this.$store.state.tituloDetalleStore = value + '_' + this.fechaHome.mes.descripcion + '_' + this.fechaHome.anio
        this.$store.state.listaDetalleStore = item
      },

      verDetallesCobrados(value, item){
        this.titulo = value
        this.dialogDetallesCobrados = true

        this.$store.state.tituloDetalleStore = ''
        this.$store.state.listaDetalleStore = []

        this.$store.state.tituloDetalleStore = value + '_' + this.fechaHome.mes.descripcion + '_' + this.fechaHome.anio
        this.$store.state.listaDetalleStore = item
      },

      verDetallesIva(value, item){
        this.titulo = value
        this.dialogDetallesIva = true

        this.$store.state.tituloDetalleStore = ''
        this.$store.state.listaDetalleStore = []

        this.$store.state.tituloDetalleStore = value + '_' + this.fechaHome.mes.descripcion + '_' + this.fechaHome.anio
        this.$store.state.listaDetalleStore = item
      },
    }
  };
</script>
<style>
.v-data-table .v-data-table-header tr th {
  font-size: 13px !important;
  color: black !important;
  text-align: center !important;
}

</style>