<template>
    <div class="app pt-5 mr-0">

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Nómina</h2>
            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>
        </v-card>
        <!-- MENU DE NOMINA -->
        <v-card class="justify-center pb-5" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>
                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=1, tipoConsulta = 'General'"
                                    class="rounded-lg mx-auto justify-center mr-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Datos Generales</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Datos Generales</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=2, tipoConsulta = 'Trabajadores'"
                                    class=" rounded-lg mx-auto justify-center ml-5 mr-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina Trabajadores</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina Trabajadores
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=3, tipoConsulta = 'Sat'"
                                    class="rounded-lg mx-auto justify-center ml-5 mr-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina por Conceptos SAT</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina por Conceptos
                                            SAT</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=5, tipoConsulta = 'detallado'"
                                    class="rounded-lg mx-auto justify-center ml-5" width="180" height="160" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="80" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="80" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-3 mb-1">Nómina Detallado</h3>
                                        <h3 v-if="active" class="mt-3 mb-1" style="color: white;">Nómina Detallado</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>
                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>
        <!-- MENU DE BUSQUEDA -->
        <v-card elevation="6" class="mt-5 pb-10 ma-5 pa-5">
            <v-row class="justify-center">
                <!-- FECHA INICIAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- FECHA FINAL  -->
                <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                    <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                locale="es"></v-text-field>
                        </template>
                        <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <!-- BOTON DE AYUDA  -->
                <v-col class="text-right" cols="10" xl="6" md="6" sm="10" xs="10">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                @click="ConsultarNomina()">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span>Consultar Comprobantes</span>
                    </v-tooltip>

                </v-col>
            </v-row>
        </v-card>



        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- DATOS GENERALES -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Datos Generales</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <DataTable :value="datosGen" exportFilename="NominaGeneral" ref="nominaGeneral" removableSort
                        filterDisplay="menu" class="p-datatable-sm p-datatable-customers" showGridlines
                        :scrollable="true" scrollHeight="500px" :paginator="true" :rows="20" 
                        :filters.sync="filtrosNominaGeneral"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :globalFilterFields="['version','tipoNomina','fechaFinal','fechaInicial','fechaPago','totalPercepciones','totalDeducciones','totalOtrosPagos','netoPagado']">
                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosNominaGeneral['global'].value" placeholder="Buscar..."
                                        style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="limpiarFiltrosNominaGeneral()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarNominaGeneral($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            No se encontraron.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>
                        <Column field="version" header="Versión" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'100px'}">
                            <template #body="{data}">
                                {{data.version}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por versión" />
                            </template>
                        </Column>
                        <Column field="tipoNomina" header="Tipo de Nómina" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.tipoNomina}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column header="Fecha Inicial de Pago" field="fechaInicial" sortable filterField="fechaInicial"
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaInicial)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText v-model="filterModel.value" type="text" />
                            </template>
                        </Column>
                        <Column header="Fecha Final de Pago" field="fechaFinal" sortable filterField="fechaFinal"
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaFinal)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText v-model="filterModel.value" type="text" />
                            </template>
                        </Column>
                        <Column header="Fecha Pago" sortable field="fechaPago" filterField="fechaPago"
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaPago)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText v-model="filterModel.value" type="text" />
                            </template>
                        </Column>
                        <Column header="Total de Percepciones" field="totalPercepciones" filterField="totalPercepciones"
                            sortable :bodyStyle="{'justify-content':'right'}" dataType="numeric"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalPercepciones)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>
                        <Column header="Deducciones" field="totalDeducciones" filterField="totalDeducciones" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalDeducciones)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>
                        <Column header="Otros Pagos" field="totalOtrosPagos" filterField="totalOtrosPagos" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.totalOtrosPagos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>
                        <Column header="Neto" field="netoPagado" filterField="netoPagado" sortable dataType="numeric"
                            :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.netoPagado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>
                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="5" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalPer" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalDed" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalOtrosP" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalNto" :footerStyle="{'text-align':'right'}" />
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </v-card>

            </v-window-item>

            <!--NOMINA TRABAJADORES -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina Trabajadores</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <DataTable :value="itemsNominaTrabajadores" exportFilename="NominaTrabajadores"
                        ref="nominaTrabajadores" removableSort filterDisplay="menu"
                        class="p-datatable-sm p-datatable-customers" showGridlines :scrollable="true"
                        scrollHeight="500px"  :paginator="true" :rows="20" :filters.sync="filtrosTrabajadores"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :globalFilterFields="['rfc','curp','nombre','edad','genero','numNominas','periodicidadPago','tipoContrato','tipoJornada','tipoRegimen','netoPagado','antiguedad']">
                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosTrabajadores['global'].value" placeholder="Buscar..."
                                        style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="limpiarFiltrosTrabajadores()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarNominaTrabajadores($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            No se encontraron.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>
                        <Column field="rfc" header="RFC" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'100px'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por versión" />
                            </template>
                        </Column>
                        <Column field="curp" header="CURP" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.curp}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="nombre" header="Nombre" sortable :bodyStyle="{'justify-content':'left'}"
                            :styles="{'min-width':'400px'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="edad" header="Edad" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.edad}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="genero" header="Género" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.genero}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="numNominas" header="Nóminadas Pagadas" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.numNominas}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="periodicidadPago" header="Periocidad de Pago" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.periodicidadPago}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="tipoContrato" header="Tipo de Contrato" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.tipoContrato}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="tipoJornada" header="Tipo de Jornada" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.tipoJornada}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column field="tipoRegimen" header="Tipo de Régimen" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.tipoRegimen}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <Column header="Neto Pagado" field="netoPagado" filterField="netoPagado" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.netoPagado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>
                        <Column field="antiguedad" header="Antigüedad" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.antiguedad}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>
                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="9" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalNetoTrabajadores"
                                    :footerStyle="{'text-align':'right','min-width':'200px','max-width':'200px','width':'200px'}" />
                                <Column :footerStyle="{'min-width':'200px','width':'200px','max-width':'200px'}" />
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </v-card>
            </v-window-item>

            <!--NOMINA POR CONCEPTOS SAT -->
            <v-window-item class="pt-0 mt-0" :value="3">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina por Conceptos SAT</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <DataTable :value="itemsConceptoSat" exportFilename="NominaConceptosSat" ref="NominaConceptosSat"
                        removableSort filterDisplay="menu" class="p-datatable-sm p-datatable-customers" showGridlines
                        :scrollable="true" scrollHeight="500px" :paginator="true" :rows="20" 
                        :filters.sync="filtrosNominaConceptosSat"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :globalFilterFields="['clave','descripcion','gravado','exento','deduccion','otrosPagos']">
                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosNominaConceptosSat['global'].value"
                                        placeholder="Buscar..." style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="limpiarFiltrosNominaConceptosSat()" style="width: 200px;"
                                        type="button" icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarNominaConceptosSat($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            No se encontraron.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>
                        <Column field="clave" header="Clave" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'50px'}">
                            <template #body="{data}">
                                {{data.clave}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por versión" />
                            </template>
                        </Column>
                        <Column field="descripcion" header="Descripción" sortable
                            :bodyStyle="{'justify-content':'left'}" :styles="{'min-width':'600px'}">
                            <template #body="{data}">
                                {{data.descripcion}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por tipo Nómina" />
                            </template>
                        </Column>

                        <Column header="Gravado" field="gravado" filterField="gravado" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.gravado)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>
                        <Column header="Exento" field="exento" filterField="exento" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.exento)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>
                        <Column header="Deducción" field="deduccion" filterField="deduccion" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.deduccion)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>
                        <Column header="Otros Pagos" field="otrosPagos" filterField="otrosPagos" sortable
                            dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                <!-- {{data.totPercepciones}} -->
                                {{formatCurrency(data.otrosPagos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="MXN"
                                    locale="es-MX" />
                            </template>
                        </Column>
                        <ColumnGroup type="footer">
                            <Row>
                                <Column footer="Total:" :colspan="2" :footerStyle="{'text-align':'right'}" />
                                <Column :footer="totalGravadoSat"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalExentoSat"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalDeduccionSat"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                                <Column :footer="totalOtrosPagosSat"
                                    :footerStyle="{'text-align':'right','min-width':'200px','width':'200px','max-width':'200px'}" />
                            </Row>
                        </ColumnGroup>
                    </DataTable>
                </v-card>
            </v-window-item>

            <!-- NOMINA DETALLADO -->
            <v-window-item class="pt-0 mt-0" :value="5">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Nómina Detallado</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <DataTable :value="itemsNominaDetallado" exportFilename="NominaDetallado" ref="NominaDetallado"
                        removableSort filterDisplay="menu" class="p-datatable-sm p-datatable-customers" showGridlines
                        :scrollable="true" scrollHeight="500px" :paginator="true" :rows="20" 
                        :filters.sync="filtrosNominaDetallado"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :globalFilterFields="['serie','folio','fechaComprobante','fechaInicial','fechaFinal','fechaPago','rfc','nombre','origenRecurso','puesto','departamento','numEmpleado','tipoRegimen','tipoContrato','periodicidadPago','tipoNomina','totalPercepciones','totalDeducciones','totalOtrosPagos','totalIsr','numDiasPagados']">
                        <template #header>
                            <div class="flex justify-content-between">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtrosNominaDetallado['global'].value" placeholder="Buscar..."
                                        style="width: 500px;" />
                                </span>
                                <div style="text-align: left">
                                    <Button @click="limpiarFiltrosNominaDetallado()" style="width: 200px;" type="button"
                                        icon="pi pi-filter-slash" label="Limpiar Filtros"
                                        class="mr-5 p-button-outlined" />
                                    <Button @click="exportarNominaDetallado($event)" style="width: 200px;"
                                        icon="pi pi-external-link" label="Exportar" />
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            No se encontraron.
                        </template>
                        <template #loading>
                            Cargando datos.
                        </template>
                        <Column field="serie" header="Serie" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'100px'}">
                            <template #body="{data}">
                                {{data.serie}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Buscar por versión" />
                            </template>
                        </Column>
                        <Column field="folio" header="Folio" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'100px'}">
                            <template #body="{data}">
                                {{data.folio}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                     />
                            </template>
                        </Column>
                        
                        <Column sortable field="fechaComprobante" header="Fecha Comprobante" :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaComprobante)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </template>
                        </Column>

                        <Column sortable field="fechaInicial" header="Fecha Inicial" :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaInicial)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </template>
                        </Column>

                        <Column sortable field="fechaFinal" header="Fecha Final" :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaFinal)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </template>
                        </Column>
                        <Column sortable field="fechaPago" header="Fecha Pago" :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatDate(data.fechaPago)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    placeholder="Search by name" />
                            </template>
                        </Column>

                        <Column field="rfc" header="RFC" sortable :bodyStyle="{'justify-content':'left'}"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.rfc}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                     />
                            </template>
                        </Column>

                        <Column field="nombre" header="Nombre" sortable :bodyStyle="{'justify-content':'left'}"
                            :styles="{'min-width':'400px'}">
                            <template #body="{data}">
                                {{data.nombre}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column field="origenRecurso" header="Origen Recurso" sortable :bodyStyle="{'justify-content':'center'}"
                            :styles="{'min-width':'150px'}">
                            <template #body="{data}">
                                {{data.origenRecurso}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column field="puesto" header="Puesto" sortable :bodyStyle="{'justify-content':'left'}"
                            :styles="{'min-width':'300px'}">
                            <template #body="{data}">
                                {{data.puesto}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column field="departamento" header="Departamento" sortable :bodyStyle="{'justify-content':'left'}"
                            :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.departamento}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                     />
                            </template>
                        </Column>

                        <Column field="numEmpleado" header="No. Empleado" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.numEmpleado}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column field="tipoRegimen" header="Tipo Régimen" sortable
                            :bodyStyle="{'justify-content':'left'}" :styles="{'min-width':'600px'}">
                            <template #body="{data}">
                                {{data.tipoRegimen}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column field="tipoContrato" header="Tipo de Contrato" sortable
                            :bodyStyle="{'justify-content':'left'}" :styles="{'min-width':'500px'}">
                            <template #body="{data}">
                                {{data.tipoContrato}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column field="periodicidadPago" header="Periocidad de Pago" sortable
                            :bodyStyle="{'justify-content':'left'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.periodicidadPago}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                     />
                            </template>
                        </Column>

                        <Column field="tipoNomina" header="Tipo de Nómina" sortable
                            :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.tipoNomina}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                    />
                            </template>
                        </Column>

                        <Column header="Total Percepciones" field="totalPercepciones" filterField="totalPercepciones" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalPercepciones)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>
                        
                        <Column header="Total Otros Pagos" field="totalOtrosPagos" filterField="totalOtrosPagos" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalOtrosPagos)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>

                        <Column header="Total ISR" field="totalIsr" filterField="totalIsr" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{formatCurrency(data.totalIsr)}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>

                        <Column header="No. Días Pagados" field="numDiasPagados" filterField="numDiasPagados" sortable
                            :bodyStyle="{'justify-content':'right'}" dataType="numeric" :styles="{'min-width':'200px'}">
                            <template #body="{data}">
                                {{data.numDiasPagados}}
                            </template>
                            <template #filter="{filterModel}">
                                <InputNumber v-model="filterModel.value" mode="currency" currency="USD"
                                    locale="en-US" />
                            </template>
                        </Column>
                    </DataTable>
                </v-card>
            </v-window-item>

        </v-window>

    </div>
</template>
<script>
    import axios from 'axios';
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import { FilterMatchMode, FilterOperator } from 'primevue/api/';

    export default {
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,

        },
        data: () => ({
            filtrosNominaGeneral: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoNomina': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaInicial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaFinal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'totalPercepciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalDeducciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalOtrosPagos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'netoPagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            },
            filtrosTrabajadores: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'curp': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'edad': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'genero': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'numNominas': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'periodicidadPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoContrato': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoJornada': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoRegimen': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'netoPagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'antiguedad': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
            filtrosNominaConceptosSat: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'clave': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'descripcion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'gravado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'exento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'deduccion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'otrosPagos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            },
            filtrosNominaDetallado: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaComprobante': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaInicial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaFinal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'origenRecurso': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'puesto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'departamento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'numEmpleado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoRegimen': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoContrato': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'periodicidadPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoNomina': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'totalPercepciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalDeducciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalOtrosPagos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalIsr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'numDiasPagados': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            },
            
            step: 1,
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            datosGen: [],
            itemsNominaTrabajadores: [],
            itemsConceptoSat: [],
            itemsNominaDetallado: [],

            //DIALOG
            dialog: false,
            dialogMessage: '',
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            tipoConsulta: 'General', //Trabajadores, Sat, Sncf

            loading: true,


        }),

        mounted() {
            this.loading = false;
        },

        computed: {
            // METODOS COMPUTADOS PARA LAS FECHAS
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },

            // PRIMEVUE
            totalNto() {
                let totalN = 0;
                for (let sumaN of this.datosGen) {
                    totalN += sumaN.netoPagado;
                }
                return this.formatCurrency(totalN);
            },
            totalPer() {
                let totalPer = 0;
                for (let sumaPer of this.datosGen) {
                    totalPer += sumaPer.totalPercepciones;
                }
                return this.formatCurrency(totalPer);
            },
            totalDed() {
                let totalDed = 0;
                for (let sumaPer of this.datosGen) {
                    totalDed += sumaPer.totalDeducciones;
                }
                return this.formatCurrency(totalDed);
            },
            totalOtrosP() {
                let totalOtrosP = 0;
                for (let sumaPer of this.datosGen) {
                    totalOtrosP += sumaPer.totalOtrosPagos;
                }
                return this.formatCurrency(totalOtrosP);
            },

            totalNetoTrabajadores() {
                let totalN = 0;
                for (let sumaN of this.itemsNominaTrabajadores) {
                    totalN += sumaN.netoPagado;
                }
                return this.formatCurrency(totalN);
            },

            // NOMINA CONCEPTOS SAT
            totalGravadoSat() {
                let totalN = 0;
                for (let sumaN of this.itemsConceptoSat) {
                    totalN += sumaN.gravado;
                }
                return this.formatCurrency(totalN);
            },
            totalExentoSat() {
                let totalN = 0;
                for (let sumaN of this.itemsConceptoSat) {
                    totalN += sumaN.exento;
                }
                return this.formatCurrency(totalN);
            },
            totalDeduccionSat() {
                let totalN = 0;
                for (let sumaN of this.itemsConceptoSat) {
                    totalN += sumaN.deduccion;
                }
                return this.formatCurrency(totalN);
            },
            totalOtrosPagosSat() {
                let totalN = 0;
                for (let sumaN of this.itemsConceptoSat) {
                    totalN += sumaN.otrosPagos;
                }
                return this.formatCurrency(totalN);
            },
        },
        methods: {
            async ConsultarNomina() {
                switch (this.tipoConsulta) {
                    case 'General':
                        this.ConsultaNominaGeneral();
                        break;
                    case 'Trabajadores':
                        this.ConsultaNominaTrabajadores();
                        break;
                    case 'Sat':
                        this.ConsultaNominaSat();
                        break;
                    case 'Sncf':
                        this.ConsultarNominaSNCF();
                        break;
                    case 'detallado':
                        this.ConsultarNominaDetallado();
                        break;
                }
            },

            async ConsultaNominaGeneral() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                try {
                    let response = await axios.get('Nomina/GetNominaGeneral/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.datosGen = response.data.nominaGeneral

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultaNominaTrabajadores() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                console.log(this.dateBuscaF)
                console.log(this.dateBuscaI)
                this.itemsNominaTrabajadores = [];
                try {
                    let response = await axios.get('Nomina/GetNominaTrabajadores/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsNominaTrabajadores = response.data.nominaTrabajadores

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultaNominaSat() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                try {
                    let response = await axios.get('Nomina/GetNominaConceptosSat/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsConceptoSat = response.data.nominaConceptosSat

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultarNominaDetallado() {
                this.dialog = true;
                this.dialogMessage = 'Consultando Detalles...'
                try {
                    let response = await axios.get('Nomina/GetRecibos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    console.log(response.data)
                    this.itemsNominaDetallado = response.data

                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },
            
            limpiarFiltrosNominaGeneral() {
                this.filtrosNominaGeneral = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'version': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoNomina': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'fechaInicial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'fechaFinal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'fechaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'totalPercepciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'totalDeducciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'totalOtrosPagos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'netoPagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                }
            },
            exportarNominaGeneral() {
                this.$refs.nominaGeneral.exportCSV();
            },
            limpiarFiltrosTrabajadores() {
                this.filtrosTrabajadores = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'curp': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'edad': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'genero': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'numNominas': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'periodicidadPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoContrato': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoJornada': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'tipoRegimen': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'netoPagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                    'antiguedad': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                }
            },
            exportarNominaTrabajadores() {
                this.$refs.nominaTrabajadores.exportCSV();
            },
            limpiarFiltrosNominaConceptosSat() {
                this.filtrosNominaConceptosSat = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'clave': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'descripcion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'gravado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'exento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'deduccion': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                    'otrosPagos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                }
            },
            exportarNominaConceptosSat() {
                this.$refs.NominaConceptosSat.exportCSV();
            },
            limpiarFiltrosNominaDetallado(){
                this.filtrosNominaDetallado= {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaComprobante': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaInicial': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaFinal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fechaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'origenRecurso': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'puesto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'departamento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'numEmpleado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoRegimen': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoContrato': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'periodicidadPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoNomina': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'totalPercepciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalDeducciones': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalOtrosPagos': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalIsr': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'numDiasPagados': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
            },
            exportarNominaDetallado() {
                this.$refs.NominaDetallado.exportCSV();
            },
            
            //PRIMEVUE
            formatDate(date) {
                return moment(date).format("yyyy-MM-DD");
            },

            formatCurrency(value) {
                return value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
            }

        },

    }


</script>