<template>
    <div class="app pt-5">
        <!-- DIALOG PARA VER DETALLES -->
        <v-dialog v-model="dialogDetalle" max-width="1750">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download> 
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcel()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download> 
                    </xlsx-workbook>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchDetalle"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerDetalles" :items="itemsDetalles" :search="searchDetalle" fixed-header
                        height="60vh">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG DE PROVEEDORES -->
        <v-dialog v-model="dialogProveedores" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                @click="dialogProveedores = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Cerrar</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-data-table :headers="headerProveedores" :items="itemsProveedores">
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- DIALOG DE QUE ESTA HACIENDO ALGO -->
        <div>
            <v-dialog v-model="dialog" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                        {{ dialogMessage }}
                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

        <!-- DIALOGO PARA SUBIR ARCHIVOS PARA EL INVENTARIO -->
        <v-dialog v-model="dialogoSubirArchivos" max-width="1200">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Reportes Excel</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row class="justify-center mt-5">
                        <!-- SELECT DE SUCURSALES -->
                        <v-col cols="12" xl="10" md="10" sm="10" xs="10">
                            <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursalArchivoInventario" item-text="descripcion"
                                return-object :items="itemsNoCre" label="Sucursal"></v-select>
                        </v-col>
                        <v-col cols="12" xl="2" md="2" sm="2" xs="2" class="text-right">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-2" fab dark color="primary darken-1" v-bind="attrs" v-on="on" @click="getDatosTotalesInventarioExcel()">
                                        <v-icon dark>
                                            mdi-magnify
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Consultar</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center mt-5">
                        <v-col cols="12" xl="10" md="10" sm="10" xs="10">
                        <v-file-input label="Seleccione el archivo .xlsx a cargar" truncate-length="50"
                                @change="agregarTotales"></v-file-input>
                        </v-col>
                    <v-col cols="12" xl="2" md="2" sm="2" xs="2" class="text-right">
                    <xlsx-read :file="archivo">
                                <xlsx-json :file="archivo">
                                    <template #default="{collection}">
                                        <span hidden>{{ collection }}</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-2" fab dark color="primary darken-1" v-bind="attrs"
                                                    v-on="on" @click="ObtenerDatosExcelInventario(collection)">
                                                    <v-icon dark>
                                                        mdi-plus
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Cargar Excel</span>
                                        </v-tooltip>
                                    </template>
                                </xlsx-json>
                            </xlsx-read>
                        </v-col>
                    </v-row>

                    <v-data-table :items-per-page="-1" hide-default-footer :headers="headerTotalesExcel" :items="itemsTotalesExcel">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.magnaLitros`]="{ item }">
                            <span>{{ functionFormatPrice(item.magnaLitros) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.premiumLitros`]="{ item }">
                            <span>{{ functionFormatPrice(item.premiumLitros) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.dieselLitros`]="{ item }">
                            <span>{{ functionFormatPrice(item.dieselLitros) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.magnaTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.magnaTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.premiumTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.premiumTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.dieselTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.dieselTotal) }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="eliminarMes(item)">mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>Eliminar Datos</span>
                                </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- TITULO -->
        <v-card elevation="6" class="justify-center mb-5 mr-5 ml-5">
            <v-card-title class="dark justify-center">
                <h2 class="justify-center">Gasolinera</h2>

            </v-card-title>
            <v-system-bar color="primary" height="6"></v-system-bar>

        </v-card>

        <!-- DIALOGO PARA VER INVETARIO INICIAL-->
        <v-dialog v-model="dialogoInventario" max-width="800">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Inventario Inicial</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2  mr-1 ml-0" color="primary darken-1" dark small fab v-bind="attrs"
                                v-on="on" @click="guardarOeditar()">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Guardar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row class="justify-center">
                        <!-- FECHAS DE BUSQUEDA -->
                        <!-- <v-col cols="12" xl="6" md="6" sm="6" xs="10" class="mt-5">
                            <v-menu v-model="menuDateBuscaInventario" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field prepend-icon="mdi-calendar"
                                        :value="computedDateFormattedMomentjsInventario" label="Fecha" readonly
                                        v-bind="attrs" v-on="on" @click:clear="dateBuscaInventario = null" locale="es">
                                    </v-text-field>
                                </template>
                                <v-date-picker locale="es" v-model="inventario.dateBuscaInventario"
                                    @change="menuDateBuscaInventario = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col> -->
                        <v-col cols="12" xl="12" md="12" sm="12" xs="12" class="mt-5">
                            <v-select prepend-icon="mdi-gas-station" v-model="inventario.SelectSucursal"
                                item-text="descripcion" return-object @change="GetInventario()" :items="itemsNoCre"
                                label="Sucursal">
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-data-table :items-per-page="-1" hide-default-footer :headers="headerNuveoInventario"
                        :items="inventario.itemsNuevoInventario">
                        <template v-slot:item.cantidad="props">
                            <v-edit-dialog :return-value.sync="props.item.cantidad">
                                {{ props.item.cantidad }}
                                <template v-slot:input>
                                    <v-text-field v-model="props.item.cantidad" label="Editar" single-line counter>
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.valor="props">
                            <v-edit-dialog :return-value.sync="props.item.valor">
                                {{ functionFormatPrice(props.item.valor) }}
                                <template v-slot:input>
                                    <v-text-field v-model="props.item.valor" label="Editar" single-line counter>
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG PARA ASIGNAR COMPROBANTES -->
        <v-dialog v-model="dialogComprobantes" max-width="1750">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-row class="justify-center">
                        <!-- FECHAS DE BUSQUEDA -->
                        <v-col cols="12" xl="3" md="3" sm="10" xs="10" class="mt-5">
                            <v-menu v-model="menuDateBuscaIC" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsIC"
                                        label="Fecha" readonly v-bind="attrs" v-on="on"
                                        @click:clear="dateBuscaIC = null" locale="es"></v-text-field>
                                </template>
                                <v-date-picker locale="es" v-model="dateBuscaIC" @change="menuDateBuscaIC = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xl="3" md="3" sm="10" xs="10" class="mt-5">
                            <v-menu v-model="menuDateBuscaFC" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsFC"
                                        label="Fecha" readonly v-bind="attrs" v-on="on"
                                        @click:clear="dateBuscaFC = null" locale="es"></v-text-field>
                                </template>
                                <v-date-picker locale="es" v-model="dateBuscaFC" @change="menuDateBuscaFC = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <!-- BOTON DE CONSULTA -->
                        <v-col cols="12" xl="6" md="6" sm="6" xs="12" class="mt-5 text-right">
                            <v-tooltip bottom class="mt-5">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="getComprasComprobantes()">
                                        <v-icon>mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                                <span>Consultar</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- SELECT DE SUCURSALES -->
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursal" item-text="descripcion"
                                return-object :items="itemsNoCre" label="Sucursal"></v-select>
                        </v-col>
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12" class="text-right">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mr-4 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="PostComprasSucursal()">
                                        <v-icon>mdi-content-save-settings</v-icon>
                                    </v-btn>
                                </template>
                                <span>Guardar Comprobantes</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <v-data-table :headers="headerComprobantes" :items="itemsComprobantes" v-model="selected"
                        show-select item-key="item">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOG PARA EDITAR COMPROBANTES -->
        <v-dialog v-model="dialogEditaComprobantes" max-width="1750">
            <v-card>
                <v-toolbar>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2  mr-1 ml-0" fab dark small color="primary" v-bind="attrs" v-on="on"
                                @click="dialogInformacion = true">
                                <v-icon dark>
                                    mdi-help-circle-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Ayuda</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2  mr-1 ml-0" color="primary darken-1" dark small fab v-bind="attrs"
                                v-on="on" @click="BorraComprobantes()">
                                <v-icon>mdi-delete-forever</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-data-table :headers="headerComprobantesEdita" :items="itemsComprobantesEdita"
                        :search="searchDetalle" v-model="selectedEdit" show-select item-key="folioFiscal">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>


        <!-- DIALOG PARA INFORMACION -->
        <v-dialog v-model="dialogInformacion" width="800">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>INFORMACIÓN</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-system-bar color="info" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-alert border="right" colored-border type="info">
                        <h3 class="mb-3">¿Cómo eliminar los comprobantes asignados?</h3>
                        <p>Para eliminar los comprobantes asignado basta con marcar la casilla del/los comprobantes no
                            deseados y
                            dar clic en el boton de eliminar, automaticamente se actualizara la tabla anterior.</p>
                    </v-alert>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOGO PARA MERMA-->
        <v-dialog v-model="dialogoMerma" max-width="800">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Merma</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2  mr-1 ml-0" color="primary darken-1" dark small fab v-bind="attrs"
                                v-on="on" @click="guardaOActualizaM()">
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Guardar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>

                    <v-row class="justify-center mt-5">
                        <!-- FECHAS DE BUSQUEDA -->
                        <!-- SELECT DEL AÑO -->
                        <v-col cols="3" xl="3" md="3" sm="12" xs="12" class="mt-5">
                            <v-select label="Año" :items="itemsAño" v-model="anio" outlined></v-select>
                        </v-col>
                        <!-- SELECT DEL MES -->
                        <v-col cols="3" xl="3" md="3" sm="12" xs="12" class="mt-5">
                            <v-select label="Mes" :items="itemsMes" v-model="mes" outlined return-object
                                item-text="descripcion"></v-select>
                        </v-col>

                        <v-col cols="6" xl="6" md="6" sm="12" xs="12" class="mt-5">
                            <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursalMerma"
                                item-text="descripcion" return-object @change="GetMerma()" :items="itemsNoCre"
                                label="Sucursal">
                            </v-select>
                        </v-col>

                    </v-row>
                    <v-data-table :items-per-page="-1" hide-default-footer :headers="headerMerma" :items="itemsMerma">
                        <template v-slot:item.cantidad="props">
                            <v-edit-dialog :return-value.sync="props.item.cantidad">
                                {{ props.item.cantidad }}
                                <template v-slot:input>
                                    <v-text-field v-model="props.item.cantidad" label="Editar" single-line counter>
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.valor="props">
                            <v-edit-dialog :return-value.sync="props.item.valor">
                                {{ functionFormatPrice(props.item.valor) }}
                                <template v-slot:input>
                                    <v-text-field v-model="props.item.valor" label="Editar" single-line counter>
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- MENU DE COMPONENTES -->
        <v-card class="justify-center pb-5" flat color="transparent">
            <v-item-group mandatory>
                <v-container>
                    <v-row dense>
                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=1"
                                    class="mx-auto justify-center mr-5  rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-1 mb-1">Ventas por Sucursal</h3>
                                        <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Ventas por Sucursal
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=2"
                                    class="mx-auto justify-center ml-5 mr-5  rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">
                                            mdi-file-document-multiple-outline</v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-file-document-multiple-outline
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-1 mb-1">Compras por Producto</h3>
                                        <h3 v-if="active" class="mt-1 mb-1" style="color: white;">Compras por Producto
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <!-- <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=3"
                                    class="mx-auto justify-center ml-5 mr-5 rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">mdi-gas-station</v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-gas-station</v-icon>
                                        <h3 v-if="!active" class="mt-2 mb-1">Ventas</h3>
                                        <h3 v-if="active" class="mt-2 mb-1" style="color: white;">Ventas</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item> -->

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=4"
                                    class="mx-auto justify-center ml-5 mr-5 rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">mdi-file-xml-box
                                        </v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-file-xml-box
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-2 mb-1">Reporte XML</h3>
                                        <h3 v-if="active" class="mt-2 mb-1" style="color: white;">Reporte XML</h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=5"
                                    class="mx-auto justify-center ml-5 mr-5 rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">mdi-file-chart</v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-file-chart</v-icon>
                                        <h3 v-if="!active" class="mt-2 mb-1">Reportes Especiales</h3>
                                        <h3 v-if="active" class="mt-2 mb-1" style="color: white;">Reportes Especiales
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>

                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=6"
                                    class="mx-auto justify-center ml-5 mr-5 rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">mdi-store
                                        </v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-store
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-2 mb-1">Compras por sucursal</h3>
                                        <h3 v-if="active" class="mt-2 mb-1" style="color: white;">Compras por sucursal
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>
                        <v-item v-slot="{ active, toggle }">
                            <v-hover v-slot="{ hover }" open-delay="10">
                                <v-card :color="active ? 'primary' : ''" :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }" @click="step=7"
                                    class="mx-auto justify-center ml-5 rounded-lg" width="150" height="130" link>
                                    <v-card-text class="text-center" @click="toggle">
                                        <v-icon v-if="!active" size="60" color="primary">mdi-package-variant-closed
                                        </v-icon>
                                        <v-icon v-if="active" size="60" color="white">mdi-package-variant-closed
                                        </v-icon>
                                        <h3 v-if="!active" class="mt-2 mb-1">Inventario</h3>
                                        <h3 v-if="active" class="mt-2 mb-1" style="color: white;">Inventario
                                        </h3>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-item>
                    </v-row>
                </v-container>
            </v-item-group>
        </v-card>

        <!-- MENU DE BUSQUEDA -->
        <template>
            <v-card v-if="this.step == 1 ||this.step == 2||this.step == 3||this.step == 6" elevation="6"
                class="mt-5 pt-10 pb-10 pa-5 ma-5">
                <v-row class="justify-center">
                    <!-- SELECCION DE DATOS -->
                    <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                        <v-menu v-model="menuDateBuscaI" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsI"
                                    label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaI = null"
                                    locale="es"></v-text-field>
                            </template>
                            <v-date-picker locale="es" v-model="dateBuscaI" @change="menuDateBuscaI = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- FECHA INICIAL  -->
                    <v-col cols="10" xl="3" md="3" sm="10" xs="10">
                        <v-menu v-model="menuDateBuscaF" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field prepend-icon="mdi-calendar" :value="computedDateFormattedMomentjsF"
                                    label="Fecha" readonly v-bind="attrs" v-on="on" @click:clear="dateBuscaF = null"
                                    locale="es"></v-text-field>
                            </template>
                            <v-date-picker locale="es" v-model="dateBuscaF" @change="menuDateBuscaF = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <!-- <v-col cols="10" xl="6" md="6" sm="10" xs="10" class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                    @click="verInventario()" v-on="on">
                                    <v-icon>mdi-package-variant-closed</v-icon>
                                </v-btn>
                            </template>
                            <span>Inventario Inicial</span>
                        </v-tooltip>
                    </v-col> -->
                </v-row>
            </v-card>
        </template>

        <!-- VENTANAS DINAMICAS -->
        <v-window v-model="step" class="pt-0 mt-0">
            <!-- VENTAS POR SUCURSAL -->
            <v-window-item class="pt-0 mt-0" :value="1">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Ventas por Sucursal</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row justify="space-around">
                        <v-col cols="12" xl="6" md="6" sm="6" xs="6">
                            <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursal" item-text="descripcion"
                                return-object :items="itemsNoCre" label="Sucursal"></v-select>
                        </v-col>
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-col class="text-right" cols="12" xl="6" md="6" sm="6" xs="6">
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="ConsultarVentas()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Consultar ventas</span>
                                </v-tooltip>
                                <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="GenerarExcelVentas()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-data-table :item-class="row_classes" :headers="headerVentas" :items="itemsVentas"
                        hide-default-footer class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <!-- <template v-if="item.descripcion != 'TOTAL:' && item.descripcion != ''"> -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="detallesVentas(item,'Ventas por sucursal')">mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Comprobaciones</span>
                            </v-tooltip>
                            <!-- </template> -->
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
                <!-- VENTAS A PUBLICO EN GENERAL -->
                <v-card elevation="6" class="justify-center ma-5 mt-10">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Público en General</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-data-table :item-class="row_classes" :headers="headerVentas" :items="itemsVentasPublicoGeneral"
                        hide-default-footer class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <template v-if="item.descripcion != 'TOTAL:' && item.descripcion != ''">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="detallesVentas(item,'Ventas por sucursal')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </template>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
                <!-- VENTAS A OTROS -->
                <v-card elevation="6" class="justify-center  ma-5 mt-10">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Otros</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-data-table :item-class="row_classes" :headers="headerVentas" :items="itemsVentasOtros"
                        hide-default-footer class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <template v-if="item.descripcion != 'TOTAL:' && item.descripcion != ''">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="detallesVentas(item,'Ventas por sucursal')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </template>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- COMPRAS POR PRODUCTO -->
            <v-window-item class="pt-0 mt-0" :value="2">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Compras por Producto</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row justify="space-around">
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="GetProveedores()">
                                            <v-icon>mdi-tanker-truck</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Proveedores</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="ConsultarCompras()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Consultar compras</span>
                                </v-tooltip>
                                <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="GeneraExcelCompras()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-data-table :item-class="row_classes" :headers="headerCompras" :items="itemsCompras"
                        hide-default-footer class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <!-- <template v-if="item.descripcion != 'TOTAL:' && item.descripcion != ''"> -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="detallesCompras(item,'Compras por productos')">mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Comprobaciones</span>
                            </v-tooltip>
                            <!-- </template> -->
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- VENTAS POS -->
            <v-window-item class="pt-0 mt-0" :value="3">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Ventas POS</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row justify="space-around">
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-row>
                                <v-col cols="12" xl="6" md="6" sm="6" xs="6">
                                    <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursal"
                                        item-text="descripcion" return-object :items="itemsNoCre" label="Sucursal">
                                    </v-select>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="GetVentasPos()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Consultar ventas</span>
                                </v-tooltip>
                                <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="GeneraExcelCompras()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Filtrar" v-model="searchVentasPos"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :item-class="row_classes" :headers="headersVentasPos" :items="itemsVentasPos"
                        class="elevation-0" :search="searchVentasPos">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="detallesCompras(item,'Ventas POS')">mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Comprobaciones</span>
                            </v-tooltip>
                            <!-- </template> -->
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.volumen`]="{ item }">
                            <span>{{ functionFormatPrice(item.volumen) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.monto`]="{ item }">
                            <span>{{ functionFormatPrice(item.monto) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.precioUnitario`]="{ item }">
                            <span>{{ functionFormatPrice(item.precioUnitario) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.fechaVenta`]="{ item }">
                            <span>{{ functionFormatDate(item.fechaVenta) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.fechaFactura`]="{ item }">
                            <span>{{ functionFormatDate(item.fechaFactura) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- SUBIR ARCHIVO XML -->
            <v-window-item class="pt-0 mt-0" :value="4">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Reporte XML</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row>
                        <v-col cols="12" xl="6" md="6" sm="6" xs="12">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                        @click="VentanaSubirArchivos()">
                                        <v-icon>mdi-xml</v-icon>
                                    </v-btn>
                                </template>
                                <span>Subir Archivo</span>
                            </v-tooltip>
                        </v-col>
                        <v-col class="text-right" cols="12" xl="6" md="6" sm="6" xs="12">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab v-bind="attrs"
                                                v-on="on" @click="GeneraExcelReporteXML()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerReportexml" :items="$store.state.listaVentasXML" class="elevation-0">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalVenta`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalVenta) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.precioDeVentaAlPublico`]="{ item }">
                            <span>{{ functionFormatPrice(item.precioDeVentaAlPublico) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalVenta`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalVenta) }}</span>
                        </template>
                    </v-data-table>
                    <v-data-table :item-class="row_classes_totales" :headers="headerTotalesXML"
                        :items="$store.state.totalesReporteXML" hide-default-footer class="elevation-0 mt-10">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalVentasXML`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalVentasXML) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalDiesel`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalDiesel) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalMagna`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalMagna) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalPremium`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalPremium) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- REPORTES ESPECIALES -->
            <v-window-item class="pt-0 mt-0" :value="5">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Reportes Especiales</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row>
                        <!-- <div> -->
                        <!-- <v-row class="ma-5"> -->
                        <v-col cols="12" xl="5" md="5" sm="12" xs="12">
                            <v-file-input label="Seleccione el archivo .xlsx a cargar" truncate-length="50"
                                @change="onChange"></v-file-input>
                        </v-col>
                        <v-col cols="12" xl="4" md="4" sm="12" xs="12">
                            <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursal" item-text="descripcion"
                                return-object :items="itemsNoCre" label="Sucursal"></v-select>
                        </v-col>
                        <v-col class="text-right" cols="12" xl="2" md="2" sm="1" xs="12">
                            <xlsx-read :file="file">
                                <xlsx-json :file="file">
                                    <template #default="{collection}">
                                        <span hidden>{{ collection }}</span>
                                        <v-spacer></v-spacer>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-2" fab dark color="primary darken-1" v-bind="attrs"
                                                    v-on="on" @click="ObtenerDatosExcel(collection)">
                                                    <v-icon dark>
                                                        mdi-table-check
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Cargar Excel</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark
                                                    color="primary darken-1" @click="comparativaReporte()">
                                                    <v-icon dark>
                                                        mdi-file-compare
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Comparativa</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark
                                                    color="primary darken-1" @click="separaFormaPago()">
                                                    <v-icon dark>
                                                        mdi-cash-multiple
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Agrupar por Forma de Pago</span>
                                        </v-tooltip>
                                    </template>
                                </xlsx-json>
                            </xlsx-read>
                        </v-col>

                        <v-col class="text-left" cols="12" xl="1" md="1" sm="1" xs="12">
                            <xlsx-workbook>
                                <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                    :sheet-name="sheet.name" />
                                <xlsx-download>
                                </xlsx-download>
                                <xlsx-download :filename="nombreReporte">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mr-2" color="primary darken-1" dark fab v-bind="attrs"
                                                v-on="on" @click="GeneraExcelReporte()">
                                                <v-icon>mdi-file-table-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Generar Excel</span>
                                    </v-tooltip>
                                </xlsx-download>
                            </xlsx-workbook>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headerReporte" :items="itemsReporte" class="elevation-0">
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                    </v-data-table>
                    <v-data-table :item-class="row_classes_totales" :headers="headerTotales" :items="itemsTotales"
                        hide-default-footer class="elevation-0 mt-10">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.sumaSinFacturar`]="{ item }">
                            <span>{{ functionFormatPrice(item.sumaSinFacturar) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.sumaFueraMes`]="{ item }">
                            <span>{{ functionFormatPrice(item.sumaFueraMes) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.sumaPendiente`]="{ item }">
                            <span>{{ functionFormatPrice(item.sumaPendiente) }}</span>
                        </template>
                        <template v-slot:[`item.totalVentas`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalVentas) }}</span>
                        </template>
                    </v-data-table>
                    <v-data-table :item-class="row_classes_totales" :headers="headerTotalesVentas"
                        :items="itemsTotalesVenta" hide-default-footer class="elevation-0">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ventasMagna`]="{ item }">
                            <span>{{ functionFormatPrice(item.ventasMagna) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ventasDiesel`]="{ item }">
                            <span>{{ functionFormatPrice(item.ventasDiesel) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ventasPremium`]="{ item }">
                            <span>{{ functionFormatPrice(item.ventasPremium) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalVenta`]="{ item }">
                            <span>{{ functionFormatPrice(item.totalVenta) }}</span>
                        </template>
                    </v-data-table>
                    <v-data-table :item-class="row_classes_totales" :headers="headerTotalesFacturado"
                        :items="itemsTotalesFactura" hide-default-footer class="elevation-0">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.facturadoDiesel`]="{ item }">
                            <span>{{ functionFormatPrice(item.facturadoDiesel) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.facturadoPremium`]="{ item }">
                            <span>{{ functionFormatPrice(item.facturadoPremium) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.facturadoMagna`]="{ item }">
                            <span>{{ functionFormatPrice(item.facturadoMagna) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.facturado`]="{ item }">
                            <span>{{ functionFormatPrice(item.facturado) }}</span>
                        </template>
                    </v-data-table>
                    <!-- FORMAS DE PAGO -->
                    <v-data-table :item-class="row_classes" :headers="headersFormaPago" :items="itemsFormaPago"
                        hide-default-footer class="elevation-0" :items-per-page="-1">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.importe`]="{ item }">
                            <span>{{ functionFormatPrice(item.importe) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- COMPRAS POR SUCURSAL -->
            <v-window-item class="pt-0 mt-0" :value="6">
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Compras por sucursal</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <!-- <v-row justify="space-around"> -->
                    <!-- <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12"> -->
                    <v-row>
                        <!-- SELECT DE SUCURSALES -->
                        <v-col cols="12" xl="6" md="6" sm="12" xs="12">
                            <v-select prepend-icon="mdi-gas-station" v-model="SelectSucursal" item-text="descripcion"
                                return-object :items="itemsNoCre" label="Sucursal">
                            </v-select>
                        </v-col>
                        <!-- BOTONES (ASIGNAR COMPROBANTES Y CONSULTA) -->
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="verAsignaComprobante('Asignar Comprobantes')">
                                    <v-icon>mdi-clipboard-list-outline </v-icon>
                                </v-btn>
                            </template>
                            <span>Asignar comprobantes</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="GetReporteComprasSucursal()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar compras</span>
                        </v-tooltip>
                        <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="GeneraExcelComprasPorSucursal()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download> 
                                </xlsx-workbook>
                    </v-row>

                    <!-- TABLA COMPRAS POR SUCURSAL -->
                    <v-data-table :item-class="row_classes" :headers="headerComprasSucursal"
                        :items="itemsComprasSucursal" hide-default-footer class="elevation-0">
                        <template v-slot:[`item.actions`]="{ item }">
                            <!-- <template v-if="item.descripcion != 'TOTAL:' && item.descripcion != ''"> -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                        @click="verEditaComprobante(item, 'Editar comprobantes')">
                                        mdi-file-document
                                    </v-icon>
                                </template>
                                <span>Editar comprobantes</span>
                            </v-tooltip>
                            <!-- </template> -->
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.ieps`]="{ item }">
                            <span>{{ functionFormatPrice(item.ieps) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.iva`]="{ item }">
                            <span>{{ functionFormatPrice(item.iva) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.descuento`]="{ item }">
                            <span>{{ functionFormatPrice(item.descuento) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidad`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidad) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
            <!-- INVENTARIO -->
            <v-window-item class="pt-0 mt-0" :value="7">
                <v-card elevation="6" class="mt-5 pt-10 pb-10 pa-5 ma-5">
                    <v-row class="justify-center">
                        <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                            <v-select label="Año" :items="itemsAño" v-model="añoInventario"></v-select>
                        </v-col>
                        <v-col cols="12" xl="3" md="3" sm="12" xs="12">
                            <v-select label="Mes" :items="itemsMes" v-model="mesInventario" return-object
                                item-text="descripcion"></v-select>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card elevation="6" class="justify-center  ma-5 ">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Inventario</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>
                <v-card elevation="6" class="mt-5 pb-10 pa-5 ma-5">
                    <v-row justify="space-around">

                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-col class="text-right" cols="12" xl="12" md="12" sm="12" xs="12">

                            <v-row>
                                <!-- SELECT DE SUCURSALES -->
                                <v-col class="pb-0 mb-0" cols="12" xl="6" md="6" sm="12" xs="12">
                                    <v-select class="pb-0 mb-0" prepend-icon="mdi-gas-station" v-model="SelectSucursal"
                                        item-text="descripcion" return-object :items="itemsNoCre" label="Sucursal">
                                    </v-select>
                                    <v-radio-group class="pa-0 ma-0" v-model="formaDeConcultarInventario" row>
                                        <v-radio label="Comprobantes" :value="1"></v-radio>
                                        <v-radio label="Reporte Excel" :value="2"></v-radio>
                                    </v-radio-group>
                                </v-col>

                                <v-spacer></v-spacer>
                                <template v-if="formaDeConcultarInventario == 2">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="secondary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="dialogoSubirArchivos = true">
                                            <v-icon>mdi-paperclip</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Subir Archivos</span>
                                </v-tooltip>
                            </template>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="agregaMerma()">
                                            <v-icon>mdi-delete-restore</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Agregar Merma</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="nuevoInventario()">
                                            <v-icon>mdi-package-variant-closed</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Inventario Inicial</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs"
                                            v-on="on" @click="GetInventarioSucursal()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Consultar inventario</span>
                                </v-tooltip>
                                <xlsx-workbook>
                                    <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                        :sheet-name="sheet.name" />
                                    <xlsx-download>
                                    </xlsx-download>
                                    <xlsx-download :filename="nombreReporte">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mr-4 mt-2" color="primary darken-1" dark fab
                                                    v-bind="attrs" v-on="on" @click="generarExcelInventario()">
                                                    <v-icon>mdi-file-table-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Generar Excel</span>
                                        </v-tooltip>
                                    </xlsx-download>
                                </xlsx-workbook>
                            </v-row>
                        </v-col>
                    </v-row>
                    <!-- TABLA DE INVENTARIO -->
                    <v-data-table :headers="headerInvenatario" :items="itemsIventario">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadInicial`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadInicial) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalInical`]="{ item }">
                            <span>{{ '$ '+functionFormatPrice(item.totalInical) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadCompras`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadCompras) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalCompras`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalCompras) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadVendida`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadVendida) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalVendida`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalVendida) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadRestante`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadRestante) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalRestante`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalRestante) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalMerma`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalMerma) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadMerma`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadMerma) }}</span>
                        </template>
                    </v-data-table>
                </v-card>
            </v-window-item>
        </v-window>

        <v-dialog persistent width="500" v-model="dialogSubirArchivos">
            <Archivos @close="cerrarVentanaArchivos()"></Archivos>
        </v-dialog>
        <span hidden>{{getData}}</span>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import moment from 'moment';
    import { format, parseISO } from 'date-fns';
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxRead, XlsxTable, XlsxJson, XlsxSheets, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import Archivos from '@/components/SubirArchivos/Archivos.vue'
    import XLSX from 'xlsx'

    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            HelloWorld,
            Archivos,
            XlsxRead,
            XlsxJson,
            XlsxSheets,
            XlsxTable,
        },
        data: () => ({

            file: null,
            //collection: null,
            selectedSheet: null,
            tempData: [],
            // ventana de archivos
            dialogSubirArchivos: false,
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
            SelectAño: '',
            SelectMes: '',
            itemsAño: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018',
            ],
            itemsMes: [
                { text: 'Enero', value: '01' },
                { text: 'Febrero', value: '02' },
                { text: 'Marzo', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Mayo', value: '05' },
                { text: 'Junio', value: '06' },
                { text: 'Julio', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Septiembre', value: '09' },
                { text: 'Octubre', value: '10' },
                { text: 'Noviembre', value: '11' },
                { text: 'Diciembre', value: '12' },
            ],
            step: 1,
            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
            //DIALOG
            dialog: false,
            dialogMessage: '',
            itemsVentas: [],
            itemsCompras: [],
            headerCompras: [
                { text: 'Descripción', value: 'descripcion' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Subtotal', value: 'subTotal', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IEPS', value: 'ieps', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headerVentas: [
                { text: 'Descripción', value: 'descripcion' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Subtotal', value: 'subTotal', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IEPS', value: 'ieps', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            headerDetalles: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'No. Venta', value: 'noVenta' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Importe', value: 'importe', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IEPS', value: 'ieps', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Método de Pago', value: 'metodoPago' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante' },
                { text: 'Folio Fiscal', value: 'folioFiscal' },
                { text: 'Versión', value: 'version' },
                { text: 'ClaveProductoServicio', value: 'claveProdServ' },
            ],
            editedItemConsulta: {
                año: '',
                mes: '',
            },
            itemsNoCre: [],
            SelectSucursal: {},
            //FECHAS
            dateBuscaI: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaF: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaI: false,
            menuDateBuscaF: false,

            //dialog detalle
            searchDetalle: '',
            itemsDetalles: [],
            dialogDetalle: false,
            titulo: '',

            itemsVentasPublicoGeneral: [],
            itemsVentasOtros: [],
            PGmagna: [],
            PGdisel: [],
            PGpremium: [],
            OtrosMagna: [],
            OtrosPremium: [],
            OtrosDisel: [],

            //PROVEEDORES
            dialogProveedores: false,
            itemsProveedores: [],
            headerProveedores: [
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
            ],

            //VENTAS POS
            itemsVentasPos: [],
            headersVentasPos: [
                { text: 'No Venta', value: 'noVenta' },
                { text: 'Producto', value: 'producto' },
                { text: 'Volúmen', value: 'volumen' },
                { text: 'Monto', value: 'monto' },
                { text: 'Precio Unitario', value: 'precioUnitario' },
                { text: 'Fecha Venta', value: 'fechaVenta' },
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Fecha Factura', value: 'fechaFactura' },
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Folio FIscal', value: 'folioFiscal' },
            ],
            searchVentasPos: '',
            headerReporte: [
                { text: 'Despacho', value: 'despacho', align: 'center' },
                { text: 'Factura', value: 'factura', align: 'right' },
                { text: 'Nombre Receptor', value: 'receptor', align: 'center' },
                { text: 'Producto', value: 'producto', align: 'center' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Precio', value: 'precio', align: 'right' },
                { text: 'Importe', value: 'importe', align: 'right' },
                { text: 'Fecha', value: 'fecha', align: 'center' },
                { text: 'Forma Pago', value: 'formaPago', align: 'center' },
                // { text: 'Hora', value: 'hora', align: 'center' },
                { text: 'Folio Fiscal', value: 'folioFiscal', align: 'center' },
                { text: 'Fecha de Facturación', value: 'fechaFactura', align: 'center' },
                // { text: 'Sucursal', value: 'noCre', align: 'center' },
            ],
            itemsReporte: [],
            headerReportexml: [
                { text: 'Producto', value: 'producto', align: 'center' },
                { text: 'RFC', value: 'rfcClienteOProveedor', align: 'center' },
                { text: 'Cliente', value: 'nombreClienteOProveedor', align: 'center' },
                { text: 'CFDI', value: 'cfdi', align: 'center' },
                { text: 'Tipo', value: 'tipoCFDI', align: 'center' },
                { text: 'Precio', value: 'precioDeVentaAlPublico', align: 'right' },
                { text: 'Cantidad', value: 'valorNumerico', align: 'right' },
                { text: 'Total', value: 'totalVenta', align: 'right' },
                { text: 'Fecha', value: 'fechaYHoraTransaccion', align: 'center' },
            ],
            itemsReportexml: [],
            itemsExcel: [],
            headerTotales: [
                { text: 'VENTAS  FACTURADAS SEGÚN EL POS, QUE NO TIENEN COMPROBANTE EN EL SAT', value: 'sumaPendiente', align: 'center' },
                { text: 'VENTAS FACTURADAS SEGÚN EL POS, CON FECHA FUERA DEL MES CON COMPROBANTE EN EL SAT', value: 'sumaFueraMes', align: 'center' },
                { text: 'VENTAS QUE NO ESTAN FACTURADAS EN EL POS Y SIN COMPROBANTES EN EL SAT', value: 'sumaSinFacturar', align: 'center' },
                { text: 'TOTAL VENTAS EXCEL', value: 'totalVentas', align: 'center' },
            ],
            itemsTotales: [],
            headerTotalesXML: [
                { text: 'Concepto', value: 'concepto', align: 'center' },
                { text: 'Magna', value: 'totalMagna', align: 'center' },
                { text: 'Premium', value: 'totalPremium', align: 'center' },
                { text: 'Diesel', value: 'totalDiesel', align: 'center' },
                { text: 'Venta', value: 'totalVentasXML', align: 'center' },
            ],
            headerTotalesVentas: [
                { text: 'Concepto', value: 'concepto', align: 'center' },
                { text: 'Venta Magna', value: 'ventasMagna', align: 'center' },
                { text: 'Venta Premium', value: 'ventasPremium', align: 'center' },
                { text: 'Venta Diesel', value: 'ventasDiesel', align: 'center' },
                { text: 'Venta', value: 'totalVenta', align: 'center' },
            ],
            headerTotalesFacturado: [
                { text: 'Concepto', value: 'concepto', align: 'center' },
                { text: 'Facturado Magna', value: 'facturadoMagna', align: 'center' },
                { text: 'Facturado Premium', value: 'facturadoPremium', align: 'center' },
                { text: 'Facturado Diesel', value: 'facturadoDiesel', align: 'center' },
                { text: 'Facturado', value: 'facturado', align: 'center' },
            ],
            itemsTotalesXML: [],
            itemsTotalesVenta: [],
            itemsTotalesFactura: [],

            totalVentas: 0,

            itemsFormaPago: [],
            headersFormaPago:
                [
                    { text: 'Producto', value: 'producto' },
                    { text: 'Forma de Pago', value: 'formaPago' },
                    { text: 'Cantidad', value: 'cantidad', align: 'center' },
                    { text: 'Total', value: 'importe', align: 'center' },
                ],

            // DIALOGO COMPROBANTES
            dialogComprobantes: false,
            itemsComprobantes: [],
            headerComprobantes: [
            { text: 'Item', value: 'item', align: 'center' },
                { text: 'Folio', value: 'folio', align: 'center' },
                { text: 'Fecha', value: 'fecha', align: 'center' },
                { text: 'Clave Producto/Servicio', value: 'claveProdServ', align: 'center' },
                { text: 'Descripción', value: 'descripcion', align: 'center' },
                { text: 'No. Venta', value: 'noVenta', align: 'center' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Importe', value: 'importe', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IEPS', value: 'ieps', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'RFC', value: 'rfc', align: 'center' },
                { text: 'Nombre', value: 'nombre', align: 'center' },
                { text: 'Método de Pago', value: 'metodoPago', align: 'center' },
                { text: 'Forma de Pago', value: 'formaPago', align: 'center' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante', align: 'center' },
                { text: 'Folio Fiscal', value: 'folioFiscal', align: 'center' },
                { text: 'Versión', value: 'version', align: 'center' },
            ],
            dateBuscaIC: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            dateBuscaFC: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaIC: false,
            menuDateBuscaFC: false,
            singleSelect: false,
            selected: [],
            itemsCompraSucursal: [],
            itemsComprasSucursal: [],
            headerComprasSucursal: [
                { text: 'Descripción', value: 'descripcion' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Subtotal', value: 'subTotal', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IEPS', value: 'ieps', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            dialogEditaComprobantes: false,
            itemsComprobantesEdita: [],
            headerComprobantesEdita: [
                { text: 'Folio', value: 'folio', align: 'center' },
                { text: 'Fecha', value: 'fecha', align: 'center' },
                { text: 'Clave Producto/Servicio', value: 'claveProdServ', align: 'center' },
                { text: 'Descripción', value: 'descripcion', align: 'center' },
                { text: 'No. Venta', value: 'noVenta', align: 'center' },
                { text: 'Cantidad', value: 'cantidad', align: 'right' },
                { text: 'Importe', value: 'importe', align: 'right' },
                { text: 'Descuento', value: 'descuento', align: 'right' },
                { text: 'IVA', value: 'iva', align: 'right' },
                { text: 'IEPS', value: 'ieps', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'RFC', value: 'rfc', align: 'center' },
                { text: 'Nombre', value: 'nombre', align: 'center' },
                { text: 'Método de Pago', value: 'metodoPago', align: 'center' },
                { text: 'Forma de Pago', value: 'formaPago', align: 'center' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante', align: 'center' },
                { text: 'Folio Fiscal', value: 'folioFiscal', align: 'center' },
                { text: 'Versión', value: 'version', align: 'center' },
            ],
            selectedEdit: [],
            dialogInformacion: false,
            // inventario
            dialogoInventario: false,
            itemsIventario: [],
            headerInvenatario: [
                { text: 'Producto', value: 'producto' },
                { text: 'Litros (Inicial)', value: 'cantidadInicial',align: 'right' },
                { text: 'Total (Inicial)', value: 'totalInical',align: 'right' },
                { text: 'Litros (Compras)', value: 'cantidadCompras',align: 'right' },
                { text: 'Total (Compras)', value: 'totalCompras',align: 'right' },
                { text: 'Litros (Vendidos)', value: 'cantidadVendida',align: 'right'},
                { text: 'Total (Vendidos)', value: 'totalVendida',align: 'right' },
                { text: 'Cantidad (Merma)', value: 'cantidadMerma',align: 'right' },
                { text: 'Total (Merma)', value: 'totalMerma',align: 'right' },
                { text: 'Cantidad (Restante)', value: 'cantidadRestante', align: 'right'},
                { text: 'Total (Restante)', value: 'totalRestante',align: 'right' },
            ],
            headerNuveoInventario: [
                { text: 'Producto', value: 'producto', align: 'center' },
                { text: 'Cantidad', value: 'cantidad', align: 'center' },
                { text: 'Valor Total', value: 'valor', align: 'center' },
            ],

            dateBuscaInventario: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
            menuDateBuscaInventario: false,
            inventario: {
                id: 0,
                dateBuscaInventario: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
                itemsNuevoInventario: [
                    {
                        producto: 'MAGNA',
                        cantidad: 0,
                        valor: 0,
                    },
                    {
                        producto: 'PREMIUM',
                        cantidad: 0,
                        valor: 0,
                    },
                    {
                        producto: 'DIESEL',
                        cantidad: 0,
                        valor: 0,
                    },
                ]
            },

            dialogoMerma: false,
            headerMerma: [
                { text: 'Producto', value: 'producto', align: 'center' },
                { text: 'Cantidad', value: 'cantidad', align: 'center' },
                { text: 'Valor Total', value: 'valor', align: 'center' },
            ],
            itemsMerma: [
                {
                    producto: 'MAGNA',
                    cantidad: 0,
                    valor: 0,
                },
                {
                    producto: 'PREMIUM',
                    cantidad: 0,
                    valor: 0,
                },
                {
                    producto: 'DIESEL',
                    cantidad: 0,
                    valor: 0,
                },
            ],
            itemsAño: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018',
            ],
            itemsMes: [
                { descripcion: 'Enero', valor: '01' },
                { descripcion: 'Febrero', valor: '02' },
                { descripcion: 'Marzo', valor: '03' },
                { descripcion: 'Abril', valor: '04' },
                { descripcion: 'Mayo', valor: '05' },
                { descripcion: 'Junio', valor: '06' },
                { descripcion: 'Julio', valor: '07' },
                { descripcion: 'Agosto', valor: '08' },
                { descripcion: 'Septiembre', valor: '09' },
                { descripcion: 'Octubre', valor: '10' },
                { descripcion: 'Noviembre', valor: '11' },
                { descripcion: 'Diciembre', valor: '12' },
            ],
            SelectSucursalMerma: {},
            mes: {},
            anio: {},
            actualizaM: 0,

            mesInventario: {},
            añoInventario: {},
            formaDeConcultarInventario: 1,
            dialogoSubirArchivos: false,
            archivo: false,

            SelectSucursalArchivoInventario:{},
            añoInventarioArchivoInventario: {},
            itemsTotalesExcel:[],
            headerTotalesExcel:[
            { text: 'Año', value: 'año', align: 'center' },
            { text: 'Mes', value: 'mes', align: 'center' },
            { text: 'Magna (Litros)', value: 'magnaLitros', align: 'right' },
            { text: 'Magna (Total)', value: 'magnaTotal', align: 'right' },
            { text: 'Premium (Litros)', value: 'premiumLitros', align: 'right' },
            { text: 'Premium (Total)', value: 'premiumTotal', align: 'right' },
            { text: 'Diesel (Litros)', value: 'dieselLitros', align: 'right' },
            { text: 'Diesel (Total)', value: 'dieselTotal', align: 'right' },
            { text: 'NoCre', value: 'noCre', align: 'right' },
            { text: 'Actions', value: 'actions', align: 'center'},
            ],

        }),
        computed: {
            computedDateFormattedMomentjsInventario() {
                return this.dateBuscaInventario ? moment(this.dateBuscaInventario).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsI() {
                return this.dateBuscaI ? moment(this.dateBuscaI).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsF() {
                return this.dateBuscaF ? moment(this.dateBuscaF).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsIC() {
                return this.dateBuscaIC ? moment(this.dateBuscaIC).format('DD/MM/yyyy') : ''
            },
            computedDateFormattedMomentjsFC() {
                return this.dateBuscaFC ? moment(this.dateBuscaFC).format('DD/MM/yyyy') : ''
            },
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            getData() {
                return this.collection
            },
        },
        created() {
            this.GetNoCre();
        },

        methods: {
            onChange(event) {
                console.log(event)
                this.file = event ? event : null;
                // this.file = event.target.files ? event.target.files[0] : null;
                console.log(this.file)
            },

            ObtenerDatosExcel(collection) {
                this.itemsExcel = []
                console.log('colleccion',collection)
                this.itemsExcel = collection

                //PARA ELIMIAR EL ULTIMO ELEMENTO EN CASO DE QUE TENGA LA FILA TOTALES
                let ultimo = this.itemsExcel[this.itemsExcel.length - 1]
                if (ultimo.Fecha == 'TOTALES' || ultimo.Fecha == 'totales' || ultimo.Fecha == 'Totales' || ultimo.Fecha == 'TOTAL' || ultimo.Fecha == 'Total' || ultimo.Fecha == 'total') {
                    this.itemsExcel.pop();
                }
                this.formatoFechaHora();
                return collection
            },
            formatoFechaHora() {
                this.itemsReporte = []
                for (let fila of this.itemsExcel) {
                    let fechas = new Date(fila.Fecha).getTime()
                    // console.log(typeof fila.Fecha)
                    let tipoFecha = typeof fila.Fecha
                    let fechaBien = ''
                    if(tipoFecha == 'string'){
                         fechaBien = new Date(fechas).toLocaleDateString()
                    }
                    if(tipoFecha == 'number'){
                         fechaBien =  format(parseISO(this.numeroAFecha(fila.Fecha, true).toISOString()), 'yyyy-MM-dd')
                    }

                    let objeto = {
                        fecha : fechaBien,
                        // hora: this.decimalAHora((fila.Hora * 24)),
                        //despacho: fila.Despacho.split("-", 1),
                        //despacho: fila.Despacho,
                        producto: this.funcionParaProductos(fila.Producto.trim().toUpperCase()),
                        cantidad: fila.Cantidad,
                        precio: fila.Precio,
                        importe: fila.Importe,
                        factura: fila.Factura,
                        receptor: fila.Cliente,
                        formaPago: fila.FormaPago,
                        // despacho: fila.Despacho.substr(0, 7),
                        // noCre : fila.Sucursal,
                        despacho: this.funcionParaDespachos(fila.Despacho),
                    }
                    this.itemsReporte.push(objeto)

                }
                this.itemsReporte = this.itemsReporte.filter(x => x.producto == "MAGNA" || x.producto == "PREMIUM" || x.producto == "DIESEL" || x.producto == "REGULAR")
                this.totalVentas = this.sum(this.itemsReporte, 'importe'),
                    console.log(this.itemsReporte)

            },
             numeroAFecha(numeroDeDias, esExcel = false) {
                var diasDesde1900 = esExcel ? 25567 + 1 : 25567;

                return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
                },
            funcionParaDespachos(value) {
                let tipoDespacho = typeof value

                if (tipoDespacho == 'string')
                {
                    // let indice = value.indexOf("-")
                    // if(indice == 6){
                    //     return value.substring(0, indice);
                    // } 
                    
                    let arr = value.split('-');
                    return arr[0];
                }
                
                if (value.length > 7) {
                    return value.substr(0, 7)
                } 
                
                    return value

               
                
            },
            funcionParaProductos(value) {
                if (value == 'REGULAR') {
                    return 'MAGNA'
                } else {
                    return value
                }

            },
            async comparativaReporte() {
                if (this.itemsReporte == '') {
                    this.snackbarMessage = 'Cargue un documento, para comparar...'
                    this.snackbar = true
                    return
                }

                if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                    this.snackbarMessage = 'Seleccione una sucursal'
                    this.snackbar = true
                    return
                }
                console.log(this.SelectSucursal)
                this.dialog = true;
                this.dialogMessage = 'Consultando ventas...'

                this.itemsTotales = []
                this.itemsTotalesFactura = []
                this.itemsTotalesVenta = []

                let lista = this.itemsReporte
                console.log(lista)
                let noCre = this.SelectSucursal.noCre.replaceAll('/', '-')
                console.log(noCre)
                try {
                    let response = await axios.post('Gasolinas/GetVentasPorMes/' + this.datosEmpresa.empresaBase + '/' + noCre, lista)
                    console.log(response.data)
                    this.itemsReporte = response.data.objetoReporte

                    let objeto = {
                        sumaSinFacturar: response.data.sumaSinFacturar,
                        sumaFueraMes: response.data.sumaFueraMes,
                        sumaPendiente: response.data.sumaPendiente,
                        totalVentas: this.totalVentas
                    }
                    let litrosVendidos = {
                        concepto: 'LITROS:',
                        ventasMagna: response.data.litrosMagnaVendidos,
                        ventasDiesel: response.data.litrosDieselVendidos,
                        ventasPremium: response.data.litrosPremiumVendidos,
                        totalVenta: response.data.litrosVendidos,
                    }
                    this.itemsTotalesVenta.push(litrosVendidos)

                    let objeto1 = {
                        concepto: 'TOTAL:',
                        ventasMagna: response.data.ventasMagna,
                        ventasDiesel: response.data.ventasDiesel,
                        ventasPremium: response.data.ventasPremium,
                        totalVenta: response.data.totalVenta,
                    }
                    let litrosFacturados = {
                        concepto: 'LITROS:',
                        facturadoDiesel: response.data.litrosDieselFacturados,
                        facturadoPremium: response.data.litrosPremiumFacturados,
                        facturadoMagna: response.data.litrosMagnaFacturados,
                        facturado: response.data.litrosFacturados
                    }
                    this.itemsTotalesFactura.push(litrosFacturados)

                    let objeto2 = {
                        concepto: 'TOTAL:',
                        facturadoDiesel: response.data.facturadoDiesel,
                        facturadoPremium: response.data.facturadoPremium,
                        facturadoMagna: response.data.facturadoMagna,
                        facturado: response.data.facturado
                    }

                    this.itemsTotales.push(objeto)
                    this.itemsTotalesVenta.push(objeto1)
                    this.itemsTotalesFactura.push(objeto2)
                    this.dialog = false;

                } catch (err) {
                    console.log(err)
                    this.dialog = false;
                }

                console.log(this.itemsTotalesVenta)
            },

            decimalAHora(decimal) {
                let horas = Math.floor(decimal), // Obtenemos la parte entera
                    restoHoras = Math.floor(decimal % 1 * 100), // Obtenemos la parde decimal
                    decimalMinutos = restoHoras * 60 / 100, // Obtenemos los minutos expresado en decimal

                    minutos = Math.floor(decimalMinutos), // Obtenemos la parte entera
                    restoMins = Math.floor(decimalMinutos % 1 * 100), // Obtenemos la parde decimal
                    segundos = Math.floor(restoMins * 60 / 100); // Obtenemos los segundos expresado en entero

                return `${('00' + horas).slice(-2)}:${('00' + minutos).slice(-2)}:${('00' + segundos).slice(-2)}`;
            },

            async GetNoCre() {
                try {
                    let response = await axios.get('Gasolinas/GetNoCre/' + this.datosEmpresa.empresaBase)
                    this.itemsNoCre = response.data
                    console.log(this.itemsNoCre)
                } catch (err) {
                    console.log(err)
                }
            },
            numeroAFecha(numeroDeDias, esExcel = false) {
                var diasDesde1900 = esExcel ? 25567 + 1 : 25567;

                // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
                return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
            },
            async ConsultarCompras() {
                this.itemsCompras = []

                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                try {
                    let response = await axios.get('Gasolinas/GetComprasGasolina/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsCompras.push(response.data.magna)
                    this.itemsCompras.push(response.data.diesel)
                    this.itemsCompras.push(response.data.premium)

                    // cocatenar las tres listas
                    let lista1 = response.data.magna.detalles.concat(response.data.diesel.detalles)
                    let lista2 = lista1.concat(response.data.premium.detalles)

                    console.log(lista2)
                    let compras = {
                        descripcion: 'TOTAL:',
                        cantidad: this.sum(this.itemsCompras, 'cantidad'),
                        subTotal: this.sum(this.itemsCompras, 'subTotal'),
                        descuento: this.sum(this.itemsCompras, 'descuento'),
                        iva: this.sum(this.itemsCompras, 'iva'),
                        ieps: this.sum(this.itemsCompras, 'ieps'),
                        total: this.sum(this.itemsCompras, 'total'),
                        detalles: lista2
                    }
                    this.itemsCompras.push(compras);
                    console.log(response.data)


                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },
            async ConsultarVentas() {
                this.itemsVentas = []
                this.itemsVentasPublicoGeneral = []
                this.itemsVentasOtros = []

                if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                    this.snackbarMessage = 'Seleccione una sucursal'
                    this.snackbar = true
                    return
                }

                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                let noCre = Object.assign({}, this.SelectSucursal);
                console.log(noCre)

                try {
                    let response = await axios.post('Gasolinas/GetVentasGasolina/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaI + '/' + this.dateBuscaF, noCre);
                    console.log(response.data)

                    this.itemsVentas.push(response.data.magna)
                    this.itemsVentas.push(response.data.diesel)
                    this.itemsVentas.push(response.data.premium)

                    // COCATENAMOS LAS LISTAS PARA OBTNER EL CONSOLIDADO
                    // cocatenar las tres listas
                    let lista1 = response.data.magna.detalles.concat(response.data.diesel.detalles)
                    let lista2 = lista1.concat(response.data.premium.detalles)


                    // OBTENGO LOS FILTROS Y LISTAS DE CADA PRODUCTO 
                    this.PGmagna = response.data.magna.detalles.filter(x => x.rfc == "XAXX010101000")
                    this.PGdisel = response.data.diesel.detalles.filter(x => x.rfc == "XAXX010101000")
                    this.PGpremium = response.data.premium.detalles.filter(x => x.rfc == "XAXX010101000")
                    this.OtrosMagna = response.data.magna.detalles.filter(x => x.rfc !== "XAXX010101000")
                    this.OtrosDisel = response.data.diesel.detalles.filter(x => x.rfc !== "XAXX010101000")
                    this.OtrosPremium = response.data.premium.detalles.filter(x => x.rfc !== "XAXX010101000")

                    let ventas = {
                        descripcion: 'TOTAL:',
                        cantidad: this.sum(this.itemsVentas, 'cantidad'),
                        subTotal: this.sum(this.itemsVentas, 'subTotal'),
                        descuento: this.sum(this.PGmagna, 'descuento'),
                        iva: this.sum(this.itemsVentas, 'iva'),
                        ieps: this.sum(this.itemsVentas, 'ieps'),
                        total: this.sum(this.itemsVentas, 'total'),
                        detalles: lista2

                    }
                    this.itemsVentas.push(ventas);

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
                this.ConsultarVentasGeneral();
                this.ConsultarVentasOtros();

            },
            async GetProveedores() {
                this.dialogProveedores = true;
                this.titulo = 'Proveedores'
                try {
                    let response = await axios.get('Gasolinas/GetProveedores/' + this.datosEmpresa.empresaBase);
                    console.log(response.data)
                    this.itemsProveedores = response.data
                } catch (error) {
                    console.log(error)
                }
            },
            async GetVentasPos() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                let noCre = Object.assign({}, this.SelectSucursal);

                try {
                    let response = await axios.post('Gasolinas/GetVentasPos/' + this.datosEmpresa.empresaBase + '/' + this.dateBuscaI + '/' + this.dateBuscaF, noCre);
                    // console.log(response.data)
                    this.itemsVentasPos = response.data;
                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },
            ConsultarVentasGeneral() {
                let magna = {
                    descripcion: 'MAGNA:',
                    cantidad: this.sum(this.PGmagna, 'cantidad'),
                    subTotal: this.sum(this.PGmagna, 'importe'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.PGmagna, 'iva'),
                    ieps: this.sum(this.PGmagna, 'ieps'),
                    total: this.sum(this.PGmagna, 'total'),
                    detalles: this.PGmagna
                }
                let disel = {
                    descripcion: 'DIESEL:',
                    cantidad: this.sum(this.PGdisel, 'cantidad'),
                    subTotal: this.sum(this.PGdisel, 'importe'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.PGdisel, 'iva'),
                    ieps: this.sum(this.PGdisel, 'ieps'),
                    total: this.sum(this.PGdisel, 'total'),
                    detalles: this.PGdisel
                }
                let premium = {
                    descripcion: 'PREMIUM:',
                    cantidad: this.sum(this.PGpremium, 'cantidad'),
                    subTotal: this.sum(this.PGpremium, 'importe'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.PGpremium, 'iva'),
                    ieps: this.sum(this.PGpremium, 'ieps'),
                    total: this.sum(this.PGpremium, 'total'),
                    detalles: this.PGpremium
                }
                this.itemsVentasPublicoGeneral.push(magna);
                this.itemsVentasPublicoGeneral.push(disel);
                this.itemsVentasPublicoGeneral.push(premium);

                let ventas = {
                    descripcion: 'TOTAL:',
                    cantidad: this.sum(this.itemsVentasPublicoGeneral, 'cantidad'),
                    subTotal: this.sum(this.itemsVentasPublicoGeneral, 'subTotal'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.itemsVentasPublicoGeneral, 'iva'),
                    ieps: this.sum(this.itemsVentasPublicoGeneral, 'ieps'),
                    total: this.sum(this.itemsVentasPublicoGeneral, 'total'),
                }
                this.itemsVentasPublicoGeneral.push(ventas);
            },
            ConsultarVentasOtros() {
                let magna = {
                    descripcion: 'MAGNA',
                    cantidad: this.sum(this.OtrosMagna, 'cantidad'),
                    subTotal: this.sum(this.OtrosMagna, 'importe'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.OtrosMagna, 'iva'),
                    ieps: this.sum(this.OtrosMagna, 'ieps'),
                    total: this.sum(this.OtrosMagna, 'total'),
                    detalles: this.OtrosMagna
                }
                let disel = {
                    descripcion: 'DIESEL',
                    cantidad: this.sum(this.OtrosDisel, 'cantidad'),
                    subTotal: this.sum(this.OtrosDisel, 'importe'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.OtrosDisel, 'iva'),
                    ieps: this.sum(this.OtrosDisel, 'ieps'),
                    total: this.sum(this.OtrosDisel, 'total'),
                    detalles: this.OtrosDisel
                }
                let premium = {
                    descripcion: 'PREMIUM',
                    cantidad: this.sum(this.OtrosPremium, 'cantidad'),
                    subTotal: this.sum(this.OtrosPremium, 'importe'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.OtrosPremium, 'iva'),
                    ieps: this.sum(this.OtrosPremium, 'ieps'),
                    total: this.sum(this.OtrosPremium, 'total'),
                    detalles: this.OtrosPremium
                }
                this.itemsVentasOtros.push(magna);
                this.itemsVentasOtros.push(disel);
                this.itemsVentasOtros.push(premium);

                let ventas = {
                    descripcion: 'TOTAL:',
                    cantidad: this.sum(this.itemsVentasOtros, 'cantidad'),
                    subTotal: this.sum(this.itemsVentasOtros, 'subTotal'),
                    descuento: this.sum(this.PGmagna, 'descuento'),
                    iva: this.sum(this.itemsVentasOtros, 'iva'),
                    ieps: this.sum(this.itemsVentasOtros, 'ieps'),
                    total: this.sum(this.itemsVentasOtros, 'total'),
                }
                this.itemsVentasOtros.push(ventas);
            },
            detallesCompras(item, value) {
                this.itemsDetalles = []
                console.log(item)
                this.itemsDetalles = item.detalles
                this.$store.state.datosExcel.titulo = value
                this.$store.state.listaExportarExcel = item.detalles
                this.titulo = value
                this.dialogDetalle = true
            },
            detallesVentas(item, value) {
                this.itemsDetalles = []
                console.log(item)
                this.$store.state.datosExcel.titulo = value
                this.itemsDetalles = item.detalles
                this.$store.state.listaExportarExcel = item.detalles
                this.titulo = value
                this.dialogDetalle = true
            },
            GeneraExcelReporte() {
                this.nombreReporte = 'ComparativoReporteMensual.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsReporte
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelReporteXML() {
                this.nombreReporte = 'ReporteXML.xlsx'
                let listaExcel = [];
                listaExcel = this.$store.state.listaVentasXML
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcel() {
                this.nombreReporte = this.$store.state.datosExcel.titulo + '.xlsx'
                let listaExcel = [];
                listaExcel = this.$store.state.listaExportarExcel
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            GeneraExcelCompras() {
                this.nombreReporte = 'ComprasGasolina.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsCompras
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            GeneraExcelComprasPorSucursal() {
                this.nombreReporte = 'ComprasPorSucursal.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsComprasSucursal
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            GenerarExcelVentas() { 
                this.nombreReporte = 'VentasGasolina.xlsx'
                let listaExcel = [];
                let lista1 = []
                let lista2 = []
                let lista3 = []
                let lista22 = []
                let lista33 = []
                let lista2y3 = []
                let listaCompleta= []
                lista1=this.itemsVentas
                // lista2=this.itemsVentasPublicoGeneral
                // lista3=this.itemsVentasOtros

                let objeVacio = {
                    descripcion:'',
                    cantidad:'',
                    subTotal:'',
                    descuento:'',
                    ieps:'',
                    total:'',
                    detalles:''            
                }

                let objePG = {
                    descripcion:'Público en General',
                    cantidad:'',
                    subTotal:'',
                    descuento:'',
                    iva:'',
                    ieps:'',
                    total:'',
                    detalles:''
                }
               
                let objeOtros = {
                    descripcion:'Otros', 
                    cantidad:'',
                    subTotal:'',
                    descuento:'',
                    iva:'',
                    ieps:'',
                    total:'',
                    detalles:''
                }
                lista2.push(objeVacio)
                lista2.push(objeVacio)
                lista2.push(objeVacio)
                lista2.push(objePG)
                lista22=lista2.concat(this.itemsVentasPublicoGeneral)

                lista3.push(objeVacio)
                lista3.push(objeVacio)
                lista3.push(objeVacio)
                lista3.push(objeOtros)
                lista33=lista3.concat(this.itemsVentasOtros)

                lista2y3 = lista22.concat(lista33)
                listaCompleta = lista1.concat(lista2y3)

                listaExcel = listaCompleta
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },
            VentanaSubirArchivos() {
                this.dialogSubirArchivos = true
            },
            cerrarVentanaArchivos() {
                this.dialogSubirArchivos = false
            },

            separaFormaPago() {
                console.log('Vamos a separar')
                this.itemsFormaPago = [];
                var resultado = [];
                const magna = this.itemsReporte.filter(x => x.producto === 'MAGNA');
                const premium = this.itemsReporte.filter(x => x.producto === 'PREMIUM');
                const diesel = this.itemsReporte.filter(x => x.producto === 'DIESEL');

                const agrupadoMagna = this.agrupaFormaPago(magna, 'formaPago');
                const agrupadoPremium = this.agrupaFormaPago(premium, 'formaPago');
                const agrupadoDiesel = this.agrupaFormaPago(diesel, 'formaPago');

                for (const prop in agrupadoMagna) {
                    let ret = Object.assign({}, agrupadoMagna[prop])
                    resultado.push(ret)
                }

                for (const prop in agrupadoPremium) {
                    let ret = Object.assign({}, agrupadoPremium[prop])
                    resultado.push(ret)
                }

                for (const prop in agrupadoDiesel) {
                    let ret = Object.assign({}, agrupadoDiesel[prop])
                    resultado.push(ret)
                }

                let cantidad = this.sum(resultado, 'cantidad');
                let total = this.sum(resultado, 'importe')
                let totales = {
                    producto: 'TOTAL:',
                    formaPago: '---',
                    cantidad: cantidad,
                    importe: total
                }
                resultado.push(totales);
                this.itemsFormaPago = resultado;
            },

            agrupaFormaPago(miArray, propA) {
                return miArray.reduce(function (groups, item) {
                    var val = item[propA];
                    groups[val] = groups[val] || { producto: item.producto, formaPago: item.formaPago, cantidad: 0, importe: 0 };
                    groups[val].cantidad += item.cantidad;
                    groups[val].importe += item.importe;
                    return groups;
                }, {});
            },
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            functionFormatDate(value) {
                let val = value;
                return moment(val).format('DD/MM/yyyy')
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },

            row_classes(item) {
                if (item.mes == "TOTAL:") {
                    return "green lighten-5";
                }
                if (item.producto == "TOTAL:") {
                    return "green lighten-5";
                }
            },
            row_classes_totales(item) {
                return "green lighten-5";
            },

            // ASIGNAR COMPROBANTES
            verAsignaComprobante(value) {
                this.titulo = value
                this.dialogComprobantes = true
            },

            async getComprasComprobantes() {

                this.dialog = true;
                this.dialogMessage = 'Consultando...'

                // console.log("entro :v")

                try {
                    let response = await axios.get('Gasolinas/GetCompras/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.dateBuscaIC + '/' + this.dateBuscaFC);
                    console.log(response.data)
                    this.itemsComprobantes = response.data

                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                    this.dialog = false;
                }
                this.dialog = false;
                // this.ConsultarVentasGeneral();
                // this.ConsultarVentasOtros();

            },

            async PostComprasSucursal() {
                if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                    this.snackbarMessage = 'Seleccione una sucursal'
                    this.snackbar = true
                    return
                }

                this.dialog = true;
                this.dialogMessage = 'Guardando...'
                // console.log(this.selected)

                for (let c of this.selected) {
                    let objeto = {}
                    objeto.noCre = this.SelectSucursal.noCre
                    objeto.folioFiscal = c.folioFiscal
                    this.itemsCompraSucursal.push(objeto)
                }
                // console.log(this.itemsCompraSucursal)
                try {
                    let response = await axios.post('Gasolinas/PostComprasSucursal/' + this.datosEmpresa.empresaBase, this.itemsCompraSucursal);
                    this.selected = [];
                } catch (error) {
                    this.snackbarMessage = 'Error al guardar'
                    this.snackbar = true
                    this.dialog = false;
                }
                this.itemsComprobantes = [];
                this.dialogComprobantes = false
                this.dialog = false;
            },

            async GetReporteComprasSucursal() {

                this.itemsComprasSucursal = [];

                if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                    this.snackbarMessage = 'Seleccione una sucursal'
                    this.snackbar = true
                    return
                }

                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                let noCre = this.SelectSucursal.noCre.replaceAll('/', '-')
                try {
                    let response = await axios.get('Gasolinas/GetReporteComprasSucursal/' + this.datosEmpresa.empresaBase + '/' + noCre + '/' + this.dateBuscaI + '/' + this.dateBuscaF);
                    this.itemsComprasSucursal.push(response.data.magna)
                    this.itemsComprasSucursal.push(response.data.diesel)
                    this.itemsComprasSucursal.push(response.data.premium)

                    // cocatenar las tres listas
                    let lista1 = response.data.magna.detalles.concat(response.data.diesel.detalles)
                    let lista2 = lista1.concat(response.data.premium.detalles)

                    console.log(lista2)
                    let compras = {
                        descripcion: 'TOTAL:',
                        cantidad: this.sum(this.itemsComprasSucursal, 'cantidad'),
                        subTotal: this.sum(this.itemsComprasSucursal, 'subTotal'),
                        descuento: this.sum(this.itemsComprasSucursal, 'descuento'),
                        iva: this.sum(this.itemsComprasSucursal, 'iva'),
                        ieps: this.sum(this.itemsComprasSucursal, 'ieps'),
                        total: this.sum(this.itemsComprasSucursal, 'total'),
                        detalles: lista2
                    }
                    this.itemsComprasSucursal.push(compras)

                } catch (error) {
                    this.snackbarMessage = 'Error al guardar'
                    this.snackbar = true
                    this.dialog = false;
                }
                this.dialog = false;

            },

            verEditaComprobante(item, value) {

                this.itemsComprobantesEdita = []
                this.itemsComprobantesEdita = item.detalles

                this.titulo = value
                this.dialogEditaComprobantes = true
            },

            async BorraComprobantes() {
                this.dialog = true;
                this.dialogMessage = 'Eliminando...'

                for (let c of this.selectedEdit) {
                    let objeto = {}
                    objeto.noCre = this.SelectSucursal.noCre
                    objeto.folioFiscal = c.folioFiscal
                    this.itemsCompraSucursal.push(objeto)
                }
                try {
                    let response = await axios.post('Gasolinas/BorraComprobantes/' + this.datosEmpresa.empresaBase, this.itemsCompraSucursal);
                } catch (error) {
                    this.snackbarMessage = 'Error al eliminar'
                    this.snackbar = true
                    this.dialog = false;
                }
                this.dialogEditaComprobantes = false
                this.dialog = false;
                this.GetReporteComprasSucursal();
            },

            nuevoInventario() {
                this.dialogoInventario = true
            },

            guardarOeditar() {
                if (this.inventario.id == 0) {
                    console.log('guardar')
                    this.guardarInventario();
                }

                if (this.inventario.id == 1) {
                    console.log('editar')
                    this.actualizarInventario();
                }
                console.log(this.inventario)
            },

            async guardarInventario() {
                this.dialog = true;
                this.dialogMessage = 'Guardando...'

                console.log(this.inventario)
                try {
                    let response = await axios.post('Gasolinas/PostInventario/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc, this.inventario);
                    this.dialog = false;
                } catch (error) {
                    this.snackbarMessage = 'Error al eliminar'
                    this.snackbar = true
                    this.dialog = false;
                }
            },
            async actualizarInventario() {
                this.dialog = true;
                this.dialogMessage = 'Guardando...'

                console.log(this.inventario)
                try {
                    let response = await axios.post('Gasolinas/UpdateInventario/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc, this.inventario);
                    this.dialog = false;
                } catch (error) {
                    this.snackbarMessage = 'Error al guardar'
                    this.snackbar = true
                    this.dialog = false;
                }
            },

            guardaOActualizaM(){
                if (this.actualizaM == 0){
                    this.guardarMerma();
                } else {
                    this.actualizaMerma();
                }
            },

            async guardarMerma() {
                this.dialog = true;
                this.dialogMessage = 'Guardando...'
                let fecha = this.anio + '-' + this.mes.valor + '-01';
                let noCre = this.SelectSucursalMerma.noCre.replaceAll('/', '-')

                // console.log(this.itemsMerma)
                try {
                    let response = await axios.post('Gasolinas/PostMerma/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha + '/' + noCre, this.itemsMerma);
                    this.dialog = false;
                } catch (error) {
                    this.snackbarMessage = 'Error al guardar'
                    this.snackbar = true
                    this.dialog = false;
                }
            },

            async actualizaMerma() {
                this.dialog = true;
                this.dialogMessage = 'Guardando...'

                console.log(this.inventario)
                try {
                    let response = await axios.post('Gasolinas/actualizaMerma/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc, this.itemsMerma);
                    this.dialog = false;
                } catch (error) {
                    this.snackbarMessage = 'Error al guardar'
                    this.snackbar = true
                    this.dialog = false;
                }
            },

            agregaMerma() {
                this.dialogoMerma = true
            },

            async GetInventario() {
                let noCre = this.inventario.SelectSucursal.noCre.replaceAll('/', '-')
                try {
                    let response = await axios.get('Gasolinas/GetInventario/' + this.datosEmpresa.empresaBase + '/' + noCre);
                    console.log(response.data)
                    if (response.data.id != 0) {
                        this.inventario.itemsNuevoInventario = response.data.itemsNuevoInventario
                        this.inventario.id = response.data.id
                    } else {
                        this.inventario.id = 0
                        this.inventario.itemsNuevoInventario = [
                            { producto: 'MAGNA', cantidad: 0, valor: 0, },
                            { producto: 'PREMIUM', cantidad: 0, valor: 0, },
                            { producto: 'DIESEL', cantidad: 0, valor: 0, },
                        ]
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async GetInventarioSucursal() {
                let objetoListasMes = {}

                if (this.formaDeConcultarInventario == 1) {
                    console.log('por comprobantes')
                    this.itemsIventario = []

                    if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                        this.snackbarMessage = 'Seleccione una sucursal'
                        this.snackbar = true
                        return
                    }

                    this.dialog = true;
                    this.dialogMessage = 'Consultando...'

                    let noCre = Object.assign({}, this.SelectSucursal);
                    let fechaInicial = this.añoInventario + '-01' + '-01'
                    let fechaFinal = this.añoInventario + '-' + this.mesInventario.valor + '-01'
                    try {
                        let response = await axios.post('Gasolinas/GetInventarioSucursal/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/' + fechaFinal, noCre);
                        console.log(response.data)
                        this.itemsIventario = response.data
                    } catch (error) {
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }else{
                    console.log('por reporte excel')

                    if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                        this.snackbarMessage = 'Seleccione una sucursal'
                        this.snackbar = true
                        return
                    }
                    // OBTNENOS LOS DATOS DE LOS ARCHIVOS
                    await this.getDatosParaCalcularInventario();

                    if (this.itemsTotalesExcel == '' || !this.itemsTotalesExcel) {
                        this.snackbarMessage = 'Para consultar su invetario, debera primero cargar en el botón amarillo "Subir archivos" y cargar los reportes.'
                        this.snackbar = true
                        return
                    }


                    if (this.mesInventario.descripcion != 'Enero'){
                    let lista = []
                    let lista2 = []
                    
                    // SEPARAMOS LOS MESES ANTERIORES
                    const meseseAnteriores = this.itemsTotalesExcel.filter(x => x.mes != this.mesInventario.descripcion);
                    console.log('A',meseseAnteriores)
                    // OBTENEMOS EL MES QUE SE VA A CONSULTAR
                    const mesCorriente = this.itemsTotalesExcel.filter(x => x.mes === this.mesInventario.descripcion);
                    console.log('C',mesCorriente)
                    
                    // CREAMOS LA SUMATORIA PARA CADA PRODUCTO
                    let objeto1 = {
                        producto : 'MAGNA',
                        cantidadVendida :this.sum(meseseAnteriores,'magnaLitros'),
                        totalVendida : this.sum(meseseAnteriores,'magnaTotal') 
                    }

                    let objeto2 = {
                        producto : 'PREMIUM',
                        cantidadVendida : this.sum(meseseAnteriores,'premiumLitros'),
                        totalVendida :this.sum(meseseAnteriores,'premiumTotal'),
                    }

                    let objeto3 = {
                        producto : 'DIESEL',
                        cantidadVendida :this.sum(meseseAnteriores,'dieselLitros'),
                        totalVendida :this.sum(meseseAnteriores,'dieselTotal'),
                    }
                    lista.push(objeto1)
                    lista.push(objeto2)
                    lista.push(objeto3)

                    let objeto11 = {
                        producto : 'MAGNA',
                        cantidadVendida :this.sum(mesCorriente,'magnaLitros'),
                        totalVendida : this.sum(mesCorriente,'magnaTotal') 
                    }

                    let objeto22 = {
                        producto : 'PREMIUM',
                        cantidadVendida : this.sum(mesCorriente,'premiumLitros'),
                        totalVendida :this.sum(mesCorriente,'premiumTotal'),
                    }

                    let objeto33 = {
                        producto : 'DIESEL',
                        cantidadVendida :this.sum(mesCorriente,'dieselLitros'),
                        totalVendida :this.sum(mesCorriente,'dieselTotal'),
                    }
                    lista2.push(objeto11)
                    lista2.push(objeto22)
                    lista2.push(objeto33)

                      objetoListasMes = {
                        ListaMesesAnteriores : lista,
                        ListaMesCorriente : lista2
                    }
                    }
                    else{

                    let lista = []

                    // CREAMOS LA SUMATORIA PARA CADA PRODUCTO
                    let objeto1 = {
                        producto : 'MAGNA',
                        cantidadVendida :this.sum(this.itemsTotalesExcel,'magnaLitros'),
                        totalVendida : this.sum(this.itemsTotalesExcel,'magnaTotal') 
                    }

                    let objeto2 = {
                        producto : 'PREMIUM',
                        cantidadVendida : this.sum(this.itemsTotalesExcel,'premiumLitros'),
                        totalVendida :this.sum(this.itemsTotalesExcel,'premiumTotal'),
                    }

                    let objeto3 = {
                        producto : 'DIESEL',
                        cantidadVendida :this.sum(this.itemsTotalesExcel,'dieselLitros'),
                        totalVendida :this.sum(this.itemsTotalesExcel,'dieselTotal'),
                    }
                    lista.push(objeto1)
                    lista.push(objeto2)
                    lista.push(objeto3)

                      objetoListasMes = {
                        ListaMesesAnteriores : lista,
                        ListaMesCorriente : []
                    }

                    }
                    console.log(objetoListasMes)
                    let noCre = this.SelectSucursal.noCre.replaceAll('/', '-')
                    let fechaInicial = this.añoInventario + '-01' + '-01'
                    let fechaFinal = this.añoInventario + '-' + this.mesInventario.valor + '-01'

                    this.dialog = true;
                    this.dialogMessage = 'Consultando...'

                    try {
                        let response = await axios.post('Gasolinas/GetInventarioSucursalXreporteEspecial/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/' + fechaFinal + '/' + noCre, objetoListasMes);
                        console.log(response.data)
                        this.itemsIventario = response.data
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }
            },

            generarExcelInventario(){
                let listaExcel = [];

                for(let a of this.itemsIventario){
                    let objeto ={
                        Producto:a.producto,
                        LitrosInicial : a.cantidadInicial,
                        TotalInicial : a.totalInical,
                        LitrosCompras : a.cantidadCompras,
                        TotalCompras : a.totalCompras,
                        LitrosVendidos : a.cantidadVendida,
                        TotalVendidos : a.totalVendida,
                        CantidadMerma : a.cantidadMerma,
                        TotalMerma : a.totalMerma,
                        CantidadRestante: a.cantidadRestante,
                        TotalRestante: a.totalRestante
                    }
                    listaExcel.push(objeto)
                }
                this.nombreReporte = 'Inventario_'+this.añoInventario+'_'+this.mesInventario.descripcion+'_' + this.SelectSucursal.noCre + '.xlsx'
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            async GetMerma() {
                let fecha = this.anio + '-' + this.mes.valor + '-01';
                let noCre = this.SelectSucursalMerma.noCre.replaceAll('/', '-')

                try {
                    let response = await axios.get('Gasolinas/GetMerma/' + this.datosEmpresa.empresaBase + '/' + noCre + '/' + fecha);
                    console.log(response.data)
                    this.itemsMerma = response.data.itemsMerma

                    if (this.itemsMerma == ''){
                        this.actualizaM = 0
                        this.itemsMerma = [
                            { producto: 'MAGNA', cantidad: 0, valor: 0, },
                            { producto: 'PREMIUM', cantidad: 0, valor: 0, },
                            { producto: 'DIESEL', cantidad: 0, valor: 0, },
                        ]
                    }else {
                        this.actualizaM = 1
                    }

                } catch (error) {
                    console.log(error)
                }
            },

            agregarTotales(event) {
                console.log(event)
                this.archivo = event ? event : null;
                // this.file = event.target.files ? event.target.files[0] : null;
                console.log(this.archivo)
            },

            async eliminarMes(item){
                try {
                let response = await axios.post('Gasolinas/DeleteArchivo/' + this.datosEmpresa.empresaBase, item)
                this.editedIndex = this.itemsTotalesExcel.indexOf(item)
                this.itemsTotalesExcel.splice(this.editedIndex, 1)

            } catch (error) {
                console.log(error)
            }
            },

            async ObtenerDatosExcelInventario(collection) {
                
                if (this.SelectSucursalArchivoInventario.descripcion == '' || !this.SelectSucursalArchivoInventario.descripcion) {
                        this.snackbarMessage = 'Seleccione una sucursal'
                        this.snackbar = true
                        return
                    }

                let listaExcelReportes = []
                console.log(collection)
                listaExcelReportes = collection
                let listaBuena = []
                for (let fila of listaExcelReportes) {
                    let fechas = new Date(fila.Fecha).getTime()
                    // console.log(new Date(fechas).toISOString())
                    let tipoFecha = typeof fila.Fecha
                    let fechaBien = ''
                    if(tipoFecha == 'string'){
                         fechaBien = new Date(fechas).toLocaleDateString()
                    }
                    if(tipoFecha == 'number'){
                         fechaBien =  format(parseISO(this.numeroAFecha(fila.Fecha, true).toISOString()), 'dd/MM/yyyy')
                    }
                    let objeto = {
                        fecha: fechaBien,
                        // fecha: format(parseISO(this.numeroAFecha(fechas, true).toISOString()), 'yyyy-MM-dd'),
                        producto: this.funcionParaProductos(fila.Producto.trim().toUpperCase()),
                        cantidad: fila.Cantidad,
                        precio: fila.Precio,
                        importe: fila.Importe,
                        factura: fila.Factura,
                        receptor: fila.Cliente,
                        formaPago: fila.FormaPago,
                        despacho: this.funcionParaDespachos(fila.Despacho),
                    }
                    listaBuena.push(objeto)
                }

                const magna = listaBuena.filter(x => x.producto === 'MAGNA');
                const premium = listaBuena.filter(x => x.producto === 'PREMIUM');
                const diesel = listaBuena.filter(x => x.producto === 'DIESEL');
                console.log(magna)
                let añoArchivo = moment(listaBuena[0].fecha).format('yyyy')
                console.log('año', añoArchivo)
                // let fechaMes = moment(listaBuena[0].fecha).format('MM')
                console.log('fechamala', listaBuena[0].fecha)
                let arr = listaBuena[0].fecha.split('/');
                let fechaMes =arr[1];
                // console.log('fecha', moment(listaBuena[0].fecha).format('DD/MM/yyyy'))
                console.log('MES', fechaMes)
                if (fechaMes == '12'){
                    fechaMes = 'Diciembre'
                }
                if (fechaMes == '11'){
                    fechaMes = 'Noviembre'
                }
                if (fechaMes == '10'){
                    fechaMes = 'Octubre'
                }
                if (fechaMes == '09' || fechaMes == '9'){
                    fechaMes = 'Septiembre'
                }
                if (fechaMes == '08' || fechaMes == '8'){
                    fechaMes = 'Agosto'
                }
                if (fechaMes == '07' || fechaMes == '7'){
                    fechaMes = 'Julio'
                }
                if (fechaMes == '06' || fechaMes == '6'){
                    fechaMes = 'Junio'
                }
                if (fechaMes == '05' || fechaMes == '5'){
                    fechaMes = 'Mayo'
                }
                if (fechaMes == '04' || fechaMes == '4'){
                    fechaMes = 'Abril'
                }
                if (fechaMes == '03' || fechaMes == '3'){ 
                    fechaMes = 'Marzo'
                }
                if (fechaMes == '02' || fechaMes == '2'){
                    fechaMes = 'Febrero'
                }
                if (fechaMes == '01' || fechaMes == '1'){
                    fechaMes = 'Enero'
                }

                let objeto = {
                    mes: fechaMes,
                    noCre: this.SelectSucursalArchivoInventario.noCre,
                    año:añoArchivo,
                    magnaTotal: this.sum(magna, 'importe'),
                    premiumTotal: this.sum(premium, 'importe'),
                    dieselTotal: this.sum(diesel, 'importe'), 

                    magnaLitros: this.sum(magna, 'cantidad'),      
                    premiumLitros: this.sum(premium, 'cantidad'),      
                    dieselLitros: this.sum(diesel, 'cantidad'),      
                    fechaArchivo:moment(listaBuena[0].fecha).format('yyyy-MM-DD')
                }

                try {
                    let response = await axios.post('Gasolinas/GuardarDatosExcelInventarioReporte/' + this.datosEmpresa.empresaBase, objeto)
                    console.log(response)
                    this.getDatosTotalesInventarioExcel();

                } catch (error) {
                    console.log(error)
                }

                return collection
    
            },
            async getDatosTotalesInventarioExcel() {
                
                if (this.SelectSucursalArchivoInventario.descripcion == '' || !this.SelectSucursalArchivoInventario.descripcion) {
                        this.snackbarMessage = 'Seleccione una sucursal'
                        this.snackbar = true
                        return
                    }
            let noCre = this.SelectSucursalArchivoInventario.noCre.replaceAll('/', '-')
            try {
                let response = await axios.get('Gasolinas/GetDatosExcelInventarioReporte/' + this.datosEmpresa.empresaBase + '/' + noCre)
                this.itemsTotalesExcel = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
            },
            // ESTE GET SOLO ES PARA OBTENER LOS DATOS DE LOS ARCHIVOS DE EXCEL DE SOLO LOS MESES QUE VAMOS A CONSULTAR EL INVENTARIO
            async getDatosParaCalcularInventario(){
                    this.itemsTotalesExcel = []
                    let fechaInicial = this.añoInventario + '-01' + '-01'
                    let fechaFinal = this.añoInventario + '-' + this.mesInventario.valor + '-01'
                let noCre = this.SelectSucursal.noCre.replaceAll('/', '-')
            try {
                let response = await axios.get('Gasolinas/getDatosParaCalcularInventario/' + this.datosEmpresa.empresaBase + '/' + fechaInicial + '/' + fechaFinal + '/' + noCre)
                this.itemsTotalesExcel = response.data
                console.log(response)

            } catch (error) {
                console.log(error)
            }
            }
        }
    };
</script>