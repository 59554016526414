<template>
        <DataTable :exportFilename="nombreArchivo" ref="provisionales" removableSort  filterDisplay="menu"   class="p-datatable-sm p-datatable-customers" :value="items" showGridlines :scrollable="true" 
        scrollHeight="flex" :paginator="true" :rows="15" 
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[15,30,50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :filters.sync="filtros" :globalFilterFields="['version','serie','folio','fecha','descuento','total','subTotal','subTotalPesosCobrados','tipoComprobante','metodoPago','rfc','nombre','folioFiscal','tipo']">
            <template #header>
                <div class="flex justify-content-between">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" /> 
                    <InputText  v-model="filtros['global'].value" placeholder="Buscar..." style="width: 500px;"/>
                </span>
                <div style="text-align: left">
                    <Button @click="limpiarFiltros()" style="width: 200px;" type="button" icon="pi pi-filter-slash" label="Limpiar Filtros" class="mr-5 p-button-outlined"/>
                    <Button @click="exportCSV($event)" style="width: 200px;" icon="pi pi-external-link" label="Exportar" />
                </div>
                </div> 
            </template>

            <Column sortable field="version" header="Versión" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'60px'}">
                <template #body="{data}" >
                    {{data.version}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="serie" header="Serie" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'60px'}">
                <template #body="{data}">
                    {{data.serie}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>


            <Column sortable field="folio" header="Folio" filterField="folio" dataType="numeric" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.folio}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="fecha" header="Fecha" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'100px'}">
                <template #body="{data}">
                    {{formatDate(data.fecha)}}
                </template>
                <template #filter="{filterModel}">
                    <InputText  type="text"  v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="descuento" header="Descuento" filterField="descuento" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.descuento)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="total" header="Total" filterField="total" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.total)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="MXN" locale="en-ES" />
                </template>
            </Column>

            <Column sortable field="subTotal" header="Subtotal" filterField="subTotal" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.subTotal)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="subTotalPesos" header="Subtotal Pesos" filterField="subTotalPesos" dataType="numeric" :bodyStyle="{'justify-content':'right'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{formatCurrency(data.subTotalPesos)}}
                </template>
                <template #filter="{filterModel}">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>

            <Column sortable field="tipoComprobante" header="Tipo de Comprobante" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'200px'}">
                <template #body="{data}">
                    {{data.tipoComprobante}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="metodoPago" header="Método Pago" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.metodoPago}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="rfc" header="RFC" :styles="{'min-width':'150px'}" :bodyStyle="{'justify-content':'center'}">
                <template #body="{data}">
                    {{data.rfc}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="nombre" header="Nombre Receptor" :bodyStyle="{'justify-content':'left'}" :styles="{'min-width':'600px'}">
                <template #body="{data}">
                    {{data.nombre}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="folioFiscal" header="FolioFiscal" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'400px'}">
                <template #body="{data}">
                    {{data.folioFiscal}}
                </template> 
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>

            <Column sortable field="tipo" header="Tipo" :bodyStyle="{'justify-content':'center'}" :styles="{'min-width':'150px'}">
                <template #body="{data}">
                    {{data.tipo}}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
                </template>
            </Column>
        </DataTable>
</template>    
<script>
import moment from "moment";
import {FilterMatchMode,FilterOperator} from 'primevue/api';
export default {
    components: {
    },
    data: () => ({
        filtros: {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'version': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'serie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'descuento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'subTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'subTotalPesos': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'tipoComprobante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'metodoPago': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'rfc': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'nombre': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folioFiscal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'tipo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            },
    }),
    computed: {
        nombreArchivo(){
            return this.$store.state.tituloDetalleStore
        },
 
        items() {
            return this.$store.state.listaDetalleStore;
        },
    },
    methods:{
        exportCSV() {
            this.$refs.provisionales.exportCSV();
        },
        limpiarFiltros(){
            this.filtros= {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'version': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'serie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'fecha': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'descuento': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'total': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'subTotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'subTotalPesos': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'tipoComprobante': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'metodoPago': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'rfc': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'nombre': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'folioFiscal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'tipo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
            }
        },
        formatCurrency(value) {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
        },
        formatDate(date) {
            return moment(date).format("yyyy-MM-DD");
        },
    },
}
</script>