<template>
    <div>
        <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                {{ dialogMessage }}
                <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- DIALOGO PARA VER DETALLES FACTURADOS -->
        <Dialog :header="titulo" :visible.sync="dialogDetallesPagosIva" :style="{width: '85vw'}" :maximizable="true" :modal="true" :contentStyle="{height: '550px'}">
            <DetallesPagosIVA></DetallesPagosIVA>
        </Dialog>

        <!-- DIALOG COMPARATIVA -->
        <v-dialog v-model="dialogComparativa" persistent max-width="500px">
            <v-card>
            <v-toolbar>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small @click="dialogComparativa = false" v-bind="attrs" v-on="on">
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                <span>Cerrar</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-toolbar-title>Importes Registrados {{ fechaHome.anio }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on" @click="PostComparativa()">
                    <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Guardar</span>
                </v-tooltip>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <br>
            <v-card-text>
                    <v-data-table :headers="headersComparativa" :items="itemsComparativa" :items-per-page="-1" hide-default-footer>
                    <!-- PARA EDITAR -->
                    <template v-slot:item.cargo="props">
                        <v-edit-dialog :return-value.sync="props.item.cargo">
                        {{ props.item.cargo }}
                        <template v-slot:input>
                            <v-text-field v-model="props.item.cargo" label="Editar" single-line counter></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:item.abono="props">
                        <v-edit-dialog :return-value.sync="props.item.abono">
                        {{ props.item.abono }}
                        <template v-slot:input>
                            <v-text-field v-model="props.item.abono" label="Editar" single-line counter></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template>
                    </v-data-table>
            </v-card-text>
            </v-card>
        </v-dialog>

        <!-- REPORTE -->
        <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5"  color="transparent">
            <v-card elevation="3" class="justify-center">
                <v-card-title class="dark justify-center">
                    <h3 class="justify-center">PAGOS DE IVA</h3>
                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
            </v-card>
            <v-card width="1400" elevation="3" class="mx-auto pt-10 pb-10 ma-5">
                <v-row justify="space-around">
                        <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelPagosIva()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" class="white--text mr-5 ml-5" color="blue darken-4"
                                    outlined @click="openDialog()">
                                    <v-icon>mdi-menu</v-icon>
                                    COMPARATIVA
                                </v-chip>
                            </template>
                            <span>COMPARATIVA</span>
                        </v-tooltip>
                        <!-- BOTÓN PARA CONSULTAR -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary accent-4" dark fab small v-bind="attrs" v-on="on" @click="GetReporte()">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn>
                            </template>
                            <span>Actualizar</span>
                        </v-tooltip>
                </v-row>
                <br>
                <v-data-table fixed-header
                height="350px" :item-class="row_classes"  :items="itemsPagosIva" :headers="headerPagosIva" class="elevation -1"
                    :items-per-page="-1" hide-default-footer>
                    <template v-slot:[`item.actions`]="{ item }">
                        <template style="position: sticky;" v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="detallesPagados(item, 'IVA Trasladado')">
                                            mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <template v-slot:[`item.actions1`]="{ item }">
                        <template v-if="item.mes != 'TOTAL:' && item.mes != ''">
                            <div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" color="primary darken-4"
                                            @click="detallesCobrados(item,'IVA Acreditado')">mdi-file-document
                                        </v-icon>
                                    </template>
                                    <span>Comprobaciones</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.baseIvaAcreditado`]="{ item }">
                        <span>{{ functionFormatPrice(item.baseIvaAcreditado) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.baseIvaTrasladado`]="{ item }">
                        <span>{{ functionFormatPrice(item.baseIvaTrasladado) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.cargo`]="{ item }">
                        <span>{{ functionFormatPrice(item.cargo) }}</span> 
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.importeIvaAcreditado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeIvaAcreditado) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.importeIvaTrasladado`]="{ item }">
                        <span>{{ functionFormatPrice(item.importeIvaTrasladado) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.abono`]="{ item }">
                        <span>{{ functionFormatPrice(item.abono) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.cargoRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.cargoRegistrado) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.abonoRegistrado`]="{ item }">
                        <span>{{ functionFormatPrice(item.abonoRegistrado) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.comparativa`]="{ item }">
                        <span>{{ functionFormatPrice(item.comparativa) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.retenido`]="{ item }">
                        <span>{{ functionFormatPrice(item.retenido) }}</span>
                    </template>
                    <!-- FORMATO CON DECIMALES -->
                    <template v-slot:[`item.retenidoAnterior`]="{ item }">
                        <span>{{ functionFormatPrice(item.retenidoAnterior) }}</span>
                    </template>
                </v-data-table>
            </v-card>
        </v-card>
    </div>
</template>
<script>
    import HelloWorld from '@/components/HelloWorld.vue'
    import axios from 'axios'
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import DetallesPagosIVA from '../PagosIVA/DetallesPagoIVA.vue'
    // import XLSX from 'xlsx';

    Vue.use(VuetifyMoney);

    export default {
        name: 'Home',
        components: {
            DetallesPagosIVA,
            HelloWorld,
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
        },
        data: () => ({
            dialogDetallesPagosIva: false,
            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "SheetOne", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',
            headerPagosIva: [
                { text: 'Mes', value: 'mes' },
                { text: 'Base IVA Traslado', value: 'baseIvaTrasladado', align: 'right' },
                { text: 'Importe IVA Traslado', value: 'importeIvaTrasladado', align: 'right' },
                { text: 'Actions', value: 'actions', sortable: false },

                { text: 'Base IVA Acreditado', value: 'baseIvaAcreditado', align: 'right' },
                { text: 'Importe IVA Acreditado', value: 'importeIvaAcreditado', align: 'right' },
                { text: 'Actions', value: 'actions1', sortable: false },

                { text: 'Retenido', value: 'retenido', align: 'right' },
                { text: 'Retenido Anterior', value: 'retenidoAnterior', align: 'right' },

                { text: 'Cargo', value: 'cargo', align: 'right' },
                { text: 'Abono', value: 'abono', align: 'right' },

                { text: 'Cargo Registrado', value: 'cargoRegistrado', align: 'right' },
                { text: 'Abono Registrado', value: 'abonoRegistrado', align: 'right' },

                { text: 'Comparativa', value: 'comparativa', align: 'right' },
            ],
            itemsPagosIva: [],
            
            titulo: '',

            //DIALOG DE CONSULTA
            dialog: false,
            dialogMessage: '',

            //COMPARATIVAS
            dialogComparativa: false,
            headersComparativa: [
                { text: 'Mes', value: 'mes' },
                { text: 'Cargo', value: 'cargo' },
                { text: 'Abono', value: 'abono' },
            ],
            itemsComparativa:[
                { anio: '', mes: 'enero', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'febrero', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'marzo', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'abril', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'mayo', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'junio', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'julio', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'agosto', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'septiembre', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'octubre', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'noviembre', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
                { anio: '', mes: 'diciembre', tipo: 'IVA', importe: 0, cargo: 0, abono: 0 },
            ],
            
        }),
        created() {

        },
        computed: {
            datosEmpresa() {
                return this.$store.state.usuario;
            },
            fechaHome(){
                return this.$store.state.fechaHome;
            },
        },

        methods: {
            async GetReporte(){
                this.dialog = true;
                this.dialogMessage = "Consultando datos..."
                this.itemsPagosIva = []
                let retencionesRecibidos = await this.GetIvaRetenidoRecibido();
                let trasladados = await this.GetTrasladado();
                let acreditados = await this.GetAcreditado();

                let concepto = {};
                for(var impuesto of trasladados){
                    concepto.mes = impuesto.mes
                    concepto.baseIvaTrasladado = impuesto.importeBaseIva
                    concepto.importeIvaTrasladado = impuesto.importeIva
                    concepto.itemsTrasladado = impuesto.detalles
                    concepto.baseIvaAcreditado = 0
                    concepto.importeIvaAcreditado = 0
                    concepto.itemsAcreditado = []

                    concepto.cargo = 0
                    concepto.abono = 0
                    
                    this.itemsPagosIva.push(concepto);
                    concepto = {};
                } 

                let indiceRet = 0;
                for(var item of this.itemsPagosIva){
                    var indice = acreditados.findIndex(x => x.mes === item.mes);
                    if(indice != -1){
                        item.importeIvaAcreditado = acreditados[indice].importeIva
                        item.baseIvaAcreditado = acreditados[indice].importeBaseIva
                        item.itemsAcreditado = acreditados[indice].detalles
                    }

                    //ASIGNAMOS LAS RETENCIONES 
                    item.retenido = retencionesRecibidos[indiceRet+1].importeIva
                    item.retenidoAnterior = retencionesRecibidos[indiceRet].importeIva
                    indiceRet++;

                    let diferencia = item.importeIvaTrasladado - item.importeIvaAcreditado + item.retenido - item.retenidoAnterior
                    if(diferencia > 0){
                        item.cargo = diferencia
                        item.abono = 0
                    }else{
                        item.cargo = 0
                        item.abono = diferencia * -1
                    }
                }

                //OBTENEMOS IMPORTES REGISTRADOS
                await this.GetComparativa();

                //SUMATORIA DEL FINAL
                let sumatoria = {
                    mes: 'TOTAL:',
                    baseIvaTrasladado: this.sum(this.itemsPagosIva, 'baseIvaTrasladado'),
                    importeIvaTrasladado: this.sum(this.itemsPagosIva, 'importeIvaTrasladado'),
                    baseIvaAcreditado: this.sum(this.itemsPagosIva, 'baseIvaAcreditado'),
                    importeIvaAcreditado: this.sum(this.itemsPagosIva, 'importeIvaAcreditado'),
                    retenido: this.sum(this.itemsPagosIva, 'retenido'),
                    retenidoAnterior: this.sum(this.itemsPagosIva, 'retenidoAnterior'),

                    cargo: this.sum(this.itemsPagosIva, 'cargo'),
                    abono: this.sum(this.itemsPagosIva, 'abono'),
                    cargoRegistrado: this.sum(this.itemsPagosIva, 'cargoRegistrado'),
                    abonoRegistrado: this.sum(this.itemsPagosIva, 'abonoRegistrado'),
                    comparativa: this.sum(this.itemsPagosIva, 'comparativa'),
                }

                this.itemsPagosIva.push(sumatoria);
                this.dialog = false;
            },
            async GetTrasladado(){
                this.dialogMessage = "Consultando trasladado..."
                try{
                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Ingresos/GetIvaTrasladado/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    console.log(response);
                    return response.data
                }catch(error){
                    console.log(error)
                }
            },
            async GetAcreditado(){
                this.dialogMessage = "Consultando acreditado..."
                try{
                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Gastos/GetIvaAcreditado/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    console.log(response);
                    return response.data
                }catch(error){
                    console.log(error)
                }
            },
            async GetIvaRetenidoRecibido(){
                this.dialogMessage = "Consultando retenciones..."
                try{
                    let fechaInicial = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Gastos/GetIvaRetenido/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial)
                    console.log(response);
                    return response.data
                }catch(error){
                    console.log(error)
                }
            },

            detallesCobrados(item, value) {
                this.titulo = value + ' ('+ item.mes +')'
                this.dialogDetallesPagosIva = true

                this.$store.state.tituloDetalleStore = ''
                this.$store.state.listaDetalleStore = []

                this.$store.state.tituloDetalleStore = value + '_' +  item.mes + '_' + this.fechaHome.anio
                this.$store.state.listaDetalleStore = item.itemsAcreditado 
            },

            detallesPagados(item, value) {
                this.titulo = value + ' ('+ item.mes +')'
                this.dialogDetallesPagosIva = true

                this.$store.state.tituloDetalleStore = ''
                this.$store.state.listaDetalleStore = []

                this.$store.state.tituloDetalleStore = value + '_' +  item.mes + '_' + this.fechaHome.anio
                this.$store.state.listaDetalleStore = item.itemsTrasladado
            },

            
            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            GeneraExcelPagosIva() {
                // console.log(this.empresa)
                this.nombreReporte = 'PagosIVA_' + this.fechaHome.anio + '.xlsx'
                let listaExcel = [];
                for (let a of this.itemsPagosIva){
                    let objeto = {
                        mes : a.mes,
                        baseIvaTrasladado:a.baseIvaTrasladado,
                        importeIvaTrasladado: a.importeIvaTrasladado,
                        baseIvaAcreditado:a.baseIvaAcreditado,
                        importeIvaAcreditado:a.importeIvaAcreditado,
                        retenido:a.retenido,
                        retenidoAnterior:a.retenidoAnterior,
                        cargo:a.cargo,
                        abono:a.abono,
                        cargoRegistrado:a.cargoRegistrado,
                        abonoRegistrado:a.abonoRegistrado,
                        comparativa:a.comparativa

                    }
                    listaExcel.push(objeto)
                }
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;

            },
            row_classes(item) {
                if (item.mes == "TOTAL:") {
                    return "primary lighten-5"; 
                }
            },
            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },
        
            //COMPARATIVA
            async openDialog(){
                await this.GetComparativa();
                this.dialogComparativa = true;
            },
            async GetComparativa(){
                try{
                    let response = await axios.get('Comparativa/GetComparativa/' + this.datosEmpresa.empresaBase + '/IVA/' + this.fechaHome.anio)
                    console.log(response);
                    let itemsComparativaBd = response.data

                    for(var item of this.itemsComparativa){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);
                        let cargo = 0;
                        let abono = 0;
                        if(indice != -1){
                            cargo = itemsComparativaBd[indice].cargo
                            abono = itemsComparativaBd[indice].abono
                        }
                        item.anio = this.fechaHome.anio
                        item.cargo = cargo;
                        item.abono = abono;
                    }

                    // RECORREMOS PARA ASIGNAR LOS VALORES
                    for(var item of this.itemsPagosIva){
                        var indice = itemsComparativaBd.findIndex(x => x.mes === item.mes);

                        if(indice != -1){
                            item.cargoRegistrado = itemsComparativaBd[indice].cargo
                            item.abonoRegistrado = itemsComparativaBd[indice].abono
                        }else{
                            item.cargoRegistrado = 0
                            item.abonoRegistrado = 0
                        }

                        let cargoAbono = item.cargo - item.abono 
                        let cargoAbonoRegistrado =  item.cargoRegistrado - item.abonoRegistrado;
                        
                        if (cargoAbono <= 0 && cargoAbonoRegistrado >= 0){
                            item.comparativa = (cargoAbono - cargoAbonoRegistrado) * -1
                        }
                        
                        if (cargoAbono <= 0 && cargoAbonoRegistrado <= 0){
                            item.comparativa = cargoAbono - cargoAbonoRegistrado
                        }

                        if (cargoAbono >= 0 && cargoAbonoRegistrado >= 0){
                            item.comparativa = cargoAbono - cargoAbonoRegistrado
                        }

                        if (cargoAbono >= 0 && cargoAbonoRegistrado <= 0){ 
                            item.comparativa = cargoAbono + cargoAbonoRegistrado
                        }


                        // item.comparativa = item.cargo - item.cargoRegistrado + item.abono - item.abonoRegistrado;
                        //item.comparativa = item.cargo - item.abono + item.cargoRegistrado - item.abonoRegistrado;
                    }
                    console.log(this.itemsPagosIva)

                }catch(error){
                    console.log(error)
                }
            },
            async PostComparativa(){
                try{
                    let response = await axios.post('Comparativa/PostComparativa/' + this.datosEmpresa.empresaBase , this.itemsComparativa)
                    this.dialogComparativa = false
                }catch(error){
                    console.log(error) 
                }
            },
        
        },



    };
</script>