var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{ref:"anticipos",staticClass:"p-datatable-sm p-datatable-customers",attrs:{"exportFilename":_vm.nombreArchivo,"removableSort":"","filterDisplay":"menu","value":_vm.items,"showGridlines":"","scrollable":true,"scrollHeight":"flex","paginator":true,"rows":15,"paginatorTemplate":"CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown","rowsPerPageOptions":[15,30,50],"currentPageReportTemplate":"Showing {first} to {last} of {totalRecords}","filters":_vm.filtros,"globalFilterFields":['serie','folio','fecha','total','moneda','formaPago','folioFiscal']},on:{"update:filters":function($event){_vm.filtros=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-content-between"},[_c('span',{staticClass:"p-input-icon-left"},[_c('i',{staticClass:"pi pi-search"}),_c('InputText',{staticStyle:{"width":"500px"},attrs:{"placeholder":"Buscar..."},model:{value:(_vm.filtros['global'].value),callback:function ($$v) {_vm.$set(_vm.filtros['global'], "value", $$v)},expression:"filtros['global'].value"}})],1),_c('div',{staticStyle:{"text-align":"left"}},[_c('Button',{staticClass:"mr-5 p-button-outlined",staticStyle:{"width":"200px"},attrs:{"type":"button","icon":"pi pi-filter-slash","label":"Limpiar Filtros"},on:{"click":function($event){return _vm.limpiarFiltros()}}}),_c('Button',{staticStyle:{"width":"200px"},attrs:{"icon":"pi pi-external-link","label":"Exportar"},on:{"click":function($event){return _vm.exportCSV($event)}}})],1)])]},proxy:true}])},[_c('Column',{attrs:{"sortable":"","field":"serie","header":"Serie","bodyStyle":{'justify-content':'center'},"styles":{'min-width':'60px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.serie)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"sortable":"","field":"folio","header":"Folio","bodyStyle":{'justify-content':'center'},"styles":{'min-width':'200px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.folio)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"sortable":"","field":"folioFiscal","header":"Folio Fiscal","bodyStyle":{'justify-content':'center'},"styles":{'min-width':'400px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.folioFiscal)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"sortable":"","field":"total","header":"Total","filterField":"total","dataType":"numeric","bodyStyle":{'justify-content':'right'},"styles":{'min-width':'150px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(data.total))+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputNumber',{attrs:{"mode":"currency","currency":"MXN","locale":"en-ES"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"sortable":"","field":"moneda","header":"Moneda","bodyStyle":{'justify-content':'center'},"styles":{'min-width':'150px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.moneda)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"sortable":"","field":"fecha","header":"Fecha","bodyStyle":{'justify-content':'center'},"styles":{'min-width':'100px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.fecha))+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])}),_c('Column',{attrs:{"sortable":"","field":"formaPago","header":"Forma de Pago","bodyStyle":{'justify-content':'center'},"styles":{'min-width':'150px'}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.formaPago)+" ")]}},{key:"filter",fn:function(ref){
var filterModel = ref.filterModel;
return [_c('InputText',{staticClass:"p-column-filter",attrs:{"type":"text","placeholder":"Search by name"},model:{value:(filterModel.value),callback:function ($$v) {_vm.$set(filterModel, "value", $$v)},expression:"filterModel.value"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }