<template>
    <div>

        <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{ dialogMessage }}
                    <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>


        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>

        <!-- REPORTE -->
        <v-card width="1400" elevation="0" class="mx-auto pb-10 ma-5" color="transparent">
            <!-- TITULO -->
            <v-card elevation="3" class="justify-center">
                <v-card-title class="dark justify-center">
                    <v-card-title class="dark justify-center">
                        <v-row>
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero' || datosEmpresa.rol === 'EjecutivoGas'">
                                <h3 class="justify-center">TARJETA EJECUTIVA</h3>
                            </template>
                            <template v-if="datosEmpresa.rol === 'Capturista'">
                                <h3 class="justify-center">REGISTRA TUS IMPORTES DETERMINADOS</h3>
                            </template>
                        </v-row>
                    </v-card-title>

                    <template
                        v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero' || datosEmpresa.rol === 'EjecutivoGas'">
                        <v-tooltip bottom v-if="graficaTabla == 0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs"
                                    v-on="on" @click="GetValores()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="graficaTabla == 1">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs"
                                    v-on="on" @click="ObtieneIngresosGastosXDias()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="graficaTabla == 2">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs"
                                    v-on="on" @click="GetBlog()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                    </template>

                    <!-- RADIO BUTTONS TABLA/GRAFICA -->
                    <v-card-text>
                        <v-row pt-3 dense class="justify-center pt-2"
                            v-if="datosEmpresa.rol === 'Administrador'|| datosEmpresa.rol === 'Ejecutivo'">
                            <v-col cols="12" md="4" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="tabla" class="mr-3" name="gastos" :value="0"
                                        v-model="graficaTabla" />
                                    <label for="tabla">Impuestos</label>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="grafica" class="mr-3" :value="1" name="gastos"
                                        v-model="graficaTabla" />
                                    <label for="grafica">Gráficas</label>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="blog" class="mr-3" :value="2" name="gastos"
                                        v-model="graficaTabla" />
                                    <label for="grafica">Blog</label>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- SOLO PARA EMPRESAS DE GASOLINERAS LES APARECERA LA OPCION 3 DE INVENTARIO -->
                        <v-row pt-3 dense class="justify-center pt-2" v-if="datosEmpresa.rol === 'Gasolinero' || datosEmpresa.rol === 'EjecutivoGas'">
                            <v-col cols="12" md="3" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="tabla" class="mr-3" name="gastos" :value="0"
                                        v-model="graficaTabla" />
                                    <label for="tabla">Impuestos</label>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="grafica" class="mr-3" :value="1" name="gastos"
                                        v-model="graficaTabla" />
                                    <label for="grafica">Gráficas</label>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="blog" class="mr-3" :value="3" name="gastos"
                                        v-model="graficaTabla" />
                                    <label for="grafica">Gasolinera</label>
                                </div>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <div class="justify-center field-radiobutton">
                                    <RadioButton inputId="blog" class="mr-3" :value="2" name="gastos"
                                        v-model="graficaTabla" />
                                    <label for="grafica">Blog</label>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>


                    <template v-if="datosEmpresa.rol === 'Capturista'">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs"
                                    v-on="on" @click="postRegistrados()">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                            </template>
                            <span>Guardar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class=" ml-10 mr-5" color="primary darken-1 " dark fab small v-bind="attrs"
                                    v-on="on" @click="GetRegistrado()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar</span>
                        </v-tooltip>
                    </template>

                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
            </v-card>

            <!-- REPORTE GENERAL CANTIDADES -->
            <template v-if="graficaTabla == 0">

                <!-- PAGO PARA ISR -->
                <template v-if="datosEmpresa.rol != 'Capturista'">
                    <v-card v-if="totaCalculoPagoISR != 0 || totalRegistradoISR != 0  || diferenciaPagoISR != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">PAGO PROVISIONALES ISR</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para Pago de ISR"
                                    v-model="totaCalculoPagoISR" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para Pago de ISR"
                                    v-model="totalRegistradoISR" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para Pago de ISR"
                                    v-model="diferenciaPagoISR" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>

                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para Pago Provisional de ISR"
                                        v-model="totalRegistradoISR" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- SUBSIDIO AL EMPLEO UNA VEZ QUEDE EL CALCULO, SE VA A INTEGRAR -->
                <!-- <v-card width="1400" elevation="6" class="mx-auto pb-10 ma-5" color="transparent">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">SUBSIDIO AL EMPLEO</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center pt-4">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para Subsidio al Empleo"
                                    v-model="totaCalculoSubsidioISR" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para Subsidio al Empleo"
                                    v-model="totalRegistradoSubsidio" readonly outlined>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para Subsidio al Empleo"
                                    v-model="diferenciaSubsidioISR" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card> -->

                <!-- ISR SUELDOS -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoISRSueldo != 0 || totalRegistradoISRSueldo != 0  || diferenciaISRSueldo != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">ISR SUELDOS</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para ISR Sueldos"
                                    v-model="totaCalculoISRSueldo" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para ISR Sueldos"
                                    v-model="totalRegistradoISRSueldo" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para ISR Sueldos"
                                    v-model="diferenciaISRSueldo" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para ISR Sueldos"
                                        v-model="totalRegistradoISRSueldo" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- ISR ASIMILADOS -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoISRAsimilados != 0 || totalRegistradoISRAsimilados != 0  || diferenciaISRAsimilados != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">ISR ASIMILADOS</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para ISR Asimilados"
                                    v-model="totaCalculoISRAsimilados" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para ISR Asimilados"
                                    v-model="totalRegistradoISRAsimilados" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para ISR Asimilados"
                                    v-model="diferenciaISRAsimilados" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para ISR Asimilados"
                                        v-model="totalRegistradoISRAsimilados" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- ISR SUELDOS OTROS -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoISRSueldosOtros != 0 || totalRegistroISROtroSueldoOtros != 0  || diferenciaISRSueldosOtros != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">ISR SUELDOS (OTROS)</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center  mt-9">
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero'">
                                <v-col cols="12" md="3" sm="12" dense>
                                    <v-text-field prefix="$" label="Total del Cálculo Para ISR Sueldos (Otros)"
                                        v-model="totaCalculoISRSueldosOtros" readonly outlined>
                                    </v-text-field>
                                </v-col>
                            </template>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para ISR Sueldos (Otros)"
                                    v-model="totalRegistroISROtroSueldoOtros" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero'">
                                <v-col cols="12" md="3" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Diferencia Para ISR Sueldos (Otros)"
                                        v-model="diferenciaISRSueldosOtros" readonly outlined>
                                    </v-text-field>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card>
                </template>

                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para ISR Sueldos (Otros)"
                                        v-model="totalRegistroISROtroSueldoOtros" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- ISR HONORARIOS -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoISRHonorarios != 0 || totalRegistroISRHonorarios != 0  || diferenciaISRHonorarios != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">ISR HONORARIOS</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para ISR Honorarios"
                                    v-model="totaCalculoISRHonorarios" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para ISR Honorarios"
                                    v-model="totalRegistroISRHonorarios" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para ISR Honorarios"
                                    v-model="diferenciaISRHonorarios" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para ISR Honorarios"
                                        v-model="totalRegistroISRHonorarios" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- ISR ARRENDAMIENTOS -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoISRArrendamiento != 0 || totalRegistradoISRArrendamiento != 0  || diferenciaISRArrendamiento != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">ISR ARRENDAMIENTOS</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero'">
                                <v-col cols="12" md="3" sm="12" dense>
                                    <v-text-field prefix="$" label="Total del Cálculo Para ISR Arrendamiento"
                                        v-model="totaCalculoISRArrendamiento" readonly outlined>
                                    </v-text-field>
                                </v-col>
                            </template>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para ISR Arrendamiento"
                                    v-model="totalRegistradoISRArrendamiento" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero'">
                                <v-col cols="12" md="3" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Diferencia Para ISR Arrendamiento"
                                        v-model="diferenciaISRArrendamiento" readonly outlined>
                                    </v-text-field>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para ISR Arrendamiento"
                                        v-model="totalRegistradoISRArrendamiento" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- ISR FLETES -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoISRFletes != 0 || totalRegistradoISRFletes != 0  || diferenciaISRFletes != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">ISR FLETES</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para ISR Fletes"
                                    v-model="totaCalculoISRFletes" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para ISR Fletes"
                                    v-model="totalRegistradoISRFletes" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para ISR Fletes"
                                    v-model="diferenciaISRFletes" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para ISR Sueldos"
                                        v-model="totalRegistradoISRSueldo" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- IVA A CARGO -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card v-if="totaCalculoIVACargo != 0 || totalRegistroIVACargo != 0  || diferenciaIVACargo != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">IVA A CARGO</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero'">
                                <v-col cols="12" md="3" sm="12" dense>
                                    <v-text-field prefix="$" label="Total del Cálculo Para IVA a Cargo"
                                        v-model="totaCalculoIVACargo" readonly outlined>
                                    </v-text-field>
                                </v-col>
                            </template>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para IVA a Cargo"
                                    v-model="totalRegistroIVACargo" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <template
                                v-if="datosEmpresa.rol === 'Ejecutivo' || datosEmpresa.rol === 'Administrador' || datosEmpresa.rol === 'Gasolinero'">
                                <v-col cols="12" md="3" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Diferencia Para IVA a Cargo"
                                        v-model="diferenciaIVACargo" readonly outlined>
                                    </v-text-field>
                                </v-col>
                            </template>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para IVA a Cargo"
                                        v-model="totalRegistroIVACargo" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- IVA A FAVOR -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card v-if="totaCalculoIVAFavor != 0 || totalRegistroIVAFavor != 0  || diferenciaIVAFavor != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">IVA A FAVOR</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para IVA a Favor"
                                    v-model="totaCalculoIVAFavor" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para IVA a Favor"
                                    v-model="totalRegistroIVAFavor" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para IVA a Favor"
                                    v-model="diferenciaIVAFavor" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para IVA a Favor"
                                        v-model="totalRegistroIVAFavor" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- IVA RETENCIONES EMITIDO -->
                <template datosEmpresa.rol !='Capturista'>

                    <v-card
                        v-if="totaCalculoIVARetEmitidos != 0 || totalRegistroIVARetEmitidas != 0  || diferenciaIVARetEmitidos != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">IVA RETENCIONES EMITIDAS</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para IVA Retenciones Emitidas"
                                    v-model="totaCalculoIVARetEmitidos" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para IVA Retenciones Emitidas"
                                    v-model="totalRegistroIVARetEmitidas" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para IVA Retenciones Emitidas"
                                    v-model="diferenciaIVARetEmitidos" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
                <template v-if="datosEmpresa.rol === 'Capturista'">
                    <v-card>
                        <v-card-text>
                            <v-row dense class="justify-center">
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                                <v-col cols="12" md="4" sm="12" dense>
                                    <v-text-field prefix="$" label="Total Registrado Para IVA Retenciones Emitidas"
                                        v-model="totalRegistroIVARetEmitidas" :readonly="banderaEditar" outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" sm="12" dense></v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- IVA RETENCIONES RECIBIDAS -->
                <template datosEmpresa.rol !='Capturista'>
                    <v-card
                        v-if="totaCalculoIVARetRecibidos != 0 || totalRegistroIVARetRecibidas != 0  || diferenciaIVARetRecibidos != 0 "
                        width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">IVA RETENCIONES RECIBIDAS</h3>
                        </v-card-title>
                        <v-system-bar color="grey" height="6"></v-system-bar>
                        <v-row pt-3 dense class="justify-center mt-9">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total del Cálculo Para IVA Retenciones RECIBIDAS"
                                    v-model="totaCalculoIVARetRecibidos" readonly outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para IVA Retenciones RECIBIDAS"
                                    v-model="totalRegistroIVARetRecibidas" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Diferencia Para IVA Retenciones RECIBIDAS"
                                    v-model="diferenciaIVARetRecibidos" readonly outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
            </template>
            <template v-if="datosEmpresa.rol === 'Capturista'">
                <v-card>
                    <v-card-text>
                        <v-row dense class="justify-center">
                            <v-col cols="12" md="4" sm="12" dense></v-col>
                            <v-col cols="12" md="4" sm="12" dense>
                                <v-text-field prefix="$" label="Total Registrado Para IVA Retenciones RECIBIDAS"
                                    v-model="totalRegistroIVARetRecibidas" :readonly="banderaEditar" outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" dense></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <!-- GRAFICA -->
            <Card v-if="graficaTabla == 1" class="justify-center text-center" style="text-align:center">
                <template #content>
                    <div class="justify-center">
                        <v-card-title class="dark justify-center">
                            <h3 class="justify-center">INGRESOS/GASTOS GENERALES {{ fecha1Titulos }}</h3>
                        </v-card-title>
                        <v-row pt-3 dense class="justify-center pt-4">
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Ingresos" v-model="totalIngresosMes" readonly
                                    outlined>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="3" sm="12" dense>
                                <v-text-field prefix="$" label="Total Gastos" v-model="totalGastosMes" readonly
                                    outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <hr size=7 color="#ED6421">

                        <div class="text-left justify-center mt-3 p-fluid grid">
                            <div class="mt-0 ml-0 mr-0 mb-0 pt-5 pl-0 pr-0 pb-0 pa-0 justify-right field col-3">
                                <Button label="Tipo de Gráfica" style="width: 300px;"
                                    class="mt-8 ml-8 p-button-danger" />
                                <Button @click="graficaBarras()" style="width: 300px;" icon="mdi mdi-chart-bar"
                                    label="Barras Verticales" class="mt-4 ml-8 p-button-outlined p-button-danger" />
                                <Button @click="graficaBarrasHorizontales()" style="width: 300px;"
                                    icon="mdi mdi-chart-timeline" label="Barras Horizontales"
                                    class="mt-4 ml-8 p-button-outlined p-button-danger" />
                                <Button @click="grficarStacked()" style="width: 300px;" icon="mdi mdi-chart-bar-stacked"
                                    label="Barras Apiladas" class="mt-4 ml-8 p-button-outlined p-button-danger" />
                                <Button @click="graficaLineal()" style="width: 300px;" icon="mdi mdi-chart-line"
                                    label="Gráfica de Líneas" class="mt-4 ml-8 p-button-outlined p-button-danger" />
                                <Button @click="graficaLineasEstilos()" style="width: 300px;"
                                    icon="mdi mdi-chart-areaspline" label="Gráfica de Líneas con Estilos"
                                    class="mt-4 ml-8 p-button-outlined p-button-danger" />
                                <!-- <Button class="mt-4 ml-8" style="width: 300px;" icon="pi pi-external-link"
                                                label="Generar PDF" @click="exportToPDF()" /> -->
                                <!-- <Button class="mt-4 ml-8 p-button-outlined p-button-info"
                                                style="width: 300px;" icon="mdi mdi-email" label="Enviar Correo"
                                                @click="abrirCorreo()" /> -->
                            </div>
                            <div
                                class="mt-0 ml-0 mr-0 mb-0 pt-0 pl-1 pr-0 pb-0 pa-0 justify-right field col-12   md:col-9">
                                <div id="element-to-convert" class="text-left" style="width: 85%; height: 50%;">
                                    <Chart :type="tipoGrafica" :data="tipoData" :options="opcionGrafica"
                                        style="height: 50%;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>

            <!-- CUENTAS POR PAGAR/COBRAR -->
            <v-card v-if="graficaTabla == 1" width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                <v-card-title class="dark justify-center">
                    <h3 class="justify-center">CUENTAS POR PAGAR/COBRAR</h3>
                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-row dense class="justify-center mt-9">
                    <v-col cols="12" md="3" sm="12" dense>
                        <v-text-field prefix="$" label="Total Cuentas por Pagar" v-model="totCuentasXPagar" readonly
                            outlined>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" dense>
                        <v-text-field prefix="$" label="Total Cuentas por Cobrar" v-model="totCuentasXCobrar"
                            readonly outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <div class="card flex justify-content-center">
                    <Chart type="pie" :data="datosGracfica" :options="opcionesGrafica" />
                </div>
            </v-card>

            <!-- LISTA DEL 69B -->
            <v-card v-if="graficaTabla == 1" width="1400" elevation="6" class="mx-auto pb-10 ma-5">
                <v-card-title class="dark justify-center">
                    <h3 class="justify-center">LISTA DEL 69B</h3>
                </v-card-title>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <!-- <v-col class="text-right mt-1" cols="12" md="6" sm="6">
                    <v-row class="text-right">
                        <v-spacer></v-spacer>
                        <xlsx-workbook>
                            <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                                :sheet-name="sheet.name" />
                            <xlsx-download>
                            </xlsx-download>
                            <xlsx-download :filename="nombreReporte">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="mr-3" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                            @click="GeneraExcelLista()">
                                            <v-icon>mdi-file-table-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Generar Excel</span>
                                </v-tooltip>
                            </xlsx-download>
                        </xlsx-workbook>
                    </v-row>
                </v-col> -->
                <v-data-table :headers="headers" :items="itemsRFC">
                    <template v-slot:[`item.lista69B`]="{ item }">
                        <template v-if="item.lista69B != ''">
                            <v-chip :color="getColorListaNegra(item.lista69B)" fab dark>
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-chip>
                        </template>
                    </template>
                    <template v-slot:[`item.noLocalizado`]="{ item }">
                        <template v-if="item.noLocalizado != ''">
                            <v-chip :color="getColornoListado(item.noLocalizado)" fab dark
                                @click="consultarComprobantesXrfc(item)">
                                <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-chip>
                        </template>
                    </template>
                </v-data-table>
            </v-card>

            <!-- INVENTARIO PARA GASOLINEROS -->
            <template v-if="graficaTabla == 3">
                <!-- TITULO -->
                <v-card elevation="6" class="justify-center mt-5">
                    <v-card-title class="dark justify-center">
                        <h2 class="justify-center">Inventario</h2>
                    </v-card-title>
                    <v-system-bar color="primary" height="6"></v-system-bar>
                </v-card>

                <!-- TABLA -->
                <v-card elevation="6" class="mt-5 pa-8">
                    <v-row>
                        <!-- SELECT DE SUCURSALES -->
                        <v-col class="pb-0 mb-0" cols="12" xl="10" md="10" sm="10" xs="12">
                            <v-select class="pb-0 mb-0" prepend-icon="mdi-gas-station" v-model="SelectSucursal"
                                item-text="descripcion" return-object :items="itemsNoCre" label="Sucursal">
                            </v-select>
                            <v-radio-group class="pa-0 ma-0" v-model="formaDeConcultarInventario" row>
                                <v-radio label="Comprobantes" :value="1"></v-radio>
                                <v-radio label="Reporte Excel" :value="2"></v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mr-4 mt-2 mb-4" color="primary darken-1" dark fab v-bind="attrs" v-on="on"
                                    @click="GetInventarioSucursal()">
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </template>
                            <span>Consultar inventario</span>
                        </v-tooltip>
                    </v-row>
                    <!-- TABLA DE INVENTARIO -->
                    <v-data-table hide-default-footer :headers="headerInvenatario" :items="itemsIventario">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadInicial`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadInicial) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalInical`]="{ item }">
                            <span>{{ '$ '+functionFormatPrice(item.totalInical) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadCompras`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadCompras) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalCompras`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalCompras) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadVendida`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadVendida) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalVendida`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalVendida) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadRestante`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadRestante) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalRestante`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalRestante) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.totalMerma`]="{ item }">
                            <span>{{'$ '+ functionFormatPrice(item.totalMerma) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.cantidadMerma`]="{ item }">
                            <span>{{ functionFormatPrice(item.cantidadMerma) }}</span>
                        </template>
                    </v-data-table>

                    <!-- Grafica -->
                    <!-- GRAFICA PRO LITROS  -->
                    <!-- <v-row class="justify-center mt-6">
                        <h1 class="text-center">Grafica de Inventario por Litros (Enero - {{ fechaHome.mes.descripcion
                            }})</h1>
                        <v-col class="pb-0 mb-0" cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-system-bar class="mb-8 mt-1" color="primary" height="6"></v-system-bar>
                            <Chart type="bar" :data="DataGasolina" :options="basicOptions" />
                        </v-col>
                    </v-row> -->
                    <!-- GRAFICA POR IMPORTE -->
                    <!-- <v-row class="justify-center mt-6">
                        <h1 class="text-center">Grafica de Inventario por Valor (Enero - {{ fechaHome.mes.descripcion
                            }})</h1>
                        <v-col class="pb-0 mb-0" cols="12" xl="12" md="12" sm="12" xs="12">
                            <v-system-bar class="mb-8 mt-1" color="primary" height="6"></v-system-bar>
                            <Chart type="bar" :data="DataGasolina1" :options="basicOptions" />
                        </v-col>
                    </v-row> -->

                </v-card>
            </template>
            <!-- BLOG -->
            <template v-if="graficaTabla == 2">
                <br />
                <template v-for="item in datosBlog">
                    <v-row align="center" justify="center">
                        <v-col align="center" justify="center" cols="5">
                            <Card class="justify-center text-center" style="text-align:center">
                                <template #title>
                                    <template v-if="item.titulo != ''">
                                        <h2 class="justify-center">{{ item.titulo }}</h2>
                                    </template>
                                    <template v-if="item.imagen != ''">
                                        <div>
                                             <!-- <div style="height: 300px"></div>  -->
                                            <img class="justify-center" id="imglogo"
                                                style="height: 100%; width: 100%; object-fit: contain"
                                                v-bind:src="'data:image/*;base64,' + item.imagen.imagen">
                                        </div>
                                    </template>
                                </template>
                                <template v-if="item.categoria != ''" #subtitle>
                                    <h3 class="justify-center">{{ item.categoria }}</h3>
                                </template>
                                <template v-if="item.contenido != ''" #content>
                                    <h3 class="text-justify">{{ item.contenido }}</h3>
                                </template> 
                                <template v-if="item.ruta != ''" #content>
                                    <v-btn color="primary darken-1" class="justify-center" @click="BlogPdf(item.ruta)" >
                                        Leer más...</v-btn>
                                </template>
                                <template #footer>
                                    <h3 class="text-right">{{ item.usuario }}</h3>
                                    <h3 class="text-right">{{ item.fecha.substr(0,10) + ' ' + item.fecha.substr(11,8) }}
                                    </h3>
                                    <h6 class="text-right">Página {{ datosBlog.indexOf(item) + 1 }}</h6>
                                </template>
                            </Card>
                        </v-col>
                    </v-row>
                </template>

            </template>
        </v-card>

        <!-- PDF -->
        <Dialog header="Enviar Correo" :visible.sync="dialogoCorreo" :containerStyle="{width: '40vw'}">
            <Correo></Correo>
        </Dialog>

        <!-- DIALOG PARA VER DETALLES DE CADA RFC -->
        <v-dialog v-model="dialogDetalle" max-width="1600">
            <v-card>
                <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{this.titulo}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <xlsx-workbook>
                        <xlsx-sheet :collection="collection" v-for="sheet in sheets" :key="sheet.name"
                            :sheet-name="sheet.name" />
                        <xlsx-download>
                        </xlsx-download>
                        <xlsx-download :filename="nombreReporte">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary darken-1" dark fab small v-bind="attrs" v-on="on"
                                        @click="GeneraExcelComprobantes()">
                                        <v-icon>mdi-file-table-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Generar Excel</span>
                            </v-tooltip>
                        </xlsx-download>
                    </xlsx-workbook>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <br>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="filtrar" v-model="searchDetalle"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headersComprobanteRFC" :items="itemsComprobantesXrfc" fixed-header
                        height="60vh">
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.total`]="{ item }">
                            <span>{{ functionFormatPrice(item.total) }}</span>
                        </template>
                        <!-- FORMATO CON DECIMALES -->
                        <template v-slot:[`item.subTotal`]="{ item }">
                            <span>{{ functionFormatPrice(item.subTotal) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    import VuetifyMoney from "vuetify-money";
    import Vue from 'vue'
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import moment from 'moment'
    import Card from 'primevue/card';

    Vue.use(VuetifyMoney);
    export default {
        name: 'Home',
        components: {
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload,
            Card
        },
        data: () => ({
            // GRAFICAS PARA GASOLINA
            DataGasolina: {
                labels: [],
                datasets: [
                    {
                        label: 'Litros (Inicial)',
                        backgroundColor: 'red',
                        data: []
                    },
                    {
                        label: 'Litros (Compras)',
                        backgroundColor: 'pink',
                        data: []
                    },
                    {
                        label: 'Litros (Vendidos)',
                        backgroundColor: 'purple',
                        data: []
                    },
                    {
                        label: 'Litros (Merma)',
                        backgroundColor: 'deep-purple',
                        data: []
                    },
                    {
                        label: 'Litros (Restante)',
                        backgroundColor: 'indigo',
                        data: []
                    }

                ]
            },

            DataGasolina1: {
                labels: [],
                datasets: [
                    {
                        label: 'Valor (Inicial)',
                        backgroundColor: 'red',
                        data: []
                    },
                    {
                        label: 'Valor (Compras)',
                        backgroundColor: 'pink',
                        data: []
                    },
                    {
                        label: 'Valor (Vendidos)',
                        backgroundColor: 'purple',
                        data: []
                    },
                    {
                        label: 'Valor (Merma)',
                        backgroundColor: 'deep-purple',
                        data: []
                    },
                    {
                        label: 'Valor (Restante)',
                        backgroundColor: 'indigo',
                        data: []
                    }

                ]
            },

            // GRAFICA PARA CUENTAS POR PAGAR Y COBRAR
            datosGracfica: {
                labels: ['Cuentas por Pagar', 'Cuentas por Cobrar'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
                            "#FFA726"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D"
                        ]
                    }
                ]
            },

            opcionesGrafica: {
                legend: {
                    labels: {
                        fontColor: '#495057'
                    }
                }
            },

            // PARA INVENTARIO
            itemsTotalesExcel: [],
            formaDeConcultarInventario: 1,
            itemsNoCre: [],
            SelectSucursal: {},
            itemsIventario: [],
            headerInvenatario: [
                { text: 'Producto', value: 'producto' },
                { text: 'Litros (Inicial)', value: 'cantidadInicial', align: 'right' },
                { text: 'Total (Inicial)', value: 'totalInical', align: 'right' },
                { text: 'Litros (Compras)', value: 'cantidadCompras', align: 'right' },
                { text: 'Total (Compras)', value: 'totalCompras', align: 'right' },
                { text: 'Litros (Vendidos)', value: 'cantidadVendida', align: 'right' },
                { text: 'Total (Vendidos)', value: 'totalVendida', align: 'right' },
                { text: 'Cantidad (Merma)', value: 'cantidadMerma', align: 'right' },
                { text: 'Total (Merma)', value: 'totalMerma', align: 'right' },
                { text: 'Cantidad (Restante)', value: 'cantidadRestante', align: 'right' },
                { text: 'Total (Restante)', value: 'totalRestante', align: 'right' },
            ],

            banderaEditar: false,
            itemsAñoGen: [
                '2023',
                '2022',
                '2021',
                '2020',
                '2019',
                '2018',
            ],

            itemsMesGen: [
                { descripcion: 'Enero', valor: '01' },
                { descripcion: 'Febrero', valor: '02' },
                { descripcion: 'Marzo', valor: '03' },
                { descripcion: 'Abril', valor: '04' },
                { descripcion: 'Mayo', valor: '05' },
                { descripcion: 'Junio', valor: '06' },
                { descripcion: 'Julio', valor: '07' },
                { descripcion: 'Agosto', valor: '08' },
                { descripcion: 'Septiembre', valor: '09' },
                { descripcion: 'Octubre', valor: '10' },
                { descripcion: 'Noviembre', valor: '11' },
                { descripcion: 'Diciembre', valor: '12' },
            ],

            //OPCIONES PARA FOMRTAOD DECIMAL
            options: {
                locale: "en-US",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2,
            },

            //DIALOG
            dialogMessage: '',
            dialog: false,

            //LISTA PARA EXPORTAR
            listaExcel: [],
            sheets: [{ name: "Hoja 1", data: [{ c: 2 }] }],
            collection: [{ a: 1, b: 2 }],
            nombreReporte: '',

            totalCalculoISR: 0,
            totalRegistradoISR: 0,
            totalDiferenciaISR: 0,

            totalCalculoSubsidio: 0,
            totalRegistradoSubsidio: 0,
            totalDiferenciaSubsidio: 0,

            totalCalculoISRSueldo: 0,
            totalRegistradoISRSueldo: 0,
            totalDiferenciaISRSueldo: 0,

            totalCalculoISRAsimilados: 0,
            totalRegistradoISRAsimilados: 0,
            totalDiferenciaISRAsimilados: 0,

            totalCalculoISROtroSueldoOtros: 0,
            totalRegistroISROtroSueldoOtros: 0,
            totalDiferenciaISROtroSueldoOtros: 0,

            totalCalculoISRHonorarios: 0,
            totalRegistroISRHonorarios: 0,
            totalDiferenciaISRHonorarios: 0,

            totalCalculoISRArrendamiento: 0,
            totalRegistradoISRArrendamiento: 0,
            totalDiferenciaISRArrendamiento: 0,

            totalCalculoISRFletes: 0,
            totalRegistradoISRFletes: 0,
            totalDiferenciaISRFletes: 0,

            totalCalculoIVACargo: 0,
            totalRegistroIVACargo: 0,
            totalDiferenciaIVACargo: 0,

            totalCalculoIVAFavor: 0,
            totalRegistroIVAFavor: 0,
            totalDiferenciaIVAFavor: 0,

            totalCalculoIVARetEmitidas: 0,
            totalRegistroIVARetEmitidas: 0,
            totalDiferenciaIVARetEmitidas: 0,

            totalCalculoIVARetRecibidas: 0,
            totalRegistroIVARetRecibidas: 0,
            totalDiferenciaIVARetRecibidas: 0,

            dia: "",

            graficaTabla: 0,
            tipoGrafica: '',
            tipoData: '',
            opcionGrafica: '',
            consulta: 0,
            basicData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#42A5F5',
                        data: []
                    },
                    {
                        label: '',
                        backgroundColor: '#FFA726',
                        data: []
                    },

                ]
            },
            basicDataDias: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        backgroundColor: '#42A5F5',
                        data: []
                    },
                    {
                        label: '',
                        backgroundColor: '#FFA726',
                        data: []
                    },

                ]
            },
            lineStylesData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        fill: true,
                        tension: .4,
                        borderColor: '#42A5F5'
                    },
                    {
                        label: '',
                        data: [],
                        fill: true,
                        borderColor: '#FFA726',
                        tension: .4,
                        backgroundColor: 'rgba(255,167,38,0.2)'
                    }
                ]
            },
            lineStylesDataDias: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        fill: true,
                        tension: .4,
                        borderColor: '#42A5F5'
                    },
                    {
                        label: '',
                        data: [],
                        fill: true,
                        borderColor: '#FFA726',
                        tension: .4,
                        backgroundColor: 'rgba(255,167,38,0.2)'
                    }
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
                            "#FFA726"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D"
                        ]
                    }
                ]
            },
            chartOptions: {
                legend: {
                    labels: {
                        fontColor: '#495057'
                    }
                }
            },
            horizontalOptions: {
                indexAxis: 'y',
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            stackedOptions: {
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },

            ingresosFacturados: { importe: 0, detalles: [] },
            gastosFacturados: { importe: 0, detalles: [] },
            // GRAFICA
            dialogoCorreo: false,
            fecha1Titulos: '',
            fechaInicial: new Date(),
            fechaFinal: new Date(),
            ingresoGeneral: [],
            gastoGeneral: [],
            itemsIngresosGastos: [],
            listaIngresosXdias: [],
            listaGastosXdias: [],

            // CUENTAS POR PAGAR/COBRAR
            totalCuentasXCobrar: 0,
            totalCobrar: 0,
            itemsCobrar: [],
            totalPorPagar: 0,
            itemsPagar: [],

            // VALIDADOR 69B
            headers: [
                { text: 'RFC', value: 'rfc' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Lista del 69B', value: 'lista69B' },
                { text: 'No Localizado', value: 'noLocalizado' },
                { text: 'Tipo', value: 'tipo' },
            ],
            itemsRFC: [],
            itemsComprobantesXrfc: [],
            titulo: '',
            dialogDetalle: false,
            headersComprobanteRFC: [
                { text: 'Serie', value: 'serie' },
                { text: 'Folio', value: 'folio' },
                { text: 'Folio Fiscal', value: 'foliofiscal' },
                { text: 'Subtotal', value: 'subTotal', align: 'right' },
                { text: 'Total', value: 'total', align: 'right' },
                { text: 'Moneda', value: 'moneda', align: 'right' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Forma Pago', value: 'formaPago', align: 'center' },
                { text: 'Método Pago', value: 'metodoPago', align: 'center' },
                { text: 'Tipo de Comprobante', value: 'tipoComprobante', align: 'right' },
                { text: 'Estatus', value: 'estatus' },
            ],
            //dialog detalle
            searchDetalle: '',
            dialogDetalle: false,
            titulo: '',

            //BLOG

            datosBlog: [],

            // TOTALES DE INGRESOS Y GASTOS
            totalIngresosMes: 0,
            totalGastosMes: 0,

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',
        }),
        computed: {
            fechaHome() {
                return this.$store.state.fechaHome;
            },
            datosEmpresa() {
                if (this.$store.state.usuario.rol === "Ejecutivo") {
                    this.banderaEditar = true;
                }
                return this.$store.state.usuario;
            },

            totaCalculoPagoISR() {
                let formato = this.functionFormatPrice(this.totalCalculoISR)
                return formato;
            },
            totaCalculoSubsidioISR() {
                let formato = this.functionFormatPrice(this.totalCalculoSubsidio)
                return formato;
            },
            totaCalculoISRSueldo() {
                let formato = this.functionFormatPrice(this.totalCalculoISRSueldo)
                return formato;
            },
            totaCalculoISRAsimilados() {
                let formato = this.functionFormatPrice(this.totalCalculoISRAsimilados)
                return formato;
            },
            totaCalculoISRSueldosOtros() {
                let formato = this.functionFormatPrice(this.totalCalculoISROtroSueldoOtros)
                return formato;
            },
            totaCalculoISRHonorarios() {
                let formato = this.functionFormatPrice(this.totalCalculoISRHonorarios)
                return formato;
            },
            totaCalculoISRArrendamiento() {
                let formato = this.functionFormatPrice(this.totalCalculoISRArrendamiento)
                return formato;
            },
            totaCalculoISRFletes() {
                let formato = this.functionFormatPrice(this.totalCalculoISRFletes)
                return formato;
            },
            totaCalculoIVACargo() {
                let formato = this.functionFormatPrice(this.totalCalculoIVACargo)
                return formato;
            },
            totaCalculoIVAFavor() {
                let formato = this.functionFormatPrice(this.totalCalculoIVAFavor)
                return formato;
            },
            totaCalculoIVARetEmitidos() {
                let formato = this.functionFormatPrice(this.totalCalculoIVARetEmitidas)
                return formato;
            },
            totaCalculoIVARetRecibidos() {
                let formato = this.functionFormatPrice(this.totalCalculoIVARetRecibidas)
                return formato;
            },

            diferenciaPagoISR() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISR)
                return formato;
            },
            diferenciaSubsidioISR() {
                let formato = this.functionFormatPrice(this.totalDiferenciaSubsidio)
                return formato;
            },
            diferenciaISRSueldo() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISRSueldo)
                return formato;
            },
            diferenciaISRAsimilados() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISRAsimilados)
                return formato;
            },
            diferenciaISRSueldosOtros() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISROtroSueldoOtros)
                return formato;
            },
            diferenciaISRHonorarios() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISRHonorarios)
                return formato;
            },
            diferenciaISRArrendamiento() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISRArrendamiento)
                return formato;
            },
            diferenciaISRFletes() {
                let formato = this.functionFormatPrice(this.totalDiferenciaISRFletes)
                return formato;
            },
            diferenciaIVACargo() {
                let formato = this.functionFormatPrice(this.totalDiferenciaIVACargo)
                return formato;
            },
            diferenciaIVAFavor() {
                let formato = this.functionFormatPrice(this.totalDiferenciaIVAFavor)
                return formato;
            },
            diferenciaIVARetEmitidos() {
                let formato = this.functionFormatPrice(this.totalDiferenciaIVARetEmitidas)
                return formato;
            },
            diferenciaIVARetRecibidos() {
                let formato = this.functionFormatPrice(this.totalDiferenciaIVARetRecibidas)
                return formato;
            },

            // CUENTAS POR PAGAR/COBRAR
            totCuentasXCobrar() {
                let formato = this.functionFormatPrice(this.totalCobrar)
                return formato;
            },
            totCuentasXPagar() {
                let formato = this.functionFormatPrice(this.totalPorPagar)
                return formato;
            },
        },

        created() {
            this.graficaBarras()
            this.GetNoCre()
            // this.consultarListaSat()
        },

        methods: {

            //FUNCIÓN PARA DAR FORMATO DECIMAL
            functionFormatPrice(value) {
                if (value != '-') {
                    let val = (value / 1).toFixed(2)
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } else {
                    return '';
                }
            },
            // GENERAR EXCEL
            GeneraExcel() {
                this.nombreReporte = 'PagosProvisionalesISR_' + this.$store.state.datosExcel.mes + '_' + this.$store.state.datosExcel.año + '.xlsx'
                let listaExcel = [];
                listaExcel = this.$store.state.listaExportarExcel
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            async obtenFecha() {
                let diaActual = moment().locale('es-Mx')
                let dia = moment(diaActual).format('D')
                let anio = moment(diaActual).format('YYYY')
                this.fechaHome.anio = anio;

                if (dia <= 17) {
                    this.fechaHome.mes = { descripcion: '', valor: '' }
                    let fechaAnterior = diaActual.add(-1, 'months');
                    let mes = moment(fechaAnterior).format('MMMM')
                    let mesC = mes.charAt(0).toUpperCase() + mes.slice(1);
                    this.fechaHome.mes = { descripcion: mesC, valor: moment(fechaAnterior).format('MM') }
                } else {
                    let mes = moment(diaActual).format('MMMM')
                    let mesC = mes.charAt(0).toUpperCase() + mes.slice(1);
                    this.fechaHome.mes = { descripcion: mesC, valor: moment(diaActual).format('MM') }
                }
            },

            async GetValores() {
                this.dialog = true;
                this.dialogMessage = 'Espere, calculando ' + this.fechaHome.mes.descripcion + " / " + this.fechaHome.anio
                console.log(this.fechaHome.mes.valor)
                try {
                    let response = await axios.get('Tarjetaejecutiva/GetPagos/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + this.fechaHome.mes.valor + '/' + this.fechaHome.anio)
                    //LLENA EL CAMPO DE CALCULADO
                    this.totalCalculoISR = response.data.pagosIsr
                    this.totalCalculoSubsidio = response.data.subsidioAlEmpleo
                    this.totalCalculoISRSueldo = response.data.isrSueldos
                    this.totalCalculoISRAsimilados = response.data.isrAsimilados
                    this.totalCalculoISROtroSueldoOtros = response.data.isrSueldosOtros
                    this.totalCalculoISRHonorarios = response.data.isrHonorarios
                    this.totalCalculoISRArrendamiento = response.data.isrArrendamientos
                    this.totalCalculoISRFletes = response.data.isrFletes
                    this.totalCalculoIVACargo = response.data.ivaCargo
                    this.totalCalculoIVAFavor = response.data.ivaFavor
                    this.totalCalculoIVARetEmitidas = response.data.ivaRetenidoEmitido
                    this.totalCalculoIVARetRecibidas = response.data.ivaRetenidoRecibido
                    await this.GetRegistrado();
                    this.dialog = false;
                } catch (error) {
                    console.log(error);
                    this.dialog = false;
                }
            },

            async GetRegistrado() {
                try {
                    console.log(this.fechaHome)
                    this.dialogMessage = 'Consultando importes registrados'
                    let response = await axios.get('Tarjetaejecutiva/GetRegistrado/' + this.datosEmpresa.empresaBase + '/' + this.fechaHome.anio + '/' + this.fechaHome.mes.valor)
                    //LLENA EL CAMPO DE CALCULADO
                    console.log(response.data)

                    let calculado = response.data;
                    //ASIGNAMOS
                    this.totalRegistradoISR = calculado.pagosIsr
                    this.totalRegistradoSubsidio = calculado.subsidioAlEmpleo
                    this.totalRegistradoISRSueldo = calculado.isrSueldos
                    this.totalRegistradoISRAsimilados = calculado.isrAsimilados
                    this.totalRegistroISROtroSueldoOtros = calculado.isrSueldosOtros
                    this.totalRegistroISRHonorarios = calculado.isrHonorarios
                    this.totalRegistradoISRArrendamiento = calculado.isrArrendamientos
                    this.totalRegistradoISRFletes = calculado.isrFletes
                    this.totalRegistroIVACargo = calculado.ivaCargo
                    this.totalRegistroIVAFavor = calculado.ivaFavor
                    this.totalRegistroIVARetEmitidas = calculado.ivaRetenidoEmitido
                    this.totalRegistroIVARetRecibidas = calculado.ivaRetenidoRecibido

                    //CALCULAMOS
                    this.totalDiferenciaISR = this.totalCalculoISR - this.totalRegistradoISR
                    this.totalDiferenciaSubsidio = this.totalCalculoSubsidio - this.totalRegistradoSubsidio
                    this.totalDiferenciaISRSueldo = this.totalCalculoISRSueldo - this.totalRegistradoISRSueldo
                    this.totalDiferenciaISRAsimilados = this.totalCalculoISRAsimilados - this.totalRegistradoISRAsimilados
                    this.totalDiferenciaISROtroSueldoOtros = this.totalCalculoISROtroSueldoOtros - this.totalRegistroISROtroSueldoOtros
                    this.totalDiferenciaISRHonorarios = this.totalCalculoISRHonorarios - this.totalRegistroISRHonorarios
                    this.totalDiferenciaISRArrendamiento = this.totalCalculoISRArrendamiento - this.totalRegistradoISRArrendamiento
                    this.totalDiferenciaISRFletes = this.totalCalculoISRFletes - this.totalRegistradoISRFletes
                    this.totalDiferenciaIVACargo = this.totalCalculoIVACargo - this.totalRegistroIVACargo
                    this.totalDiferenciaIVAFavor = this.totalCalculoIVAFavor - this.totalRegistroIVAFavor
                    this.totalDiferenciaIVARetEmitidas = this.totalCalculoIVARetEmitidas - this.totalRegistroIVARetEmitidas
                    this.totalDiferenciaIVARetRecibidas = this.totalCalculoIVARetRecibidas - this.totalRegistroIVARetRecibidas

                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },

            async postRegistrados() {
                try {
                    console.log(this.fechaHome)
                    let elMes = this.fechaHome.mes.descripcion.toLowerCase()
                    this.dialog = true;
                    let registros = [
                        { cargo: 0, abono: 0, importe: this.totalRegistradoISR, tipo: 'PPISR', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistradoISRSueldo, tipo: 'SUELDOS', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistradoISRAsimilados, tipo: 'ASIMILADOS', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistroISROtroSueldoOtros, tipo: 'SUELDOS_O', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistroISRHonorarios, tipo: 'HONORARIOS', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistradoISRArrendamiento, tipo: 'ARRENDAMIENTOS', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistradoISRFletes, tipo: 'FLETES', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: this.totalRegistroIVACargo, abono: 0, importe: 0, tipo: 'IVA', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: this.totalRegistroIVAFavor, importe: 0, tipo: 'IVA', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistroIVARetEmitidas, tipo: 'IVARETE', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                        { cargo: 0, abono: 0, importe: this.totalRegistroIVARetRecibidas, tipo: 'IVARETR', mes: elMes, anio: this.fechaHome.anio, usuario: this.datosEmpresa.nombre },
                    ]
                    this.dialogMessage = 'Guardando espere...'
                    let response = await axios.post('Tarjetaejecutiva/PostRegistrado/' + this.datosEmpresa.empresaBase, registros)
                    this.dialog = false;
                } catch (error) {
                    console.log(error)
                    this.dialog = false;
                }
            },


            // FUNCIONES PARA GENERAR LAS DISTINTAS GRAFICAS
            graficaBarras() {
                if (this.consulta == 0) {
                    this.tipoData = this.basicDataDias
                }
                else {
                    this.tipoData = this.basicData
                }

                this.tipoGrafica = 'bar';
                this.opcionGrafica = this.basicOptions
            },
            graficaBarrasHorizontales() {
                if (this.consulta == 0) {
                    this.tipoData = this.basicDataDias
                }
                else {
                    this.tipoData = this.basicData
                }
                this.tipoGrafica = 'bar';
                this.opcionGrafica = this.horizontalOptions
            },
            grficarStacked() {
                if (this.consulta == 0) {
                    this.tipoData = this.basicDataDias
                }
                else {
                    this.tipoData = this.basicData
                }
                this.tipoGrafica = 'bar';
                this.opcionGrafica = this.stackedOptions
            },
            graficaLineal() {

                if (this.consulta == 0) {
                    this.tipoData = this.basicDataDias
                }
                else {
                    this.tipoData = this.basicData
                }

                this.tipoGrafica = 'line';
                this.opcionGrafica = this.basicOptions
            },
            graficaLineasEstilos() {
                this.tipoGrafica = 'line';

                if (this.consulta == 0) {
                    this.tipoData = this.lineStylesDataDias
                }
                else {
                    this.tipoData = this.lineStylesData
                }

            },
            graficaPie() {
                this.tipoGrafica = 'pie';
                this.tipoData = this.chartData
                this.opcionGrafica = this.chartOptions
            },
            graficaAnillos() {
                this.tipoGrafica = 'doughnut';
            },

            async GetIngresos() {
                try {
                    this.fechaInicial = this.fechaHome.mes.descripcion + " - " + this.fechaHome.anio
                    // let fecha = moment(this.fechaInicial).format('yyyy-MM-DD')
                    let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    try {
                        let response = await axios.get('Ingresos/GetIngresosSP/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha);
                        this.ingresoGeneral = response.data.ingresos
                    } catch (error) {
                        console.log(error)
                    }

                    this.dialog = false;

                } catch (error) {
                    console.log(error)
                }
            },

            async ObtieneIngresosPorDias() {
                try {
                    console.log(this.fechaHome)
                    this.fechaInicial = this.fechaHome.mes.descripcion + " - " + this.fechaHome.anio
                    let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    // let fecha = moment(this.fechaInicial).format('yyyy-MM-DD')
                    let response = await axios.get('Ingresos/GetIngresosReporteDias/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha);
                    // console.log("Ingresos", response.data)
                    this.listaIngresosXdias = response.data
                    this.totalIngresosMes = this.functionFormatPrice(this.sum(this.listaIngresosXdias, 'importe'))
                } catch (error) {
                    console.log(error)
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al consultar', life: 3000 });
                }
                this.loading = false;
            },

            async GetGastos() {
                try {
                    this.fechaInicial = this.fechaHome.mes.descripcion + " - " + this.fechaHome.anio
                    // let fecha = moment(this.fechaInicial).format('yyyy-MM-DD')
                    let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Gastos/GetGastosReporte/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha);
                    this.gastoGeneral = response.data
                } catch (error) {
                    console.log(error)
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al consultar', life: 3000 });
                }
            },

            // CONSULTA PARA GASTONES QUENERALES POR DIAS
            async ObtieneGastosPorDias() {
                try {
                    this.fechaInicial = this.fechaHome.mes.descripcion + " - " + this.fechaHome.anio
                    // let fecha = moment(this.fechaInicial).format('yyyy-MM-DD')
                    let fecha = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    let response = await axios.get('Gastos/GetGastosReporteDias/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fecha);
                    // console.log('gastos', response.data)

                    this.listaGastosXdias = response.data
                    this.totalGastosMes = this.functionFormatPrice(this.sum(this.listaGastosXdias, 'importe'))
                    // this.GeneraGraficasPorDias();
                } catch (error) {
                    console.log(error)
                }
                this.loading = false;
            },

            async ObtieneIngresosGastos() {
                this.dialog = true;
                this.dialogMessage = 'Espere, calculando ' + this.fechaHome.mes.descripcion + " / " + this.fechaHome.anio

                moment.locale('es-mx');
                let fecha1 = moment(this.fechaInicial).format('yyyy')
                let fecha2 = moment(this.fechaFinal).format('yyyy')
                let mes = moment(this.fechaInicial).format('MMMM')

                this.fecha1Titulos = '(Enero - ' + mes + ' de ' + fecha1 + ' - ' + fecha2 + ')'
                this.fecha1Titulos = '(' + this.fechaHome.mes.descripcion + " / " + this.fechaHome.anio + ')'

                // LIMPIA GRAFICAS POR MES DE AQUI
                this.basicData.labels = [];
                this.basicData.datasets[0].data = []
                this.basicData.datasets[1].data = []
                this.basicData.datasets[0].label = "Ingresos";
                this.basicData.datasets[1].label = "Gastos";

                this.lineStylesData.labels = [];
                this.lineStylesData.datasets[0].label = "Ingresos";
                this.lineStylesData.datasets[1].label = "Gastos";
                // HASTA AQUI

                // LIMPIA GRAFICAS POR DIAS DE AQUI
                this.basicDataDias.labels = [];
                this.basicDataDias.datasets[0].data = []

                this.lineStylesDataDias.labels = [];
                this.lineStylesDataDias.datasets[0].data = []
                // HASTA AQUI

                await this.GetIngresos()
                await this.GetGastos()

                for (let i of this.ingresoGeneral) {
                    for (let j of this.gastoGeneral) {
                        if (j.mes == i.mes) {
                            let objeto = {
                                mes: j.mes,
                                importe: i.importe,
                                importeCompara: j.importe,
                            }
                            this.itemsIngresosGastos.push(objeto)
                            this.basicData.labels.push(objeto.mes)
                            this.basicData.datasets[0].data.push(objeto.importe)
                            this.basicData.datasets[1].data.push(objeto.importeCompara)

                            this.lineStylesData.labels.push(objeto.mes)
                            this.lineStylesData.datasets[0].data.push(objeto.importe)
                            this.lineStylesData.datasets[1].data.push(objeto.importeCompara)
                            break;
                        }
                    }
                }
                this.graficaBarras()
                this.dialog = false;
            },

            async ObtieneIngresosGastosXDias() {

                this.dialog = true;
                this.dialogMessage = 'Espere, calculando ' + this.fechaHome.mes.descripcion + " / " + this.fechaHome.anio

                moment.locale('es-mx');
                let fecha1 = moment(this.fechaInicial).format('yyyy')
                let fecha2 = moment(this.fechaFinal).format('yyyy')
                let mes = moment(this.fechaInicial).format('MMMM')

                this.fecha1Titulos = '(Enero - ' + mes + ' de ' + fecha1 + ' - ' + fecha2 + ')'
                this.fecha1Titulos = '(' + this.fechaHome.mes.descripcion + " / " + this.fechaHome.anio + ')'

                // LIMPIA GRAFICAS POR MES DE AQUI
                this.basicDataDias.labels = [];
                this.basicDataDias.datasets[0].data = []
                this.basicDataDias.datasets[1].data = []
                this.basicDataDias.datasets[0].label = "Ingresos";
                this.basicDataDias.datasets[1].label = "Gastos";

                this.lineStylesDataDias.labels = [];
                this.lineStylesDataDias.datasets[0].label = "Ingresos";
                this.lineStylesDataDias.datasets[1].label = "Gastos";
                // HASTA AQUI

                // GRAFICAS
                await this.ObtieneIngresosPorDias()
                await this.ObtieneGastosPorDias()

                for (let i of this.listaIngresosXdias) {
                    for (let j of this.listaGastosXdias) {
                        if (j.mes == i.mes) {
                            let objeto = {
                                mes: j.mes,
                                importe: i.importe,
                                importeCompara: j.importe,
                            }
                            this.itemsIngresosGastos.push(objeto)
                            this.basicDataDias.labels.push(objeto.mes)
                            this.basicDataDias.datasets[0].data.push(objeto.importe)
                            this.basicDataDias.datasets[1].data.push(objeto.importeCompara)

                            this.lineStylesDataDias.labels.push(objeto.mes)
                            this.lineStylesDataDias.datasets[0].data.push(objeto.importe)
                            this.lineStylesDataDias.datasets[1].data.push(objeto.importeCompara)
                            break;
                        }
                    }
                }

                // CUENTAS POR COBRAR Y POR PAGAR
                this.datosGracfica.datasets[0].data = []
                await this.ConsultarCuentasPagar()
                await this.ConsultarCuentasCobrar()
                // LISTA 69B
                await this.consultarListaSat()


                this.graficaBarras()
                this.dialog = false;
            },

            // CUENTAS POR PAGAR/COBRAR
            async ConsultarCuentasCobrar() {
                this.dialog = true;
                this.totalCobrar = 0
                this.dialogMessage = 'Consultando cuentas por cobrar...'

                let fechaInicial = this.fechaHome.anio + '-01-01'
                let fechaFinal = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                console.log(fechaFinal)
                try {
                    let response = await axios.get('CuentasPorCobrarPagar/GetCxCTE/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/' + fechaFinal);
                    // console.log('cobrar', response.data)
                    this.itemsCobrar = response.data
                    this.datosGen = response.data
                    this.totalCobrar = this.sum(this.itemsCobrar, 'porCobrar')
                    this.datosGracfica.datasets[0].data.push(this.totalCobrar)

                    // this.totalFacturado = this.sum(this.itemsCobrar, 'totalFacturadoPesos')
                    // this.totalCobrado = this.sum(this.itemsCobrar, 'totalCobradoPesos')
                    // this.totalNC = this.sum(this.itemsCobrar, 'totalNCPesos')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            async ConsultarCuentasPagar() {
                this.dialog = true;
                this.dialogMessage = 'Consultando cuentas por pagar...'
                this.totalPorPagar = 0
                let fechaInicial = this.fechaHome.anio + '-01-01'
                let fechaFinal = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                // console.log(fechaFinal)

                try {
                    let response = await axios.get('CuentasPorCobrarPagar/GetCxPTE/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/' + fechaFinal);
                    // console.log('pagar', response.data)
                    this.itemsPagar = response.data
                    this.datosGenPagos = response.data
                    this.totalPorPagar = this.sum(this.itemsPagar, 'porPagar')

                    this.datosGracfica.datasets[0].data.push(this.totalPorPagar)
                    // this.totalFacturadoP = this.sum(this.itemsPagar, 'totalFacturadoPesos')
                    // this.totalPagado = this.sum(this.itemsPagar, 'totalPagadoPesos')
                    // this.totalNCP = this.sum(this.itemsPagar, 'totalNCPesos')
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            sum(array, key) {
                return array.reduce((a, b) => a + (b[key] || 0), 0);
            },

            // LISTA DEL 69B
            async consultarListaSat() {
                this.dialog = true;
                this.dialogMessage = 'Consultando lista 69B'
                // console.log(this.select)
                try {
                    let response = await axios.get('PeticionesSat/GetListasSat/' + this.datosEmpresa.empresaBase + '/' + 'Todos');
                    console.log(response.data)
                    this.itemsRFC = response.data
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            // GENERAR EXCEL LISTA 69B
            GeneraExcelLista() {
                // console.log(this.empresa)
                this.nombreReporte = 'ListaRFC.xlsx'
                let listaExcel = [];
                listaExcel = this.itemsRFC
                console.log(listaExcel)
                this.listaExcel = listaExcel;
                this.collection = listaExcel;
            },

            getColorListaNegra(listaNegra) {
                if (!listaNegra || listaNegra != 'No encontrado') return 'red darken-4'
                else return 'white'
            },

            getColornoListado(noLocalizados) {
                if (!noLocalizados || noLocalizados != 'No encontrado') return 'amber darken-4'
                else return 'white'
            },

            async consultarComprobantesXrfc(item) {
                this.titulo = item.rfc + ' - ' + item.nombre
                this.itemsComprobantesXrfc = []
                console.log(item)
                this.dialog = true;
                this.dialogMessage = 'Consultando...'
                try {
                    let response = await axios.get('PeticionesSat/GetComprobantesXrfc/' + this.datosEmpresa.empresaBase + '/' + item.rfc + '/' + item.tipo);
                    console.log(response.data)
                    this.itemsComprobantesXrfc = response.data
                    this.dialogDetalle = true
                    this.nombreReporte = 'ListaComprobantes_' + item.rfc + '.xlsx'
                } catch (error) {
                    this.snackbarMessage = 'Error al consultar'
                    this.snackbar = true
                }
                this.dialog = false;
            },

            // PARA INVENTARIO GASOLINEROS
            async GetNoCre() {
                try {
                    let response = await axios.get('Gasolinas/GetNoCre/' + this.datosEmpresa.empresaBase)
                    this.itemsNoCre = response.data
                    console.log(this.itemsNoCre)
                } catch (err) {
                    console.log(err)
                }
            },
            async GetInventarioSucursal() {
                console.log(this.fechaHome)
                let objetoListasMes = {}

                if (this.formaDeConcultarInventario == 1) {
                    console.log('por comprobantes')
                    this.itemsIventario = []

                    if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                        this.snackbarMessage = 'Seleccione una sucursal'
                        this.snackbar = true
                        return
                    }

                    this.dialog = true;
                    this.dialogMessage = 'Consultando...'

                    let noCre = Object.assign({}, this.SelectSucursal);
                    let fechaInicial = this.fechaHome.anio + '-01' + '-01'
                    let fechaFinal = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                    try {
                        let response = await axios.post('Gasolinas/GetInventarioSucursal/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/' + fechaFinal, noCre);
                        console.log(response.data)
                        this.itemsIventario = response.data
                    } catch (error) {
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                } else {
                    console.log('por reporte excel')

                    if (this.SelectSucursal.descripcion == '' || !this.SelectSucursal.descripcion) {
                        this.snackbarMessage = 'Seleccione una sucursal'
                        this.snackbar = true
                        return
                    }
                    // OBTNENOS LOS DATOS DE LOS ARCHIVOS
                    await this.getDatosParaCalcularInventario();

                    if (this.itemsTotalesExcel == '' || !this.itemsTotalesExcel) {
                        this.snackbarMessage = 'Para consultar su invetario, debera primero cargar en el botón amarillo "Subir archivos" y cargar los reportes.'
                        this.snackbar = true
                        return
                    }


                    if (this.fechaHome.mes.descripcion != 'Enero') {
                        let lista = []
                        let lista2 = []

                        // SEPARAMOS LOS MESES ANTERIORES
                        const meseseAnteriores = this.itemsTotalesExcel.filter(x => x.mes != this.fechaHome.mes.descripcion);
                        console.log('A', meseseAnteriores)
                        // OBTENEMOS EL MES QUE SE VA A CONSULTAR
                        const mesCorriente = this.itemsTotalesExcel.filter(x => x.mes === this.fechaHome.mes.descripcion);
                        console.log('C', mesCorriente)

                        // CREAMOS LA SUMATORIA PARA CADA PRODUCTO
                        let objeto1 = {
                            producto: 'MAGNA',
                            cantidadVendida: this.sum(meseseAnteriores, 'magnaLitros'),
                            totalVendida: this.sum(meseseAnteriores, 'magnaTotal')
                        }

                        let objeto2 = {
                            producto: 'PREMIUM',
                            cantidadVendida: this.sum(meseseAnteriores, 'premiumLitros'),
                            totalVendida: this.sum(meseseAnteriores, 'premiumTotal'),
                        }

                        let objeto3 = {
                            producto: 'DIESEL',
                            cantidadVendida: this.sum(meseseAnteriores, 'dieselLitros'),
                            totalVendida: this.sum(meseseAnteriores, 'dieselTotal'),
                        }
                        lista.push(objeto1)
                        lista.push(objeto2)
                        lista.push(objeto3)

                        let objeto11 = {
                            producto: 'MAGNA',
                            cantidadVendida: this.sum(mesCorriente, 'magnaLitros'),
                            totalVendida: this.sum(mesCorriente, 'magnaTotal')
                        }

                        let objeto22 = {
                            producto: 'PREMIUM',
                            cantidadVendida: this.sum(mesCorriente, 'premiumLitros'),
                            totalVendida: this.sum(mesCorriente, 'premiumTotal'),
                        }

                        let objeto33 = {
                            producto: 'DIESEL',
                            cantidadVendida: this.sum(mesCorriente, 'dieselLitros'),
                            totalVendida: this.sum(mesCorriente, 'dieselTotal'),
                        }
                        lista2.push(objeto11)
                        lista2.push(objeto22)
                        lista2.push(objeto33)

                        objetoListasMes = {
                            ListaMesesAnteriores: lista,
                            ListaMesCorriente: lista2
                        }
                    }
                    else {

                        let lista = []

                        // CREAMOS LA SUMATORIA PARA CADA PRODUCTO
                        let objeto1 = {
                            producto: 'MAGNA',
                            cantidadVendida: this.sum(this.itemsTotalesExcel, 'magnaLitros'),
                            totalVendida: this.sum(this.itemsTotalesExcel, 'magnaTotal')
                        }

                        let objeto2 = {
                            producto: 'PREMIUM',
                            cantidadVendida: this.sum(this.itemsTotalesExcel, 'premiumLitros'),
                            totalVendida: this.sum(this.itemsTotalesExcel, 'premiumTotal'),
                        }

                        let objeto3 = {
                            producto: 'DIESEL',
                            cantidadVendida: this.sum(this.itemsTotalesExcel, 'dieselLitros'),
                            totalVendida: this.sum(this.itemsTotalesExcel, 'dieselTotal'),
                        }
                        lista.push(objeto1)
                        lista.push(objeto2)
                        lista.push(objeto3)

                        objetoListasMes = {
                            ListaMesesAnteriores: lista,
                            ListaMesCorriente: []
                        }

                    }
                    console.log(objetoListasMes)
                    let noCre = this.SelectSucursal.noCre.replaceAll('/', '-')
                    let fechaInicial = this.fechaHome.anio + '-01' + '-01'
                    let fechaFinal = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'

                    this.dialog = true;
                    this.dialogMessage = 'Consultando...'

                    try {
                        let response = await axios.post('Gasolinas/GetInventarioSucursalXreporteEspecial/' + this.datosEmpresa.empresaBase + '/' + this.datosEmpresa.rfc + '/' + fechaInicial + '/' + fechaFinal + '/' + noCre, objetoListasMes);
                        console.log(response.data)
                        this.itemsIventario = response.data
                    } catch (error) {
                        console.log(error)
                        this.snackbarMessage = 'Error al consultar'
                        this.snackbar = true
                    }
                    this.dialog = false;
                }
                this.funcionGraficar()
            },

            funcionGraficar() {
                console.log('ga', this.itemsIventario)
                this.DataGasolina.labels = []
                this.DataGasolina1.labels = []

                this.DataGasolina.datasets[0].data = []
                this.DataGasolina.datasets[1].data = []
                this.DataGasolina.datasets[2].data = []
                this.DataGasolina.datasets[3].data = []
                this.DataGasolina.datasets[4].data = []

                this.DataGasolina1.datasets[0].data = []
                this.DataGasolina1.datasets[1].data = []
                this.DataGasolina1.datasets[2].data = []
                this.DataGasolina1.datasets[3].data = []
                this.DataGasolina1.datasets[4].data = []

                for (let i of this.itemsIventario) {
                    this.DataGasolina.labels.push(i.producto)
                    this.DataGasolina1.labels.push(i.producto)

                    this.DataGasolina.datasets[0].data.push(i.cantidadInicial)
                    this.DataGasolina.datasets[1].data.push(i.cantidadCompras)
                    this.DataGasolina.datasets[2].data.push(i.cantidadVendida)
                    this.DataGasolina.datasets[3].data.push(i.cantidadMerma)
                    this.DataGasolina.datasets[4].data.push(i.cantidadRestante)

                    this.DataGasolina1.datasets[0].data.push(i.totalInical)
                    this.DataGasolina1.datasets[1].data.push(i.totalCompras)
                    this.DataGasolina1.datasets[2].data.push(i.totalVendida)
                    this.DataGasolina1.datasets[3].data.push(i.totalMerma)
                    this.DataGasolina1.datasets[4].data.push(i.totalRestante)
                }
            },

            // ESTE GET SOLO ES PARA OBTENER LOS DATOS DE LOS ARCHIVOS DE EXCEL DE SOLO LOS MESES QUE VAMOS A CONSULTAR EL INVENTARIO
            async getDatosParaCalcularInventario() {
                this.itemsTotalesExcel = []
                let fechaInicial = this.fechaHome.anio + '-01' + '-01'
                let fechaFinal = this.fechaHome.anio + '-' + this.fechaHome.mes.valor + '-01'
                let noCre = this.SelectSucursal.noCre.replaceAll('/', '-')
                try {
                    let response = await axios.get('Gasolinas/getDatosParaCalcularInventario/' + this.datosEmpresa.empresaBase + '/' + fechaInicial + '/' + fechaFinal + '/' + noCre)
                    this.itemsTotalesExcel = response.data
                    console.log(response)

                } catch (error) {
                    console.log(error)
                }
            },

            // FUNCION DE BLOG

            async GetBlog() {
                this.dialog = true;
                this.dialogMessage = 'Consultando...';
                let fecha1 = this.fechaHome.anio + '-' + this.fechaHome.mes.valor;

                try {
                    let response = await axios.get('Blog/GetBlog/' + fecha1);
                    this.datosBlog = response.data;
                    console.log(response.data);

                } catch (error) {
                    console.log(error);
                }
                this.dialog = false;
            },

            BlogPdf(value) {
                window.open(value);
            }
        },

        mounted() {
            this.obtenFecha();
            if (this.datosEmpresa.rol === 'Capturista') {
                this.GetRegistrado();
            }
        },
    };
</script>
<style>
    .v-data-table .v-data-table-header tr th {
        font-size: 13px !important;
        color: black !important;
        text-align: center !important;
    }
</style>nt;
text-align: center !important;
}
</style>nt;
text-align: center !important;
}
</style>nt;
text-align: center !important;
}
</style>