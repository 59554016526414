<template>
    <div>
        <!-- DIALOG DE QUE ESTA HACIEDNO ALGO -->
        <v-dialog v-model="dialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{ dialogMessage }}
                    <v-progress-linear indeterminate color="white"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- SNACKBAR PARA NOTIFICAR LOS ERRORES -->
        <v-snackbar :timeout="10000" v-model="snackbar" color="primary darken-2" elevation="30">{{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-icon dark @click="snackbar = false">
                    mdi-close-circle
                </v-icon>
            </template>
        </v-snackbar>
        <v-card>
            <v-toolbar>
                <v-btn @click="cierraVentana()" color="primary darken-1" dark fab small>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>Registrar Información</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="validamosSellos()" color="primary darken-1" dark fab small>
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-toolbar>
            <v-system-bar color="primary" height="6"></v-system-bar>
            <v-card-text>
                <v-card elevation="0" class="ma-0 pa-0">
                    <v-card-text class="text-center ma-0 pa-0">
                        <v-icon class="ma-0 pa-0" size="150" color="rgb(255, 0, 0, 0.1)">mdi-account-key</v-icon>
                    </v-card-text>
                </v-card>
                <v-file-input label="Seleccione el archivo .cer" accept=".cer" v-model="cer_e"
                    @change="convertirBase64CER()" truncate-length="50"></v-file-input>
                <v-file-input label="Seleccione el archivo .key" accept=".key" v-model="key_e"
                    @change="convertirBase64KEY()" truncate-length="50"></v-file-input>
                <v-text-field :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1" prepend-icon="mdi-lock" v-model="sello.contra" label="Contraseña">

                </v-text-field>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        name: 'App',

        components: {
        },

        data: () => ({
            //
            drawer: false,
            nombreUsuario: '',

            //
            dialogMessage: '',
            dialog: false,

            dialogSubirArchivos: false,

            key_e: [],
            cer_e: [],
            contra_e: '',
            show1: false,

            // SNACKBAR
            snackbar: false,
            snackbarMessage: '',

            dialogDescargaXml: false,

            iconoMensaje: '',
            colorMensaje: '',
        }),
        computed: {
            sello() {
                return this.$store.state.sellosStore;
            },
            Usuario() {
                return this.$store.state.usuario.rol
            },
            nuevaEmpresa() {
                return this.$store.state.nuevaEmpresaStore
            },
        },
        created() {
        },
        methods: {
            cierraVentana() {
                this.$emit('closeCargaFiel')

                let objeto = {
                    tipo: '',
                    rfc: '',
                    selloCer: {
                        base64: ''
                    },
                    selloKey: {
                        base64: ''
                    },
                    contra: '',
                }

                this.$store.state.sellosStore = Object.assign({}, objeto)

                this.cer_e = null
                this.key_e = null

            },
            convertirBase64KEY() {
                this.archivoKEY = {};

                let file = this.key_e;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.archivoKEY.base64 = reader.result;
                    this.sello.selloKey = this.archivoKEY
                    console.log(this.archivoKEY)
                }
                reader.readAsDataURL(file);
            },
            convertirBase64CER() {
                this.archivoCER = {};
                let file = this.cer_e;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.archivoCER.base64 = reader.result;
                    this.sello.selloCer = this.archivoCER;
                    console.log(this.archivoCER)
                }
                reader.readAsDataURL(file);
            },

            // PRIMERO VALIDAMOS LOS SELLOS CORRECTOS
            async validamosSellos() {
                this.sello.tipo = "Fiel"
                this.sello.rfc = this.$store.state.nuevaEmpresaStore.rfc

                console.log(this.sello)

                this.dialogMessage = 'Cargando archivos...'
                this.dialog = true;
                try {
                    let response = await axios.post('configuracion/PostSellosParaCreacion/', this.sello);
                    console.log(response.data)
                    this.snackbarMessage = 'Archivos validos.'
                    this.snackbar = true
                    await this.crearEmpresa()
                    await this.guardarSellos()
                    this.cierraVentana();
                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = 'Archivos no validos, intentelo de nuevo.'
                    this.snackbar = true

                    let objeto = {
                        tipo: 'agua',
                        rfc: '',
                        selloCer: {
                            base64: ''
                        },
                        selloKey: {
                            base64: ''
                        },
                        contra: '',
                    }

                    this.$store.state.sellosStore = Object.assign({}, objeto)

                    this.cer_e = null
                    this.key_e = null
                }
                this.dialog = false;

            },

            // DESPUES GUARDAMOS LOS SELLOS EN SU BASE CORRESPONDIEMTE
            async guardarSellos() {
                console.log('aqui',this.sello)
                let base = 'documentos_' + this.sello.rfc
                try {
                    let response = await axios.post('configuracion/postSellos/' + base, this.sello);
                    console.log(response.data)

                } catch (error) {
                    console.log(error)

                }
            },

            // GUARDAMOS SOLAMENTE LOS DATOS DEL USUARIO Y LA EMPRESA AL IGUAL QUE SE LE ASIGNAN LOS ID 
            async crearEmpresa() {
                this.dialog = true,
                    this.dialogMessage = 'Creando Empresa...'

                console.log(this.nuevaEmpresa)
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/CreaEmpresa/', this.nuevaEmpresa);
                    // let response = await axios.post('http://localhost:53531/api/Usuarios/CreaEmpresa/', this.nuevaEmpresa);
                    console.log(response.data)

                    await this.creaBaseTablas();

                } catch (error) {
                    console.log(error)
                    if (error.response.status == 400) {
                        this.snackbarMessage = "Empresa ya se ha dado de alta.";
                        this.snackbar = true;
                    }
                }
                this.dialog = false
            },

            // CREAMOS LAS BASES Y TABLAS DE LA BASE DOCUMENTOS
            async creaBaseTablas() {
                this.dialog = true,
                    this.dialogMessage = 'Creando Base...'
                console.log(this.nuevaEmpresa)
                try {
                    let response = await axios.post('https://api-framework.contago.com.mx/api/Usuarios/CreaBaseTablas/', this.nuevaEmpresa);
                    // let response = await axios.post('http://localhost:53531/api/Usuarios/CreaBaseTablas/', this.nuevaEmpresa);
                    console.log(response.data)

                } catch (error) {
                    console.log(error)
                    this.snackbarMessage = error.response.data.Message
                    this.snackbar = true;
                }
                this.dialog = false

            },

        },
    };
</script>