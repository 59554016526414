<template>
    <div>
        <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Calculando Datos... Espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- VALIDAR EMPRESA -->
        <v-dialog persistent v-model="dialogValidacionEmpresa" width="500">
            <v-card>
                <v-toolbar>
                    <v-btn @click="cerrarValidarEmpresa()" icon color="primary darken-1">
                        <v-icon large>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <h3>EMPRESA</h3>
                    <v-spacer></v-spacer>
                    <!-- Boton para guardar factura -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="validarEmpresa()" color="primary darken-1" v-bind="attrs" v-on="on" icon>
                                <v-icon large>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Validar</span>
                    </v-tooltip>
                </v-toolbar>
                <v-system-bar color="primary" height="6"></v-system-bar>
                <v-card-text>
                    <v-card elevation="0" class="ma-0 pa-0">
                        <v-card-text class="text-center ma-0 pa-0">
                            <v-icon class="ma-0 pa-0" size="150" color="rgb(255, 0, 0, 0.1)">mdi-account-tie</v-icon>
                        </v-card-text>
                    </v-card>
                    <v-text-field v-model="nuevaEmpresa.rfc" counter="13" :maxlength="13" label="RFC" outlined>
                    </v-text-field>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- REGISTRO DE NUEVO USUARIO -->
        <v-dialog persistent v-model="dialogRegistro" width="1000">
            <Registro @closeNuevoUsuario="closeDialogRegistro()"></Registro>
        </v-dialog>



        <!-- login -->
        <v-layout align-center justify-center>
            <v-flex>
                <v-main>
                    <v-container class="fill-height" fluid>
                        <v-row align="center" justify="center">
                            <v-col cols="12" md="12">
                                <v-card style="margin-top: 40px;" class="elevation-5 mx-auto" max-width="600">
                                    <v-window v-model="step">
                                        <v-window-item :value="1">
                                            <v-row>
                                                <v-col cols="12" md="7" sm="7">
                                                    <v-card-text class="mt-2 text-center">
                                                        <div class="text-center mt-4">
                                                            <img src="../assets/contago-mi-auditor.jpg" height="80">
                                                        </div>
                                                        <!-- USUARIO -->
                                                        <v-text-field prepend-icon="mdi-account" v-model="nombreU"
                                                            label="Usuario">
                                                        </v-text-field>
                                                        <!-- CONTRASEÑA -->
                                                        <v-text-field :type="show1 ? 'text' : 'password'"
                                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                            @click:append="show1 = !show1" prepend-icon="mdi-lock"
                                                            v-model="password" label="Contraseña"
                                                            @keydown.enter="validarUsuario"></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions class="mb-6">
                                                        <v-btn width="100%" class="ml-3 mr-3" @click="validarUsuario()"
                                                            rounded outlined color="primary">
                                                            Validar Usuario</v-btn>
                                                    </v-card-actions>
                                                </v-col>
                                                <v-col cols="12" sm="5" md="5" class="primary">
                                                    <v-card-text class="white--text mt-12">
                                                        <h2 class="text-center display-1">Bienvenido</h2>
                                                        <h5 class="text-center mt-10 mb-11">
                                                            Ingresa tu información para formar parte de contago.
                                                        </h5>
                                                        <v-btn width="100%" class="white--text mt-14"
                                                            @click="abrirNuevoUsuario()" rounded outlined>
                                                            Registrarse</v-btn>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>
                                         </v-window-item>
                                        <v-window-item :value="2">
                                            <v-card-text>
                                                <!-- <div class="text-center display-1 mb-2">
                                                    <b style="color: #C62828; font-size: 35px;">Seleccione su
                                                        empresa</b>
                                                </div> -->
                                                <div class="text-center mt-3">
                                                    <img src="../assets/contago-mi-auditor.jpg" height="80">
                                                </div>
                                                <v-select v-model="selectEmpresa" :items="itemsEmpresa"
                                                    item-text="nombre_e" item-value="nombreBase"
                                                    prepend-icon="mdi-domain" label="Selecciona tu empresa"
                                                    return-object></v-select>
                                            </v-card-text>
                                            <v-card-actions class="mb-5">
                                                <v-row>
                                                    <v-col cols="12" md="6" sm="6">
                                                        <v-btn width="100%" rounded color="primary" @click="step--"
                                                            dark>Cambiar Usuario</v-btn>
                                                    </v-col>
                                                    <v-col cols="12" md="6" sm="6">
                                                        <v-btn width="100%" rounded color="primary" dark
                                                            @click="ingresar()">Ingresar
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                        </v-window-item>
                                    </v-window>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-main>
            </v-flex>
        </v-layout>

        <v-snackbar rounded="pill" shaped color="primary" v-model="snackbar" :timeout="timeout">{{ error }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">Cerrar</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'
    import Registro from '../components/RegistroUsuario/Registro.vue'

    export default {
        name: "Login",
        components: {
            Registro,
        },
        data: () => ({

            dialogRegistro: false,
            empresaValidar: '',
            dialogValidacionEmpresa: false,

            loading: false,
            snackbar: false,
            error: null,
            timeout: 3000,
            show1: false,
            step: 1,
            DialogCrear: false,

            nombreU: '',
            password: '',

            validar: false,
            editar: false,

            selectEmpresa: {},
            itemsEmpresa: [],
            disabledEmpresa: null,
            disabledUsuario: null,
            hiddenValida: false,
            hiddenEmpresa: false,

            idUsuario: null,
            nombreUsuario: null,
            rolUsuario: null,
            empresa: null,
            empresaBase: null,
            rfc: '',
            firma: null,
            loading: false,

            usuarioN: '',
            contraN: '',
            empresaN: '',
            correoN: '',
            teleN: '',


            rfcValidar: ''
        }
        ),
        created() {
        },
        computed: {
            nuevaEmpresa() {
                return this.$store.state.nuevaEmpresaStore
            },
        },
        methods: {
            abrirNuevoUsuario() {
                this.dialogValidacionEmpresa = true
            },
            cerrarValidarEmpresa() {
                this.dialogValidacionEmpresa = false
                this.$store.state.nuevaEmpresaStore.rfc = ''
            },
            closeDialogRegistro() {
                this.dialogValidacionEmpresa = false
                this.dialogRegistro = false

            },
            cambiarColor() {
                if (this.$store.state.usuario.rol == 'Gasolinero') {
                    this.$vuetify.theme.themes.light.primary = '#009341'
                    return this.$vuetify.theme.themes.light.primary
                }
                else {
                    this.$vuetify.theme.themes.light.primary = '#C62828'
                    return this.$vuetify.theme.themes.light.primary
                }
            },
            async validarUsuario() {

                this.snackbar = false;
                if (this.nombreU == "") {
                    this.error = "Indique un usuario";
                    this.snackbar = true;
                    return;
                }
                if (this.password == "") {
                    this.error = "Indique su contraseña";
                    this.snackbar = true;
                    return;
                }
                //VALIDAMOS QUE EL USUARIO SEA VALIDO
                try {
                    let respuesta = await axios.post("https://api-framework.contago.com.mx/api/Usuarios/ValidaAsync", {
                        nombre: this.nombreU,
                        password: this.password,
                    });
                    this.idUsuario = respuesta.data.idUsuario_;
                    this.rolUsuario = respuesta.data.rol_;
                    this.firma = respuesta.data.firma_;
                    this.rfc = respuesta.data.rfc_;
                    this.correo = respuesta.data.correo_;
                    this.listaEmpresas(respuesta.data.idUsuario_);
                    this.disabledEmpresa = !this.disabledEmpresa;
                    this.disabledUsuario = !this.disabledUsuario;
                    this.hiddenValida = !this.hiddenValida;
                    this.hiddenEmpresa = !this.hiddenEmpresa;
                    this.nombreUsuario = respuesta.data.nombre_;
                    this.step++;
                    this.loading = false;

                    console.log(respuesta.data);
                } catch (err) {
                    console.log(err.response.status);

                    if (err.response.status == 400) {
                        this.error = "Usuario inexistente";
                        this.snackbar = true;
                        this.loading = false;
                    } else if (err.response.status == 404) {
                        this.error = "Contraseña incorrecta";
                        this.snackbar = true;
                        this.loading = false;
                    } else if (err.response.status == 401) {
                        this.error = "El usuario ya cuenta con una sesión iniciada";
                        this.snackbar = true;
                        this.loading = false;
                    } else {
                        this.error = "Datos incorrectos, verifique su usuario y contraseña";
                        this.snackbar = true;
                        this.loading = false;
                        this.loading = false;
                    }
                }
            },
            ingresar() {
                this.snackbar = false;
                // this.nombreUsuario = this.nombreU;
                this.empresa = this.selectEmpresa.nombre_e
                this.empresaBase = this.selectEmpresa.nombreBase
                this.rfc = this.selectEmpresa.rfc
                if (!this.selectEmpresa.nombre_e) {
                    this.error = "Seleccione una empresa de la lista";
                    this.snackbar = true;
                    return
                }

                console.log(this.empresaBase);
                console.log(this.empresa);

                axios.post('https://api-framework.contago.com.mx/api/Usuarios/Login', { idUsuario: this.idUsuario, nombre: this.nombreUsuario, rol: this.rolUsuario, empresa: this.empresa, empresaBase: this.empresaBase, firma: this.firma, rfc: this.rfc })
                    .then(response => {
                        console.log(response.data);
                        return response.data
                    })
                    .then(data => {
                        this.$store.dispatch("guardarToken", data.token)
                        this.$router.push({ name: 'Home' })
                        this.nombre = '';
                        this.password = '';
                        this.cambiarColor();
                        location.reload();

                    }).catch(err => {
                        if (err.response.status == 500) {
                            this.error = err.response;
                            this.snackbar = true;
                        }
                    });

            },

            listaEmpresas(item) {
                axios.get('https://api-framework.contago.com.mx/api/Usuarios/Empresas/' + item + '/' + 'DESERIALIZADOR')
                    .then(response => {
                        this.itemsEmpresa = response.data;
                        console.log(this.itemsEmpresa)
                        this.$store.state.listaEmpresasStore = this.itemsEmpresa
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },

            async validarEmpresa() {
                console.log(this.nuevaEmpresa)
                try {
                    let response = await axios.get('https://api-framework.contago.com.mx/api/Usuarios/ValidaEmpresa/' + this.nuevaEmpresa.rfc);
                    console.log(response.data)
                    this.dialogRegistro = true

                } catch (error) {
                    console.log(error)
                    this.error = error.response.data.Message
                    this.snackbar = true;
                }
            },
        },
    };
</script>